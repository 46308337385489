import { render, staticRenderFns } from "./idle_certification.vue?vue&type=template&id=352216f2&scoped=true"
import script from "./idle_certification.vue?vue&type=script&lang=js"
export * from "./idle_certification.vue?vue&type=script&lang=js"
import style0 from "./idle_certification.vue?vue&type=style&index=0&id=352216f2&prod&scoped=true&lang=scss"
import style1 from "./idle_certification.vue?vue&type=style&index=1&id=352216f2&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "352216f2",
  null
  
)

export default component.exports