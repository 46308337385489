<script>
export default {
  name: "sukPop",
  props: {
    goods: [Object],
    num: [Number, String],
    chaoshi: [Boolean],
    skuParent: [Object],
    initial_sku: [Object],
  },
  watch: {
    num(n) {
      this.nums = n
    },
    nums(n) {
      this.nums = n
    },
  },
  data() {
    return {
      nums: this.num,
      sku: this.skuParent,
      skuShow: false,
      goodsId: this.goods.storeInfo.id,
      quota: 0,//限购数量   0 不限购
      quotaUsed: 0,//已经购买过的数量
      initialSku:this.initial_sku,
    }
  },
  methods: {
    closePop() {
      this.skuShow = false
      this.sku = {}
    },
    openPop() {
      this.skuShow = true
      this.initialSku = this.initial_sku
      this.goodsId = this.goods.storeInfo.id
      this.sku = this.skuParent
    },
    add() {
      this.nums += 1
    },
    unadd() {
      if (this.nums <= 1) return;
      this.nums -= 1
    },
    submit() {
      let obj = {
        id: this.goods.id,
        goods_type_id: this.goods.goods_type_id,
        nums: this.nums,
      }
      this.$emit('addCarc', obj)
    },
    onBuyClicked(skuData) {
      console.log(skuData)
      this.$emit('buyClicked', skuData)
    },
  },
  mounted() {
    console.log(this.goods)
    this.nums = this.goods.sell_num
  }
}
</script>

<template>
  <div class="carPop">
    <div style="margin-bottom: 129px;position:relative;z-index: 9999;">


    </div>
    <van-sku
        v-model="skuShow"
        :sku="sku"
        :goods="goods.storeInfo"
        :goods-id="goodsId"
        :quota="quota"
        :quota-used="quotaUsed"
        :hide-stock="sku.hide_stock"
        :initial-sku="initialSku"
        :show-add-cart-btn="false"
        :buy-text="'确定'"
        @buy-clicked="onBuyClicked"
        @close="closePop"
    >

<!--      <template #sku-header="props">-->
<!--        <div class="sku-header">-->
<!--          <div class="leftImg">-->
<!--            <img :src="goods.storeInfo.image" alt="">-->
<!--          </div>-->
<!--          <div class="skuRight">-->
<!--            <div class="van-sku__goods-price">-->
<!--              <div class="brand">-->
<!--                <div class="brandBox">品牌</div>-->
<!--                {{ goods.storeInfo.id }}-->
<!--              </div>-->
<!--              <span class="van-sku__price-symbol">￥</span-->
<!--              ><span class="van-sku__price-num">{{props.id }}c </span>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

<!--      </template>-->
    </van-sku>
  </div>
</template>

<style scoped lang="scss">
.skuRight{
  .brand {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 13px;
    color: #1B1B1B;
    line-height: 18px;

    .brandBox {
      background: #E8E8E8;
      border-radius: 4px;
      margin-right: 5px;
      font-weight: 500;
      font-size: 12px;
      color: #1B1B1B;
      line-height: 18px;
      padding: 0 4px;
    }
  }
}
.rightAdd {
  display: flex;

  .unadd,
  .add {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #398ae9;
    position: relative;
  }

  .add::after,
  .unadd:after {
    content: "";
    display: block;
    position: absolute;
    transform: translate(-50%, -50%);
    width: 50%;
    height: 10%;
    background-color: white;
    top: 50%;
    left: 50%;
    border-radius: 100px;
  }

  .add::before {
    content: "";
    display: block;
    position: absolute;
    transform: translate(-50%, -50%);
    width: 10%;
    height: 50%;
    background-color: white;
    top: 50%;
    left: 50%;
    border-radius: 100px;
  }

  .input {
    width: 24px;
    height: 24px;
    font-size: 18px;
    color: #1B1B1B;
    text-align: center;
    margin: 0 2px;
    font-weight: 500;
    white-space: nowrap;
    overflow: scroll;
  }

  .input::-webkit-scrollbar {
    display: none;
  }

  .addBox {
    background: #0085FF;
    border-radius: 5px 5px 5px 5px;
    font-weight: 500;
    font-size: 12px;
    color: #FFFFFF;
    line-height: 24px;
    text-align: center;
    padding: 0 10px;
  }
}

.carPop {
  padding: 16px;
  background: #fff;

  .top {
    display: flex;

    .leftImg {
      width: 94px;
      height: 94px;
      border-radius: 10px;
      overflow: hidden;
      margin-right: 10px;

      .image {
        width: 94px;
        height: 94px;
        object-fit: cover;
        border-radius: 10px;

      }
    }

    .right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
      text-align: left;

      .brand {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 13px;
        color: #1B1B1B;
        line-height: 18px;

        .brandBox {
          background: #E8E8E8;
          border-radius: 4px;
          margin-right: 5px;
          font-weight: 500;
          font-size: 12px;
          color: #1B1B1B;
          line-height: 18px;
          padding: 0 4px;
        }
      }

      .name {
        font-weight: 500;
        font-size: 14px;
        color: #1B1B1B;
        line-height: 24px;
      }

      .price {
        color: #FD2A39;
        display: flex;
        align-items: baseline;

        .unit {
          font-size: 14px;
        }

        .yuan {
          font-size: 20px;
        }

        .mao {
          font-size: 14px;
        }

        .oldPrice {
          font-weight: 400;
          font-size: 12px;
          color: #7F7F7F;
          line-height: 18px;
          text-align: left;
          font-style: normal;
          text-decoration-line: line-through;
        }
      }
    }
  }

  .bttom {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-top: 10px;

    .specification {
      font-weight: 400;
      font-size: 14px;
      color: #1B1B1B;
      line-height: 24px;
    }

    .selectNums {
      display: flex;
      justify-content: space-between;
      font-weight: 400;
      font-size: 14px;
      color: #1B1B1B;
      line-height: 24px;
    }

    .button {
      background: #398ae9;
      border-radius: 32px 32px 32px 32px;
      border: none;
      margin-top: 20px;
      padding: 8px;
      font-weight: 400;
      font-size: 14px;
      color: #FFFFFF;
      line-height: 24px;
    }
  }
}

.chaoshiAdd {
  .unadd {
    background: #FC223B !important;
  }

  .add {
    background: #FC223B !important;
  }
}

.chaoshiButtom {
  background: #FC223B !important;
}
</style>

<style>
.carPop {
  text-align: left;
}
</style>