<script>
import navigationBar from "@/components/navigation-bar/navigation-bar.vue";
import chooseTime from "@/components/common/choose_time/choose_time.vue";
import {
  defaultAddress,
  getNowTime,
  getuserShip, newwater_list,
  water_kt_create, water_create,
  // transmit_water,
} from "@/utils/api";
import {Toast} from "vant";
import Bus from "@/utils/Bus";
import {_throttle} from "@/utils/tool";
import Loding from "@/components/common/loding/loding.vue";

export default {
  name: "one_key_send_water",
  components: {
    Loding,
    chooseTime,
    navigationBar,
  },
  data() {
    return {
      time: '立即送水1小时送达(默认)',
      shipTime: '',
      address: '',
      myKtNum: 0,
      sendNums: 1,
      timeShow: false,
      ticket: false,
      columns: [{name: '水票1'}, {name: '水票12'}, {name: '水票13'}, {name: '水票14'}, {name: '水票15'}, {name: '水票16'}, {name: '水票17'},],
      chooseTicketIndex: 0,
      userInfo: {},
      addresst: {},
      lodingShow: false,
      page: 1,
      limit: 40,
    }
  },
  computed: {

    chooseWater() {

      return this.columns[this.chooseTicketIndex]
    },
    addressInputValue() {
      return this.userInfo.province ? this.userInfo.province + this.userInfo.city + this.userInfo.district + this.userInfo.address_location_name : ''
    },

  },
  methods: {
    async ininTime() {
      const {data} = await getNowTime()
      this.serverTime = data.data
      console.log(this.serverTime)
      const now = new Date(data.data * 1000)
      const last = new Date((data.data * 1000) + 3600000)
      let y = now.getFullYear()
      let m = now.getMonth() + 1
      let d = now.getDate()
      let hh = now.getHours()
      let mm = now.getMinutes()
      let hh1 = last.getHours()
      let mm2 = last.getMinutes()
      m = m < 10 ? '0' + m : m
      d = d < 10 ? '0' + d : d
      hh = hh < 10 ? '0' + hh : hh
      mm = mm < 10 ? '0' + mm : mm
      hh1 = hh1 < 10 ? '0' + hh1 : hh1
      mm2 = mm2 < 10 ? '0' + mm2 : mm2
      this.shipTime = y + '-' + m + '-' + d + ' ' + hh + ':' + mm + ',' + y + '-' + m + '-' + d + ' ' + hh1 + ':' + mm2
      this.shipTimeshow = '立即配送'
    },
    goBuyKt() {
      console.log('购买空桶')
      this.$router.push(({name: 'buyPail'}))
    },
    sendBtn2: _throttle(function () {
    }, 2000),
    sendBtn() {
      //增加节流
      // console.log('一键送水')
      console.log(Number(this.myKtNum), '自有空桶数量')
      console.log(Number(this.sendNums), '送水数量')

      if (!this.chooseWater && this.columns.length <= 0) {
        Toast('请购买水票')
        return
      }
      let wate_data = [
        // {"id":1,"nums":1}
      ]
      wate_data.push({id: JSON.stringify(this.chooseWater.id), nums: JSON.stringify(this.sendNums)})
      if (wate_data.length <= 0) {
        Toast('请选择水票')
        return;
      }
      if (this.shipTime == '') {
        Toast('请选择时间')
        return;
      }
      if (!this.addresst.id) {
        Toast('请您选择收货地址')
      }
      if (this.chooseWater.length == 0 || this.chooseWater.num == 0) {
        Toast('请选择数量')
        return;
      }
      if (this.$store.state.user.my_kt_status == 0) {
        this.sub_verification_barrel()
      }
      if (!this.lodingShow) {
        this.jiekou(wate_data)
        this.lodingShow = true
      }
    },


    jiekou: _throttle(function (wate_data) {
      const ship = {
        name: this.addresst.name,
        yx: this.addresst.ship_yx,
        ds: this.addresst.address_yx + this.addresst.address,
        address: this.addresst.address_yx,
        mobile: this.addresst.mobile,
        time: this.shipTime,
        remark: '',
        id: this.addresst.id
      }
      console.log(ship)
      console.log(wate_data)
      water_create(this.userInfo.id, '', Number(this.sendNums), this.chooseWater.goods_id, this.sendNums).then(res => {
        this.lodingShow = false
        if (res.data.status == 200) {
          Toast('送水成功')
          let msg = {type: 'u_order_songsui'}
          this.lodingShow = false
          console.log(msg)
          // this.$store.state.websocket.send(JSON.stringify(msg))
          this.$router.go(-1)
        } else {
          Toast(res.data.msg)
        }

      })
      // transmit_water(this.$store.state.token, JSON.stringify(wate_data), JSON.stringify(ship)).then(res => {
      //   console.log(res)
      //   if (res.data.status) {
      //     Toast('送水成功')
      //     let msg = {type: 'u_order_songsui'}
      //     this.lodingShow = false
      //     this.$store.state.websocket.send(JSON.stringify(msg))
      //     this.$router.go(-1)
      //   } else {
      //     this.lodingShow = false
      //     Toast(res.data.msg)
      //   }
      // })

    }, 2000),
    sub_verification_barrel() {
      water_kt_create(Number(this.myself)).then(res => {
        console.log(res)
      })
    },
    openTime() {
      // this.timeShow = true
    },
    chooseAddress() {
      this.$router.push({
        path: '/userAddress',
        query: {id: this.userInfo.id},
      })
    },
    unadd() {
      if (this.sendNums <= 1) return;
      this.sendNums -= 1
    },
    add() {
      if (this.sendNums >= this.chooseWater.num) return;
      this.sendNums += 1
    },
    inputMytk(e) {
      console.log(e)
    },
    childMessage(obj) {
      console.log(obj)
      this.time = obj.item.timeshow
      this.shipTime = obj.item.time
      // this.GetDateStr()
      // }
      this.timeShow = false
      this.timeShow = false
    },
    chooseTicket() {
      this.ticket = true
    },
    ticketConfirm(value, index) {
      console.log(value, index)
      this.chooseTicketIndex = index
      this.ticket = false
    },
    ticketCancel() {
      this.ticket = false
    },
    ticketChange(e) {
      console.log(e)
    },
    getList() {
      if (this.$store.state.token) {
        newwater_list('', this.page, this.limit, '').then(res => {
          if (res.data.status == 200) {
            console.log(res.data.data)
            this.columns = res.data.data
          } else {
            Toast(res.data.msg)
          }
        })

      }
      // user_water_list(this.$store.state.token).then(res => {
      //   if (res.data.status == true) {
      //     console.log(res.data.data)
      //     this.columns = res.data.data
      //   } else {
      //     Toast(res.data.msg)
      //   }
      // })
    },
    initUserAddress() {

      if(sessionStorage.getItem('now_choose_address')){
        this.userInfo = JSON.parse(sessionStorage.getItem('now_choose_address'))
        this.addresst = JSON.parse(sessionStorage.getItem('now_choose_address'))
      }else {
        defaultAddress().then(res => {
          if (res.data.status == 200) {
            this.userInfo = res.data.data
            this.addresst = res.data.data
            sessionStorage.setItem('now_choose_address',JSON.stringify(this.addresst))
          }
        })
      }


      // Bus.$on("sendUserInfo", (msg) => {
      //   let obj = {
      //     name: msg.ship_name,
      //     mobile: msg.ship_mobile,
      //     addId: msg.addId,
      //     shipAddress: msg.ship_address,
      //     ys: msg.ship_address_yx,
      //     address: msg.ship_address_ds,
      //   }
      //   console.log(msg)
      // });
    },
    initialize() {
      console.log(this.$store.state.user)
      getuserShip(this.$store.state.token).then(res => {
        if (res.data.status == true) {
          let addressList = res.data.data
          let moren = {}
          if (addressList.length > 0) {
            addressList.forEach((item) => {
              if (item.is_def == 1) {
                item.chekc = true
                moren = item

              } else {
                item.chekc = false
                if (item.chekc) {
                  moren = item

                }
              }
            })
            if (!moren.address) {
              moren = addressList[0]
              addressList[0].chekc = true
            }
            this.addresst = moren
          }
          this.$store.commit('updateShipingAddress', res.data.data)
        } else {
          Toast(res.data.msg)
        }
      })
    },
  }
  ,
  mounted() {
    Bus.$emit("updataUserInfo", '1');
    if (this.$store.state.token) {
      this.initUserAddress()
    }
    // this.initialize()
    console.log(this.$store.state.user)

    this.ininTime()
    this.getList()
    Bus.$on("sendUserInfo", () => {
      // A发送来的消息
    });
  }
  ,
  beforeDestroy() {
    Bus.$off("sendUserInfo");
  },
  beforeRouteLeave(to,from,next){
    if(to.name!='userAddress'){
      sessionStorage.removeItem('now_choose_address')
    }
    next();
  },
}
</script>

<template>
  <div class="container">
    <div class="area">
      <navigationBar :title="'一键送水'" class="navigationBar"></navigationBar>
      <div class="myBarrel">
        <div class="toptitle">我的空桶</div>
        <div class="myBarrelBox">
          <div class="BarrelBody">
            <div class="every">
              <div class="text">自有空桶</div>
              <div class="nums">{{ $store.state.user.my_kt_ktpledge }}桶</div>
            </div>
            <div class="every">
              <div class="text">已购空桶</div>
              <div class="nums">{{ $store.state.user.kt_pledge }}桶</div>
            </div>
            <div class="every">
              <div class="text">空桶总量</div>
              <div class="nums">{{ $store.state.user.my_kt_ktpledge + $store.state.user.kt_pledge }}桶</div>
            </div>

          </div>
          <div class="buy" @click="goBuyKt">
            <div class="text">购买空桶</div>
            <span class="iconfont icon-jiantou1"></span>
          </div>
        </div>
      </div>
      <div class="card myhavaCard" v-if="$store.state.user.my_kt_status == 0">
        <div class="myHave">
          <div class="text">自有空桶数量</div>
          <div class="inputBox">
            <input class="input" type="number" oninput="if(value.length>3)value=value.slice(0,3)"
                   maxlength="2" v-model="myKtNum" @input="inputMytk(myKtNum)">
            桶
          </div>

        </div>
        <div class="bottom">
          如：家中有空桶，请选择空桶数量可抵押空桶押金
        </div>
      </div>
      <div class="card">
        <div class="line" @click="openTime">
          <div class="left">
            <div class="text">送水时间</div>
            <div class="inputBox"><input type="text" readonly class="input" v-model="time" placeholder="请选择送水时间">
            </div>
          </div>
          <span class="iconfont icon-Frame-4"></span>
        </div>
        <div class="line" @click="chooseAddress">
          <div class="left">
            <div class="text">收货地址</div>
            <div class="inputBox"><input type="text" readonly class="input" v-model="addressInputValue"
                                         placeholder="请选择收货地址"></div>
          </div>
          <span class="iconfont icon-Frame-4"></span>
        </div>
      </div>
      <div class="card waterCouponBox">
        <div class="line">
          <div class="left">
            <div class="text">桶装水</div>
            <div class="right">
              <div class="surplus">水票剩余<span class="nums">{{ chooseWater.num }}</span>张</div>
              <div class="choose" @click="chooseTicket">
                <div class="name">{{ chooseWater.goods_name }}</div>
                | <span class="iconfont icon-Frame12"></span>
              </div>
            </div>
          </div>
        </div>
        <div class="line">
          <div class="left">
            <div class="text">送水数量</div>
            <div class="right">
              <div></div>
              <div class="rightAdd">
                <div class="unadd" @click="unadd()"></div>
                <div class="input">{{ sendNums }}</div>
                <div class="add" @click="add()"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottomBtn" @click="sendBtn">
        一键送水
      </div>
      <van-popup v-model="timeShow" round closeable position="bottom">
        <chooseTime :white="'white'" :type="'song'" :color="'#0085FF'" @childMessage="childMessage"></chooseTime>
      </van-popup>
      <van-popup v-model="ticket" round position="bottom">
        <van-picker @confirm="ticketConfirm"
                    @cancel="ticketCancel"
                    @change="ticketChange" value-key="goods_name" show-toolbar title="" :columns="columns"
                    :default-index="chooseTicketIndex"/>
      </van-popup>
      <loding v-if="lodingShow"></loding>
    </div>
  </div>
</template>

<style scoped lang="scss">
.container {
  min-height: 100vh;
  position: relative;

  .bottomBtn {
    background: #286CFB;
    border-radius: 10px 10px 10px 10px;
    font-weight: 400;
    font-size: 16px;
    color: #FFFFFF;
    line-height: 24px;
    padding: 13px;
    position: absolute;
    width: 85%;
    transform: translate(-50%, 0);
    left: 50%;
    bottom: 5%;
  }
}

.myBarrel {
  .toptitle {
    font-size: 16px;
    font-weight: 500;
    color: #1B1B1B;
    margin: 0 16px 10px 16px;
  }

  .myBarrelBox {
    padding-top: 5px;
    background: linear-gradient(180deg, #E7EEFD 0%, #FFFFFF 100%);
    margin: auto 16px;
    border-radius: 10px;
    position: relative;
    margin-bottom: 10px;
    overflow: hidden;

    .BarrelBody {
      display: flex;
      justify-content: space-between;

      .every {
        //display: flex;
        text-align: center;
        margin: 0 0 0 5px;
        width: 33%;

        .text {
          font-size: 14px;
          color: #1B1B1B;
        }

        .nums {
          margin: 4px 0 5px 0;
          font-size: 14px;
          color: #1B1B1B;
        }
      }

    }

    .buy {
      background: red;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 10px;
      background: #286CFB;
      color: #fff;
      font-size: 14px;

      .iconfont {
        transform: rotate(90deg);
      }
    }
  }
}

.card {
  box-shadow: 0px 0px 2px 0px rgba(102, 102, 102, 0.25);
  border-radius: 10px;
  margin: 0 16px 10px 16px;
  background: #fff;

  .myHave {
    display: flex;
    justify-content: space-between;

    .text {
      font-weight: 400;
      font-size: 14px;
      color: #666666;
      line-height: 24px;
    }

    .inputBox {
      font-weight: 400;
      font-size: 14px;
      color: #1B1B1B;
      line-height: 24px;

      .input {
        background: #F1F6FD;
        border-radius: 8px 8px 8px 8px;
        border: 1px solid #286CFB;
        width: 40px;
        text-align: center;
        font-weight: 400;
        font-size: 14px;
        color: #286CFB;
        line-height: 24px;
        text-align: center;
        margin-right: 10px;
      }
    }
  }

  .bottom {
    font-size: 12px;
    color: #0085FF;
    line-height: 24px;
    text-align: left;
    margin-top: 2px;
  }

  .line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(153, 153, 153, 0.15);;
    margin: 0 10px;
    padding: 10px 0;

    .left {
      display: flex;
      align-items: center;
      width: 100%;

      .text {
        font-weight: 400;
        font-size: 14px;
        color: #1B1B1B;
        line-height: 24px;
        text-align: left;
        margin-right: 10px;
        width: 15%;
        white-space: nowrap;
        min-width: 60px;
      }

      .inputBox {
        width: 80%;

        .input {
          width: 100%;
          background: #fff;
          color: #1B1B1B;
          border: none;
          font-size: 14px;
          color: #1B1B1B;
        }
      }
    }

    .iconfont {
      font-size: 24px;
    }
  }

  .line:nth-last-of-type(1) {
    border-bottom: none;
  }
}

.myhavaCard {
  padding: 10px;
}

.waterCouponBox {
  .line {
    .left {
      width: 100%;

      .right {
        width: 85%;
        //background: red;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .nums {
          color: #286cfb;
          margin: 0 3px;
        }
      }

      .choose {
        background: #F1F6FD;
        border-radius: 8px 8px 8px 8px;
        border: 1px solid #286CFB;
        padding: 2px 10px;
        font-weight: 400;
        font-size: 12px;
        color: #286CFB;
        line-height: 24px;
        text-align: left;
        display: flex;
        align-items: center;
        max-width: 100px;

        .iconfont {
          font-size: 18px;
        }

        .name {
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-right: 4px;
        }
      }
    }
  }
}

.rightAdd {
  display: flex;

  .unadd,
  .add {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #398ae9;
    position: relative;
  }

  .add::after,
  .unadd:after {
    content: "";
    display: block;
    position: absolute;
    transform: translate(-50%, -50%);
    width: 50%;
    height: 10%;
    background-color: white;
    top: 50%;
    left: 50%;
    border-radius: 100px;
  }

  .add::before {
    content: "";
    display: block;
    position: absolute;
    transform: translate(-50%, -50%);
    width: 10%;
    height: 50%;
    background-color: white;
    top: 50%;
    left: 50%;
    border-radius: 100px;
  }

  .input {
    height: 22px;
    width: 22px;
    line-height: 22px;
    margin: 0 5px;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    color: #1b1b1b;
  }
}
</style>