<script>
import navigationBar from "@/components/navigation-bar/navigation-bar.vue";
import {invoiceList, orderDetails} from "@/utils/api";
import Clipboard from "clipboard";
import {Dialog, Toast} from "vant";

export default {
  name: "order_details",
  data() {
    return {
      obj: {cartInfo: [],},
      maxShow: 2,
      invoice_func: false,
      status: {}, //订单底部按钮状态
      invoiceData: {},
    }
  },
  components: {navigationBar},
  methods: {
    getObj(id) {
      orderDetails(id).then(res => {
        if (res.data.status == 200) {
          this.obj = res.data.data
          this.invoice_func = res.data.data.invoice_func
          this.invoiceData = res.data.data.invoiceData
          if (this.invoiceData) {
            this.invoiceData.pay_price = res.data.data.pay_price;
          }
          this.obj.cartInfo.forEach(item => {
            if (item.productInfo.is_vip == 0) {
              item.productInfo.yuan = item.truePrice.toFixed(2).split('.')[0]
              item.productInfo.mao = item.truePrice.toFixed(2).split('.')[1]
            } else {
              item.productInfo.yuan = (this.$store.state.is_vip.is_vip ? item.productInfo.vip_price : item.productInfo.truePrice.toFixed(2)).split('.')[0]
              item.productInfo.mao = (this.$store.state.is_vip.is_vip ? item.productInfo.vip_price : item.productInfo.truePrice.toFixed(2)).split('.')[1]
            }
          })
          if (this.invoice_id && !this.invoiceData) {
            this.invChecked = this.invoice_id || '';
            this.invoiceApply()
          }
          this.getOrderStatus()
        }
      })
    },
    getOrderStatus() {
      let orderInfo = this.orderInfo || {},
          _status = orderInfo._status || {
            _type: 0
          },
          status = {};
      let type = parseInt(_status._type),
          delivery_type = orderInfo.delivery_type,
          seckill_id = orderInfo.seckill_id ? parseInt(orderInfo.seckill_id) : 0,
          bargain_id = orderInfo.bargain_id ? parseInt(orderInfo.bargain_id) : 0,
          discount_id = orderInfo.discount_id ? parseInt(orderInfo.discount_id) : 0,
          combination_id = orderInfo.combination_id ? parseInt(orderInfo.combination_id) : 0;
      status = {
        type: type == 9 ? -9 : type,
        class_status: 0,
        class_again: 0
      };
      if (type == 1 && combination_id > 0) status.class_status = 1; //查看拼团
      if (type == 2 && delivery_type == 'express') status.class_status = 2; //查看物流
      if (type == 2) status.class_status = 3; //确认收货
      if (type == 4 || type == 0) status.class_status = 4; //删除订单
      if (!seckill_id && !bargain_id && !combination_id && !discount_id && !orderInfo.type && (type == 3 ||
          type == 4)) status.class_status = 5; //再次购买（待评价、已完成）
      if (!seckill_id && !bargain_id && !combination_id && !discount_id && !orderInfo.type && (type == 1 || type == 2 ||
          type == 5)) status.class_again = 6; //再次购买 （待发货、待收货、部分核销）
      this.status = status
      console.log(this.status)
    },
    //申请开票
    invoiceApply() {
      this.getInvoiceList()
      this.moreBtn = false;
      this.invShow = true;
    },
    getInvoiceList() {
      invoiceList().then(res => {
        console.log(res)
      })
    },
    showBtn() {
      this.maxShow = -1
    },
    hideBtn() {
      this.maxShow = 2
    },
    copy(e) {
      let clipboard = new Clipboard("#copy", {
        text: function () {
          //返回字符串
          return e;
        },
      });
      clipboard.on("success", () => {
        //复制成功
        Toast('复制成功')
        clipboard.destroy();
      });
      clipboard.on("error", () => {
        //复制失败
        Toast('复制失败')
        clipboard.destroy();
      });
    },
    //确认收货
    confirmOrder(){
      Dialog.confirm({
        title: '确认收货',
        message: '为保障权益，请收到货确认无误后，再确认收货',
      })
          .then(() => {
            // on confirm
          })
          .catch(() => {
            // on cancel
          });
    },
    //批量退款
    openAfter(){
      if(this.obj.cartInfo.length>1){
        //批量
        this.$router.push({
          path:"/goods_return_list",
          query:{order_id:this.obj.order_id,id:this.obj.id}
        })
      }else{
        //直接申请
      }
    },
  },
  mounted() {
    this.getObj(this.$route.query.order_id)
  },
}
</script>

<template>
  <div class="container">
    <div class="area">
      <navigationBar :title="'订单详情'" class="navigation"></navigationBar>
      <div class="inner">
        <div class="topInfo">
          <div class="address">
            {{ obj.user_address }}
            <span class="iconfont icon-Frame-4"></span>
          </div>
          <div class="nameBox">
            <span class="name">{{ obj.real_name }}</span>
            <span class="user_phone">{{ obj.user_phone }}</span>
          </div>
        </div>
        <div class="orderInfo">
          <div class="orderTitle">
            订单信息
          </div>
          <div class="orderListBox">
            <div class="out" v-for="(item,index) in obj.cartInfo" :key="index">
              <div class="every" v-if="index<=maxShow||maxShow==-1">
                <div class="top">
                  <div class="leftImg">
                    <img :src="item.productInfo.image" class="image" alt="">
                  </div>
                  <div class="center">
                    <div class="brand">
                      <div class='brandBox'>品牌</div>
                      <div class='text'>{{ item.productInfo.brand_name }}</div>
                    </div>
                    <div class="name">{{ item.productInfo.store_name }}</div>
                  </div>
                  <div class="right">
                    x{{ item.cart_num }}
                  </div>
                </div>
                <div class="foot">
                  <div class="specification">规格：{{ item.productInfo.attrInfo.suk }}</div>
                  <div class="univalence">
                    <div class="leftPrice">
                      单价：￥{{ item.productInfo.attrInfo ? item.productInfo.attrInfo.price : item.productInfo.price }}
                    </div>
                    <div class="rightPrice">
                      <div class="leftPrice">
                        <div class="sellPrice">
                          <div>￥</div>
                          <div class="yuan">{{ item.productInfo.yuan }}</div>
                          <div>.{{ item.productInfo.mao }}</div>
                        </div>
                        <div class="lastPrice" :class="$store.state.is_vip.is_vip?'oldPrice':''"
                             v-if="!$store.state.is_vip.is_vip">￥{{ item.sum_price }}
                        </div>
                        <img v-if="$store.state.is_vip.is_vip" :src="$store.state.imageHost+'/img/vip_price_tag.png'"
                             alt="" class="vip_price_tag">
                      </div>
                      <div class="vipBox" v-if="item.productInfo.is_vip">
                        <div class="vipPrice">
                          ￥{{ $store.state.is_vip.is_vip ? item.productInfo.price : item.productInfo.vip_price }}
                        </div>
                        <img v-if="!$store.state.is_vip.is_vip" :src="$store.state.imageHost+'/img/vip_price_tag.png'"
                             alt="" class="vip_price_tag">
                        <div class="lastPrice" :class="$store.state.is_vip.is_vip?'oldPrice':''"
                             v-if="$store.state.is_vip.is_vip">￥{{ item.productInfo.ot_price }}
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
<!--              <div class="exitBtn" v-if="obj.is_apply_refund && obj.refund_status === 0 && item.refund_num !=item.cart_num && Number(obj.paid) && item.is_support_refund && obj.type != 8 && obj.type != 10&&(index<=maxShow||maxShow==-1)">申请退款</div>-->
            </div>
            <div class="showBox" v-if="obj.cartInfo.length - 1>maxShow">
              <div class="showBtn btn" v-if="maxShow!=-1" @click="showBtn">展开
                <van-icon name="arrow-down" class="icon"/>
              </div>
              <div class="hideBtn btn" v-if="maxShow==-1" @click="hideBtn">收起
                <van-icon name="arrow-up" class="icon"/>
              </div>
            </div>
          </div>

        </div>
        <div class="orderDetail">
          <div class="line">
            <div class="label">商品总价</div>
            <div class="value">￥{{ (parseFloat(obj.total_price) + parseFloat(obj.vip_true_price)).toFixed(2) }}</div>
          </div>
          <div class="line" v-if="obj.pay_postage > 0">
            <div class="label">订单运费</div>
            <div class="value">￥parseFloat(obj.pay_postage).toFixed(2)</div>
          </div>
          <div class="line" v-if="obj.vip_true_price > 0">
            <div class="label">会员减免</div>
            <div class="value">￥{{ parseFloat(obj.vip_true_price).toFixed(2) }}</div>
          </div>
          <div class="line">
            <div class="label">实付款</div>
            <div class="value">￥{{ parseFloat(obj.pay_price).toFixed(2) }}</div>
          </div>
          <div class="line">
            <div class="label">订单编号</div>
            <div class="value">{{ obj.order_id }}<span class="inline-block copy_btn " id="copy"
                                                       @click="copy(obj.order_id)">复制</span></div>
          </div>
          <div class="line">
            <div class="label">下单时间</div>
            <div class="value">{{ obj._add_time }}</div>
          </div>
        </div>
      </div>

      <div class="bottomBox">
        <div class="in">
          <div class="btn everyBtn"
               v-if="invoice_func && !invoiceData && obj.type != 8 && status.type !=-2 && (obj.type != 9 || obj.shipping_type == 2)"
          >申请开票
          </div>
          <div class="btn everyBtn last"
               v-if="obj.is_apply_refund && obj.refund_status == 0 && obj.cartInfo.length>1 && obj.paid && obj.type != 10 && (obj.type != 9 || obj.shipping_type == 2)"
               @click="openAfter"
          >批量退款
          </div>
          <div class="btn everyBtn last"
               v-if="status.class_status==3 && !split.length" @click="confirmOrder"
          >
            确认收货
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.container {
  background: #f4f6fa;
  height: 100vh;
  padding-bottom: 100px;
}

.navigation {
  background-color: #fff;
}

.topInfo {
  border-radius: 10px;
  background: #ffffff;
  margin: 10px 16px;
  padding: 10px;
  text-align: left;

  .address {
    display: flex;
    justify-content: space-between;

    .iconfont {
      font-size: 22px;
    }
  }

  .nameBox {
    color: #1b1b1b;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    .name {
      display: inline-block;
      margin-right: 5px;
    }

    .user_phone {
      display: inline-block;
    }
  }
}

.orderInfo {
  border-radius: 10px;
  background: #ffffff;
  margin: 10px 16px;
  padding: 10px;
  text-align: left;

  .orderTitle {
    font-size: 14px;
    color: #1b1b1b;
    font-weight: 500;
    padding-bottom: 10px;
    border-bottom: 1px solid hsla(0, 0%, 60%, 0.15);
  }

  .orderListBox {
    margin-top: 10px;
    padding-bottom: 10px;

    .every {
      text-align: left;

      .top {
        display: flex;
        align-items: flex-end;
      }

      .leftImg {
        width: 50px;
        height: 50px;
        border-radius: 10px;

        .image {
          width: 50px;
          height: 50px;
          border-radius: 10px;
        }
      }

      .center {
        padding-left: 14px;
        width: 70%;

        .brand {
          display: flex;
          align-items: center;

          .brandBox {
            background: #E8E8E8;
            border-radius: 4px 4px 4px 4px;
            font-weight: 500;
            font-size: 12px;
            color: #1B1B1B;
            line-height: 18px;
            padding: 0 4px;
          }

          .text {
            font-weight: 500;
            font-size: 13px;
            color: #1B1B1B;
            line-height: 18px;
            margin-left: 5px;
          }
        }

        .name {
          color: #1b1b1b;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
        }
      }

      .right {
        text-align: right;
        width: 20%;
        color: #1b1b1b;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
      }

      .foot {
        padding-left: 67px;
        color: #7f7f7f;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;

        .univalence {
          display: flex;
          justify-content: space-between;
          align-items: baseline;

          .vipBox {
            display: flex;
            font-weight: 600;
            font-size: 14px;
            color: #392723;
            line-height: 18px;
            align-items: baseline;

            .vipPrice {
              margin-right: 5px;
            }
          }

          .leftPrice {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .sellPrice {
              display: flex;
              font-size: 14px;
              color: #fd2a39;
              line-height: 18px;
              align-items: baseline;

              .yuan {

                font-size: 20px !important;
              }
            }

            .lastPrice {
              text-decoration-line: line-through;
              line-height: 18px;
              color: #7f7f7f;
              font-size: 12px;
            }
          }

          .vip_price_tag {
            width: 34px;
            height: 14px;
            margin-left: 5px;
          }
        }
      }
    }

    .showBox {
      margin-top: 10px;

      .btn {
        text-align: center;
      }
    }

    .out {
      margin-bottom: 10px;
      text-align: right;

      .exitBtn {
        margin-top: 5px;
        display: inline-block;
        border-radius: 50px;
        border: 0.5px solid #999999;
        box-sizing: border-box;
        color: #999999;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        padding: 5px 10px;
      }
    }
  }
}

.oldPrice {
  font-weight: 400;
  font-size: 12px;
  color: #7F7F7F;
  line-height: 18px;
  text-align: left;
  text-decoration-line: line-through
}

.orderDetail {
  border-radius: 10px;
  background: #ffffff;
  margin: 10px 16px;
  padding: 10px;
  text-align: left;

  .line {
    display: flex;
    padding: 10px 0;

    .label {
      min-width: 75px;
    }

    .value {

      .copy_btn {
        margin-left: 10px;
        width: 38px;
        height: 20px;
        background: #f5f5f5;
        border-radius: 11px;
        text-align: center;
        line-height: 20px;
        font-size: 12px;
        padding: 5px;
      }
    }
  }
}

.bottomBox {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 10px;
  background: #ffffff;
  box-shadow: 0px -0.5px 2px #99999926;

  .in {
    text-align: right;
    padding-right: 20px;

    .everyBtn {
      margin-right: 10px;
      display: inline-block;
      border-radius: 50px;
      border: 0.5px solid #999999;
      box-sizing: border-box;
      padding: 5px 10px;
      color: #999999;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
    }

    .last {
      background: #fc223b;
      color: #ffffff;
      border: none;
    }
  }
}
</style>