<template>
  <div id="app">
    <!--    <transition name="fade" mode="out-in">-->
    <!--      <router-view></router-view>-->
    <!--    </transition>-->
    <div id="app">
      <!--    <transition name="fade" mode="out-in">-->
      <keep-alive>
        <RouterView v-if="$route.meta.keepAlive"/>
      </keep-alive>
      <RouterView v-if="!$route.meta.keepAlive"/>
      <!--      <router-view></router-view>-->
      <!--    </transition>-->
      <!--      <loding v-if="$store.state.loding"></loding>-->
    </div>
  </div>
</template>
<script>
import {
  config,
  getAllArea,
  getAppImgOrigin,
  getUserInfo,
  icontont, is_peisongyuan,
  user_kt_list,
  water_user_kt_list
} from "@/utils/api";
import {Toast} from "vant";
import Bus from "@/utils/Bus";
import {_throttle, sendApp} from "@/utils/tool";
// import Loding from "@/components/common/loding/loding.vue";
// import {WebsocketService} from "@/utils/websocket"
export default {
  name: 'App',
  // components: {Loding},
  data() {
    return {
      websocket: null,
      timeout: 10000, // 45秒一次心跳
      timeoutObj: null, // 心跳心跳倒计时
      serverTimeoutObj: null, // 心跳倒计时
      timeoutnum: null, // 断开 重连倒计时
      lockReconnect: false, // 防止
    }
  },
  created() {
    // 获取当前位置 **************************************************************************
    // this.$jsonp(`https://apis.map.qq.com/ws/location/v1/ip?`,{
    //     key: "RO3BZ-DM63Q-FEH5C-BM3OU-WKCL2-7TFL5", //这里就是要开启那个service不然会报错让你开启
    //     output: "jsonp", // output必须jsonp 不然会超时
    // }).then(res=>{
    //     if(res.status == 0){
    //     console.log(res.result);
    //     }
    // })
    // 获取当前位置 **************************************************************************
    // WebsocketService
    if (this.$store.state.token) {
      this.initWebsocket()
    }
  },
  mounted() {
    window.sendFlag = true
    window.myselfSendFlag = true
    window.sendFun = this.sendToApp
    window.cwqCallJs = function (msg) {
      console.log(msg, 'appvue 接受消息123444444444')
      if (msg == 6) {
        window.sendFlag = false
        return false
      } else {
        window.sendFlag = true
        return true
      }
    }
    console.warn('hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh     进入带不带参数？', this.$route.query.idle_token)
    if (this.$route.query.idle_token) {
      this.$store.commit('updataToken', this.$route.query.idle_token)
      sessionStorage.setItem('token', this.$route.query.idle_token);
      this.updataUserInfo()
    }

    getAppImgOrigin().then(res => {
      if (res.status) {
        if (this.websocket) {
          this.websocket.close()
        }
        this.initWebsocket()
        this.$store.commit("updataimageHost", res.data.data.origin);
      }
    })
    this.updataUserInfo()

    Bus.$on("updataUserInfo", (msg) => {
      // A发送来的消息
      console.log(msg)
      this.updataUserInfo()
    });
    // Bus.$on('updataUserInfo',res=>{
    //   console.log(res)
    // })
    if (this.$store.state.config) {
      config().then(res => {
            this.$store.commit('updataConfig', res.data.data)
          }
      )
    }
    var link =
        document.querySelector("link[rel*='icon']") ||
        document.createElement("link");
    link.type = "image/x-icon";
    link.rel = "shortcut icon";
    icontont(sessionStorage.getItem("token")).then((res) => {
      if (res.data.status) {
        link.href = this.$store.state.imageHost + res.data.data[0].value;
        console.log(this.$store.state.imageHost + res.data.data[0].value);
      } else {
        this.$message.error(res.data.msg);
      }
    });


    this.initLocation()

    // this.getAllAddress()
    // 先判断是不是微信端打开的
    if (/(micromessenger)/i.test(navigator.userAgent)) {
      this.$store.commit('updateclient_type', 6)
      return
    } else {
      // 判断h5还是pc true就是h5
      let client =
          /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
              navigator.userAgent
          );
      if (client) {
        this.$store.commit('updateclient_type', 4)
        return

      } else {
        this.$store.commit('updateclient_type', 1)
        return
      }
    }

  },
  methods: {
    sendToApp: _throttle(function () {
      sendApp()
    }, 1000),

    updataUserInfo() {
      if (this.$store.state.token) {
        getUserInfo().then(async res => {
          console.log(res.data.status == 200)
          if (res.data.status == 200) {
            sessionStorage.setItem('userInfo', JSON.stringify(res.data.data));
            this.$store.commit("updataUser", JSON.stringify(res.data.data));
            let userInfo = res.data.data
            let obj = {}
            if (userInfo.is_money_level && userInfo.is_money_level > 0 && userInfo.overdue_time && userInfo.overdue_time > new Date().getTime() / 1000) {
              obj = {
                is_vip: true,
                time: userInfo.overdue_time
              }
              this.$store.commit("setvip", obj);
            }
            let user_kt_list2 = await user_kt_list()
            let water_user_kt_list2 = await water_user_kt_list()
            let is_peisongyuan2 = await is_peisongyuan()
            console.log(is_peisongyuan2.data)
            userInfo.user_authority = 10
            if(is_peisongyuan2.data.status == 400){
              Toast.clear()
            }
            if(is_peisongyuan2.data.status == 200) {
              userInfo.user_authority = 5
            }


            for (const res1Key in user_kt_list2.data.data) {
              userInfo[res1Key] = user_kt_list2.data.data[res1Key]
            }
            Bus.$emit("sendUserInfo", userInfo);
            this.$store.commit("updataUser", userInfo);
            if (!water_user_kt_list2.data.data.result) {
              userInfo.my_kt_status = 0
            }
            this.$store.commit("updataUser", userInfo);
            Bus.$emit("sendUserInfo", userInfo);
            // user_kt_list().then(res1 => {
            //   if (res1.data.status == 200) {
            //     //my_kt_ktpledge 自有空桶
            //     //kt_pledge 已购空桶
            //   }
            // })


          } else {
            this.$store.commit('outLogin')
            Toast(res.data.msg)
          }
        })
        // get_token_userinfo(this.$store.state.token).then(res => {
        //   if (res.data.status) {
        //     getuserShip(this.$store.state.token).then(res1 => {
        //       if (res1.data.status) {
        //         let userInfo = res.data.data
        //         let addressList = res1.data.data
        //         let moren = {}
        //         if (addressList.length > 0) {
        //           addressList.forEach((item) => {
        //             if (item.is_def == 1) {
        //               item.chekc = true
        //               moren = item
        //               userInfo.addId = item.id
        //               userInfo.ship_address = item.address_yx
        //               userInfo.ship_address_ds = item.address
        //               userInfo.ship_address_yx = item.ship_yx
        //               userInfo.ship_mobile = item.mobile
        //               userInfo.ship_name = item.name
        //             } else {
        //               item.chekc = false
        //               if (item.chekc) {
        //                 moren = item
        //               }
        //             }
        //           })
        //           if (!moren.address) {
        //             moren = addressList[0]
        //             addressList[0].chekc = true
        //             userInfo.addId = moren.id
        //             userInfo.ship_address = moren.address_yx
        //             userInfo.ship_address_ds = moren.address
        //             userInfo.ship_address_yx = moren.ship_yx
        //             userInfo.ship_mobile = moren.mobile
        //             userInfo.ship_name = moren.name
        //           }
        //           this.addresst = moren
        //           Bus.$emit("sendUserInfo", userInfo);
        //         }
        //         this.$store.commit('updateShipingAddress', res1.data.data)
        //         sessionStorage.setItem('userInfo', JSON.stringify(userInfo))
        //         this.$store.commit("updataUser", userInfo);
        //       } else {
        //         Toast(res.data.msg)
        //       }
        //     })
        //     is_vip(this.$store.state.token, 5).then(res => {
        //       if (res.data.status) {
        //         console.log(res.data.data)
        //         this.$store.commit('setvip', res.data.data)
        //       }
        //     }).catch(err => {
        //       console.log(err)
        //     })
        //   } else {
        //     this.$store.commit('outLogin')
        //     Toast(res.data.msg)
        //   }
        // })
      }
    },
    reset() {
      // 重置心跳
      // 清除时间
      clearTimeout(this.timeoutObj);
      clearTimeout(this.serverTimeoutObj);
      // 重启心跳
      this.start();
    },
    start() {
      //清除延时器
      this.timeoutObj && clearTimeout(this.timeoutObj);
      this.serverTimeoutObj && clearTimeout(this.serverTimeoutObj);
      this.timeoutObj = setTimeout(() => {
        if (this.websocket && this.websocket.readyState == 1) {
          let msg = {
            user_id: this.$store.state.user ? this.$store.state.user.id : '',
            type: "pong",
          };
          this.websocket.send(JSON.stringify(msg)); //发送消息，服务端返回信息，即表示连接良好，可以在socket的onmessage事件重置心跳机制函数
        } else {
          this.reconnect();
        }
        //定义一个延时器等待服务器响应，若超时，则关闭连接，重新请求server建立socket连接
        this.serverTimeoutObj = setTimeout(() => {
          this.websocket.close();
        }, this.timeout);
      }, this.timeout);
    },
    // 重新连接
    reconnect() {
      // let that = this
      if (this.lockReconnect) return;
      this.lockReconnect = true;
      //没连接上会一直重连，设置延迟避免请求过多
      this.timeoutnum && clearTimeout(this.timeoutnum);
      this.timeoutnum = setTimeout(() => {
        this.websocket = null
        this.initWebsocket();
        this.lockReconnect = false;
      }, 5000);
    },
    initWebsocket() {
      let wsUrl = "wss://dev.taobaijie.com/wss";//
      // let wsUrl = "wss://test.taobaijie.com/wss";//
      // let wsUrl = "wss://pros.taobaijie.com";//

      this.websocket = new WebSocket(wsUrl);
      this.websocket.onopen = this.websocketonopen;
      this.websocket.onerror = this.websocketonerror;
      this.websocket.onmessage = this.setOnmessageMessage;
      this.websocket.onclose = this.websocketclose;

      this.$store.commit('updataWebsocket', this.websocket)

    },
    websocketonopen() {
      console.log(
          "WebSocket连接成功!!!" + new Date() + "----" + this.websocket.readyState
      );
      let user_id = this.$store.state.user ? this.$store.state.user.id : ''
      let msg = {type: 'u_user', user_id}
      if (this.$store.state.user) {
        if (this.$store.state.user.user_authority == 10) {
          msg = {type: 'u_user', user_id}
        } else if (this.$store.state.user.user_authority == 5) {
          msg = {type: 'u_client_songsui', user_id}
        } else if (this.$store.state.user.user_authority == 4) {
          msg = {type: 'u_client', user_id}
        } else if (this.$store.state.user.user_authority == 3) {
          msg = {type: 'u_client_weixiu', user_id}
        }
      }

      this.websocket.send(JSON.stringify(msg));
    },
    websocketonerror(e) {
      e
      // console.log("WebSocket连接发生错误" + e);
      this.reconnect()
    },
    setOnmessageMessage(event) {
      if (!event) return;
      this.reset();
      console.log(event)
      const data = {
        type: 'Accept',
        content: event
      }
      this.sendBus('websocket', data)
      if (
          event.data != "pong" &&
          event.data != "Hello" &&
          event.data.split(" ")[1] != "退出了"
      ) {
        // console.log('app.vue======未读消息')
      }
    },
    websocketclose() {
      if (this.websocket) {
        this.websocket.close();
      }
      this.$store.commit('updataWebsocket', this.websocket)
      clearTimeout(this.timeoutObj);
      clearTimeout(this.serverTimeoutObj);
      console.log("WebSocket连接关闭");
    },

    sendBus(type, data) {
      Bus.$emit(type, data);
    },

    initLocation() {
      this.$jsonp("https://apis.map.qq.com/ws/location/v1/ip", {
        key: "RO3BZ-DM63Q-FEH5C-BM3OU-WKCL2-7TFL5", //这里就是要开启那个service不然会报错让你开启
        output: "jsonp", // output必须jsonp 不然会超时
      })
          .then(res => {
            if (res.status == 0) {
              this.$store.commit('updatanowPosition', res.result)
            }
          })
          .catch(error => {
            console.log(error)
          })

    },


    getAllAddress() {
      if (this.$store.state.allAddress.length <= 0) {
        getAllArea(this.$store.state.token).then(res => {
          console.log(res.data.data)
          if (res.data.status) {
            let list = res.data.data
            list.forEach(item => {
              if (!item.children) {
                item.children = item.staa
                item.children.forEach(item2 => {
                  if (!item2.children) {
                    item2.children = item2.staa
                  }
                })
              }

            })
            this.$store.commit('updateallAddress', list)
            console.log('updateallAddress', list)
          }
        }).catch(err => {
          console.log(err)
        })
      }
    },


  },
  beforeDestroy() {
    Bus.$off("updataUserInfo");
    this.timeoutObj && clearTimeout(this.timeoutObj);
    this.serverTimeoutObj && clearTimeout(this.serverTimeoutObj);
    this.websocket.close()
    this.websocket = null
  },

}

</script>

<style>
@import "./assets/css/main.css";
@import "./assets/css/quil.css";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

/* 垂直滚动条隐藏 */
.area::-webkit-scrollbar {
  width: 0;
  height: 10px;
  color: transparent;
}

/* 水平滚动条的样式 */
.area::-webkit-scrollbar-track-piece {
  background-color: #f8f8f8; /* 滚动条轨道的背景颜色 */
}

/* 滚动条的样式 */
.area::-webkit-scrollbar-thumb {
  background-color: #c1c1c1; /* 滚动条的颜色 */
  border-radius: 10px; /* 滚动条的圆角大小 */
  border: 2px solid #ffffff; /* 滚动条边框 */
}

/* 滚动条在悬停或者点击时的样式 */
.area::-webkit-scrollbar-thumb:hover {
  background-color: #a8a8a8;
}

.container::-webkit-scrollbar {
  display: none;
}

.area {
  min-height: 95vh;
  overflow: scroll;
}

.innerarea {
  background: #FFFFFF;
  border-radius: 10px 10px 10px 10px;
  border-radius: 10px;
  /* padding-top: 10px; */
  /* padding: 10px 0; */
}

@media (min-width: 768px) {
  .area, .areaT, .van-sticky {
    width: 750px !important;
    margin: 0 auto !important;
  }

  .bottomFun {
    width: 750px !important;
  }

  .vanPopBox, .van-popup {
    width: 750px !important;
  }

  .van-popup {
    transform: translate(-50%, -0%);
    left: 50% !important;

  }
}

.container {
  overflow-y: scroll;
}

.zhutiColor {
  color: #FC223B !important;
}

.dianpian {
  width: 100%;
  height: 100px;
}


.loader-box {
  width: 375px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#loader {
  width: 25px;
  height: 25px;
  border: 3px solid #ddd;
  border-radius: 50%;
  border-bottom: 3px solid #717171;
  transform: rotate(0deg);
  animation: loading 1s linear infinite;
}

@keyframes loading {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

</style>
