import { render, staticRenderFns } from "./water_coupon.vue?vue&type=template&id=023069fc&scoped=true"
import script from "./water_coupon.vue?vue&type=script&lang=js"
export * from "./water_coupon.vue?vue&type=script&lang=js"
import style0 from "./water_coupon.vue?vue&type=style&index=0&id=023069fc&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "023069fc",
  null
  
)

export default component.exports