import axios from "axios";
import store from "../store/index";
import Vue from 'vue'
import service from "@/utils/axios";
import request from "@/utils/request";

// service
console.log(axios)
const devHost = 'https://dev.taobaijie.com';
// const devHost = 'https://test.taobaijie.com';
// const devHost = 'https://pros.taobaijie.com';
const newDevHost = 'https://develop.taobaijie.com';
console.log(newDevHost)
//
// 对数组按键进行升序排序
function sortObjFun(objsend) {
    let obj2 = Object.keys(objsend)
        .sort()
        .reduce((obj, key) => {
            obj[key] = objsend[key];
            return obj;
        }, {});
    return obj2
}

//sign 加密
function initSign(obj) {
    // console.log(Vue.prototype.$md5(str)) //MD5加密
    // console.log(store.state.sign.secret_key)//密钥
    let obj1 = obj
    let str = ''
    for (const obj1Key in obj1) {
        if ((obj1[obj1Key] || (obj1[obj1Key] == 0 && typeof obj1[obj1Key] == 'number'))) {
            str += obj1Key + obj1[obj1Key]
        }
    }
    str += store.state.sign.secret_key
    return Vue.prototype.$md5(str).toUpperCase()
}

// 初始化FormData
function initForm(obj) {
    const obj1 = Object.assign({}, obj, store.state.sign.essential)
    let obj2 = sortObjFun(obj1)
    let formData = new FormData()
    console.log(JSON.stringify(obj),initSign(obj2))

    formData.append('sign', initSign(obj2))
    for (const obj1Key in obj2) {
        formData.append(obj1Key, obj2[obj1Key])
    }
    // formData.append('secret_key', store.state.sign.secret_key)
    return formData
}


// **********************************************************接口
// 获取静态文件域名
export function getAppImgOrigin() {
    let formData = initForm()
    // return service.post(`${devHost}/h5/v1.building.Seller/getAppImgOrigin`, formData)
    return service.post(`${devHost}/h5/v1.building.Seller/getAppImgOrigin`, formData)
}

//发送验证码
export function sendSms(mobile) {
    let obj = {mobile}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.SmsDemo/sendSms`, formData)
}

//验证验证码
export function yzm(mobile, code) {
    let obj = {mobile, code}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.SmsDemo/yzm`, formData)
}

// 注册登录
export function app_mobile_sign_login(mobile, password, new_password, invitecode) {
    let obj = {mobile, password, new_password, invitecode,}
    let formData = initForm(obj)
    // formData.append('secret_key')
    return service.post(`${devHost}/h5/v1.user/app_mobile_sign_login`, formData)
}

//登录
export function app_mobile_login(mobile, password) {
    let obj = {mobile, password,}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.user/app_mobile_login`, formData)
}

//水站分类
export function getAllCatlist(type) {
    let obj = {type}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.goods_cat/getAllCatlist`, formData)
}

//获取水站商品
export function get_cat_goods_list(goods_cat_id, is_hot, token) {
    let obj = {goods_cat_id, is_hot, token,}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.goods/get_cat_goods_list`, formData)
}

//获取水票
export function get_cat_package_list(goods_cat_id, is_hot) {
    let obj = {
        goods_cat_id,
        is_hot,
    }
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.goods/get_cat_package_list`, formData)
}

//获取购物车列表
export function getCartListAll(token, type = 1) {
    let obj = {token, type,}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.cart/getCartListAll`, formData)
}

// 添加购物车
export function addCar(token, goods_id, nums, num_type, type) {
    let obj = {token, goods_id, nums, num_type, type,}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.cart/cart_add`, formData)
}

//清空购物车
export function cart_del(token, goods_id) {
    let obj = {token, goods_id,}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.cart/cart_del`, formData)
}

//一键送水
export function transmit_water(token, wate_data, ship) {
    let obj = {token, wate_data, ship}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.WaterTicket/transmit_water`, formData)
}

//获取用户地址列表
export function getuserShip(token) {
    let obj = {token}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.user/getUserShip`, formData)
}

//自有空桶核验
export function sub_verification_barrel(token, nums) {
    let obj = {token, nums}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.WaterTicket/sub_verification_barrel`, formData)
}

// 获取服务器时间
export function getNowTime() {
    let formData = initForm()
    return service.post(`${devHost}/h5/v1.user/datetime`, formData)
}

// 获取用户信息
export function get_token_userinfo(token) {
    let obj = {token}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.user/get_token_userinfo`, formData)
}

// 创建订单
export function create(token, order_type, cart_ids, ship) {
    let obj = {token, order_type, cart_ids, ship}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.order/create`, formData)
}

// 获取超市商品分类
export function get_cat_goods_list_type_6() {
    let formData = initForm()
    return service.post(`${devHost}/h5/v1.goods/get_cat_goods_list_type_6`, formData)
}

//获取商品列表
export function get_list_6(token, goods_cat_id) {
    let obj = {token, goods_cat_id}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.goods/get_list_6`, formData)
}


//余额支付
export function materialspay(token, ids, payment_type, payment_code, params) {
    let obj = {token, ids, payment_type, payment_code, params}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.user/balancepay`, formData)
}

//获取订单列表
export function getOrderList(token, page, limit, status) {
    let obj = {token, page, limit, status}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.order/getOrderList`, formData)
}
//获取售后列表
export function aftersalesList(token, page, limit) {
    let obj = {token, page, limit}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.order/aftersalesList`, formData)
}

//取消订单
export function cancel(order_ids, token) {
    let obj = {token, order_ids}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.order/cancel`, formData)
}
//  订单详情
export function getOrderindex(token,order_id){
    let obj = {token, order_id}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.order/getOrderindex`, formData)
}


//获取勘察员
export function authority_list(token) {
    let obj = {token}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.order/authority_list`, formData)
}

//获取维修项目
export function get_two_cat(token, type) {
    let obj = {token, type}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.goods_cat/get_two_cat`, formData)
}

//上传图片
export function upload_file(files) {
    let formData = new FormData()
    formData.append('files', files)
    for (const obj1Key in store.state.sign.essential) {
        formData.append(obj1Key, store.state.sign.essential[obj1Key])
    }
    // console.log(axios.post(`${devHost}/lib/Upload/upload_file`, formData))
    return service.post(`${devHost}/lib/Upload/upload_file`, formData)
}

//上传视频和语音
export function upload_files(files) {
    let formData = new FormData()
    formData.append('files', files)
    for (const obj1Key in store.state.sign.essential) {
        formData.append(obj1Key, store.state.sign.essential[obj1Key])
    }
    return service.post(`${devHost}/lib/Upload/upload_files`, formData)
}

// 一键维修
export function user_apply_embellish(token, title, files, mp3, ship, authority_user_id,order_id) {
    let obj = {token, title, files, mp3, ship,authority_user_id, order_id}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.user/user_apply_embellish`, formData)
}

// 一件维修订单列表
export function maintenance_order_list(token, page, limit) {
    let obj = {token, page, limit}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.order/maintenance_order_list`, formData)
}

//正在维修订单
export function get_no_order(token, page, limit) {
    let obj = {token, page, limit}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.order/get_no_order`, formData)
}

// 取消快修订单
export function cancel_maintenanc_order(token, order_id) {
    let obj = {token, order_id}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.order/cancel_maintenanc_order`, formData)
}

// 联系客服获取客服账号id
export function getFiveCus(token, type) {
    let obj = {token, type}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.building.Seller/getFiveCus`, formData)
}

// 创建聊天室
export function kefu_list(token, cus_id, type) {
    let obj = {token, cus_id, type}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.user/kefu_list`, formData)
}

// 配置
export function config() {
    let formData = initForm()
    return service.post(`${devHost}/h5/v1.user/config`, formData)
}

// 商品详情 水站 超市
export function superGoods_index(goods_id) {
    let obj = {goods_id}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.goods/get_goods_index`, formData)
}

//tabbar大购物车
export function getCartAllType(token, is_all_type = 1) {
    let obj = {token, is_all_type}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.cart/getCartAllType`, formData)
}

//购买水票列表
export function user_water_list(token) {
    let obj = {token}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.user/user_water_list`, formData)
}

//员工端 --type 1获取工种 2获取工具
export function wokr_type_cat(token, type) {
    let obj = {token, type}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.order/wokr_type_cat`, formData)
}

// 水票记录
export function water_list(token) {
    let obj = {token}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.WaterTicket/water_list`, formData)
}

//剩余水票
export function getbaijiesp(token) {
    let obj = {token}
    let formData = initForm(obj)
    // return  service({url:`${devHost}/h5/v1.user/getbaijiesp`,method:'post',params:formData})
    return service.post(`${devHost}/h5/v1.user/getbaijiesp`, formData)
}

//员工提交建议 员工端
export function user_opinions(token, common) {
    let obj = {token, common}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.user/user_opinions`, formData)
}

//空桶盘点 数据 员工端
export function user_kt_num(token) {
    let obj = {token}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.user/user_kt_num`, formData)
}

//员工每月数据 员工端
export function worker_user_info(token, time) {
    let obj = {token, time}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.user/worker_user_info`, formData)
}

//送水工 center tabbar Nums 员工端
export function get_authority_count(token) {
    let obj = {token}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.order/get_authority_count`, formData)
}

//员工获取订单列表   员工端
export function get_authority_OrderList(token, status, page, limit) {
    let obj = {token, status, page, limit}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.order/get_authority_OrderList`, formData)
}

//员工获取换货列表
export function change_list(token, status, page, limit) {
    let obj = {token, status, page, limit}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.order/change_list`, formData)
}

//员工获取退货列表
export function reship_list(token, status, page, limit) {
    let obj = {token, status, page, limit}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.order/reship_list`, formData)
}

//送水工接单送货
export function order_user_authority_agree(token, order_id) {
    let obj = {token, order_id}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.order/order_user_authority_agree`, formData)
}

// 员工是否打卡
export function user_daka(token) {
    let obj = {token}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.user/user_daka`, formData)
}

//员工上下班打卡
export function add_work(token, work_type, ship = '') {
    let obj = {token, work_type, ship}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.user/add_work`, formData)
}

// 送水工接单退货
export function reship_order_receive(token, reship_id) {
    let obj = {token, reship_id}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.order/reship_order_receive`, formData)
}

// 送水工换货接单
export function change_order_receive(token, change_id) {
    let obj = {token, change_id}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.order/change_order_receive`, formData)
}

//送水工订单详情
export function get_authority_OrderList_index(token, order_id) {
    let obj = {token, order_id}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.order/get_authority_OrderList_index`, formData)
}
//送水工退货详情
export function reship_list_index(token,id){
    let obj = {token, id}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.order/reship_list_index`, formData)
}

//送水工换货详情
export function change_list_index(token,id){
    let obj = {token, id}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.order/change_list_index`, formData)
}


//送水工确认空桶退款
export function order_kt_goods(token, order_id, confirm_kt_img, nums, authority_remark, real_nums) {
    let obj = {token, order_id, confirm_kt_img, nums, authority_remark, real_nums}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.order/order_kt_goods`, formData)
}

//送水工   申请售后
export function tuikuan_kt(token, nums, user_id) {
    let obj = {token, nums, user_id}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.order/tuikuan_kt`, formData)
}

//确认补桶数量
export function refund_water(token, refund_water_number, order_id) {
    let obj = {token, refund_water_number, order_id}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.order/refund_water`, formData)
}

//送水工核验确认空桶数量
export function sub_verification_order_id(token, order_id, nums) {
    let obj = {token, order_id, nums}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.order/sub_verification_order_id`, formData)
}

//送水工确认收货
export function order_take_goods(token,
                                 order_id,
                                 authority_confirm_img,
                                 confirm_kt_img,
                                 replenishment,
                                 my_kt_img,
                                 refund_img,
                                 authority_remark,
                                 refund_water,
                                 huitong,
                                 songsui_count) {
    let obj = {
        token,
        order_id,
        authority_confirm_img,
        confirm_kt_img,
        replenishment,
        my_kt_img,
        refund_img,
        authority_remark,
        refund_water,
        huitong,
        songsui_count,
    }
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.order/order_take_goods`, formData)
}

// 送水工确认退货订单
export function reship_order_confirm(token, reship_id, img, Remarks){
    let obj = {token, reship_id, img, Remarks}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.order/reship_order_confirm`, formData)
}
// 送水工确认换货订单
export function change_order_confirm(token, change_id, img, Remarks){
    let obj = {token, change_id, img, Remarks}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.order/change_order_confirm`, formData)
}
//获取某月打卡记录
export function get_work_list(token,ctime,stime){
    let obj = {token, ctime, stime}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.user/get_work_list`, formData)
}
//绩效查询
export function select_work(token,time){
    let obj = {token, time}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.user/select_work`, formData)
}
//首页获取推荐商品
export function getPromoteGoodsList(page,limit){
    let obj = {page,limit}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.building.BuildingMaterials/getPromoteGoodsList`,formData)
    // return service.post(`${devHost}/api/building.BuildingMaterials/getPromoteGoodsList`,formData)
}
// 获取浏览器iconfont
export function icontont() {
    return service.post(`${devHost}/admin/config/config_shop_logo`);
}
// 空桶盘点列表
export function brand(token) {
    let obj = {token};
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.user/brand`, formData)
}
// 获取盘点列表
export function pandianList(token,limit) {
    let obj = {token,limit};
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.user/pandian_list`, formData)
}
// 盘点确定
export function pandian(token,datas) {
    let obj = {token,datas};
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.user/pandian`, formData)
}
//获取vip会员卡列表
export function vip_order(token,type) {
    let obj = {token,type};
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.Vip/index`, formData)
}
//是否vip
export function is_vip(token,type){
    let obj = {token,type};
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.Vip/check`, formData)
}
//购买vip   vip创建专属订单
export function vipOrder(token,type,id,num,vip_type) {
    let obj = {token,id,num,vip_type};
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.Vip/order`, formData)
}
//勘察员的待接单
export function get_authority_noReceiving_list(token,page,limit){
    let obj = {token,page,limit};
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.order/get_authority_noReceiving_list`, formData)
}
//是否质保
export function get_zhibao_status(token,order_id){
    let obj = {token,order_id};
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.order/get_zhibao_status`, formData)
}
//勘察员的已接单
export function get_authority_Receiving_list(token,page,limit,status){
    let obj = {token,page,limit,status};
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.order/get_authority_Receiving_list`, formData)
}

//勘察员 center tabbar Nums 勘察员徽标
export function get_kanchayuan_count(token){
    let obj = {token};
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.order/get_kanchayuan_count`, formData)
}

//勘察员的接单
export function Receiving_authority_agree(token,order_id){
    let obj = {token,order_id};
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.order/Receiving_authority_agree`, formData)
}

//勘察员上门打卡
export function up_authority_order(token,authority_user_img,order_id){
    let obj = {token,authority_user_img,order_id};
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.order/up_authority_order`, formData)
}

//工具清单
export function tool(token,type){
    let obj = {token,type};
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.user/tool`, formData)
}
//获取维修工列表
export function get_authority_list(token,time){
    let obj = {token,time};
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.user/get_authority_list`, formData)
}

//勘察员创建单子   提交订单  派单
export function Receiving_authority_create(
    token,order_id,maintenance_user_id,surveyor_img,material_img,
    yvyue_maintenance_time,maintenance_type,notes,tool,material,
    material_price,maintenance_price,maintenance_content
){
    let obj = {token,order_id,maintenance_user_id,surveyor_img,material_img,
        yvyue_maintenance_time,maintenance_type,notes,tool,material,
        material_price,maintenance_price,maintenance_content};
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.order/Receiving_authority_create`, formData)
}

//勘察员修改单子   修改订单  修改派单
export function Receiving_authority_up(
    token,order_id,maintenance_user_id,surveyor_img,material_img,
    yvyue_maintenance_time,maintenance_type,notes,tool,material,
    material_price,maintenance_price,maintenance_content
) {
    let obj = {
        token, order_id, maintenance_user_id, surveyor_img, material_img,
        yvyue_maintenance_time, maintenance_type, notes, tool, material,
        material_price, maintenance_price, maintenance_content
    };
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.order/Receiving_authority_up`, formData)
}


// 维修工徽标
export function get_weixiu_count(token){
    let obj = {token};
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.order/get_weixiu_count`, formData)
}
//维修工 的未接单
export function order_ship_maintenance_list(token,page,limit){
    let obj = {token,page,limit};
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.order/order_ship_maintenance_list`, formData)
}
//维修工的接单
export function  maintenance_Receiving(token,order_id){
    let obj = {token,order_id};
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.order/maintenance_Receiving`, formData)
}

//维修工 的已接单
export function order_ship_maintenance_ok_list(token,page,limit){
    let obj = {token,page,limit};
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.order/order_ship_maintenance_ok_list`, formData)
}
//维修工 的接单记录
export function maintenance_order_ship_ok_list(token,page,limit){
    let obj = {token,page,limit};
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.order/maintenance_order_ship_ok_list`, formData)
}

//维修工上门打卡
export function up_maintenance_user(token,maintenance_user_img,order_id) {
    let obj = {token, maintenance_user_img, order_id};
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.order/up_maintenance_user`, formData)
}


//维修工确认完成维修
export function maintenance_order_ship_ok(token,order_id,maintenance_img,maintenance_tishi) {
    let obj = {token, order_id,maintenance_img,maintenance_tishi};
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.order/maintenance_order_ship_ok`, formData)
}

//用户上传快修问题
export function add_maintenance_wenti(token,order_id,title,felis,mp3='') {
    let obj = {token, order_id, title,felis,mp3};
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.order/add_maintenance_wenti`,formData)
}
//维修用户验收通过
export function order_confirm(token,order_id){
    let obj = {token, order_id};
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.order/order_confirm`, formData)
}
//维修订单评论
export function order_comments(token,order_id,content,contents,datas){
    let obj = {token, order_id,content,contents,datas};
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.order/order_comments`, formData)
}
//闲置转让   周边好物
// export function getTopSearchList(token,page,limit){}

//闲置转让   中部区域金刚区
export function getCategory(token){
    let obj = {token}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.recovery.Plate/getCategory`, formData)
}
//消息列表
export function getShopMessageList(token){
    let obj = {token};
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.building.BuildingMaterials/getShopMessageList`, formData)
}

//清除未读
export function upUnread(token){
    let obj = {token};
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.building.BuildingMaterials/upUnread`, formData)
}
//闲置   商品详情
export function getProductdetail(token,id){
    let obj = {token,id};
    const obj1 = Object.assign({}, obj, store.state.sign.essential)
    let obj2 = sortObjFun(obj1)
    return service.get(
        `${devHost}/h5/v1.recovery.Product/getProductdetail?token=${token}&id=${id}&app_id=${obj2.app_id}&sign_key=${obj2.sign_key}&version=${obj2.version}&sign=${initSign(obj2)}`)
}
//闲置   闲置好物商品列表
export function getPeripProductList(token='',page,limit){
    let obj = {token,page,limit};
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.recovery.Plate/getPeripProductList`, formData)
}
//闲置 获取轮播图
export function getLunboList(token=''){
    let obj = {token};
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.recovery.Plate/getLunboList`, formData)
}
//闲置  自由市场列表
export  function  getFreeMarkList(token){
    let obj = {token};
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.recovery.Plate/getFreeMarkList`, formData)
}
// 获取所有地区
export function getAllArea(token){
    let obj = {token}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.GoodsCat/getAllArea`, formData)
}
// 闲置  发布
export function publish(
    token,
    product_name,//商品名称
    category_id,//分类
    images,//图片
    price,//售价
    origin_price,//商品原价
    brand,//品牌
    warranty,//质保
    product_mode,//型号
    use_date,//使用日期
    is_invoice,//是否有发票 0 -无，1-有
    is_sale,//是否预售 1是 2否
    pre_sale_date,//预售时间
    address,//取件地址
    detail_address,//详细地址
    product_desc,//简介
    train_time,//交易时间
){
    let obj = {token,
        product_name,
        category_id,
        images,
        price,
        origin_price,
        brand,
        warranty,
        product_mode,
        use_date,
        is_invoice,
        is_sale,
        pre_sale_date,
        address,
        detail_address,
        product_desc,train_time};
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.recovery.Product/publish`, formData)
}


// 闲置  编辑发布
export function updatePublish(
    token,
    product_name,//商品名称
    category_id,//分类
    images,//图片
    price,//售价
    origin_price,//商品原价
    brand,//品牌
    warranty,//质保
    product_mode,//型号
    use_date,//使用日期
    is_invoice,//是否有发票 0 -无，1-有
    is_sale,//是否预售 1是 2否
    pre_sale_date,//预售时间
    address,//取件地址
    detail_address,//详细地址
    product_desc,//简介
    train_time,//交易时间
    id,
){
    let obj = {token,
        product_name,
        category_id,
        images,
        price,
        origin_price,
        brand,
        warranty,
        product_mode,
        use_date,
        is_invoice,
        is_sale,
        pre_sale_date,
        address,
        detail_address,
        product_desc,train_time,id,};
    let formData = initForm(obj)
    return service.post(`${devHost}/h5//v1.recovery.Plate/updatePublish`, formData)
}
//闲置   获取个人实名认证
export function getRegAuthentDetail(token){
    let obj = {token}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.recovery.Product/getRegAuthentDetail`, formData)
}

//闲置 我的  我的发布 上下架
export function enablePublish(token,id,on_sale){
    //1上架 2下架
    let obj = {token,id,on_sale}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.recovery.Plate/enablePublish`, formData)
}
//闲置  我的   我的发布 删除
export function delPublish(token,id){
    let obj = {token,id}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.recovery.Plate/delPublish`, formData)
}


//闲置转让  实名认证提交
export function regAuthentication(token,valid_type,name,idcard,img){
    let obj = {token,valid_type,name,idcard,img}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.recovery.Plate/regAuthentication`, formData)
}

//闲置转让  店铺认证提交
export function regAuthenticationShop(token,shop_name,logo,img){
    let obj = {token,shop_name,logo,img}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.recovery.Plate/regAuthenticationShop`, formData)
}
//闲置转让   获取店铺认证信息
export function getRegAuthshop(token){
    let obj = {token}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.recovery.Plate/getRegAuthshop`, formData)
}
//闲置转让   用户报价
export function quote(token,product_id,quote_price,shop_id){
    let obj = {token,product_id,quote_price,shop_id}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.recovery.Product/quote`, formData)
}
//获取 用户快修质保
export function get_user_order(token,user_id){
    let obj = {token,user_id}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.order/get_user_order`, formData)
}

//获取 用户装修质保
export function get_user_construction(token,user_id){
    let obj = {token,user_id}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.order/get_user_construction`, formData)
}
//闲置转让  校验是否已报价
export function checkIsQuote(token,product_id){
    let obj = {token,product_id}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.recovery.Product/checkIsQuote`, formData)
}
//闲置转让   收藏
export function recovercollect(token,product_id,is_collect){
    let obj = {token,product_id,is_collect}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.recovery.Product/collect`, formData)
}
//闲置转让  获取问题反馈分类  类型 1问题类型 2举报类型 4回收入驻类型 5搬家入驻类型  6发布店铺转让获取店铺类型 7发布办公转租获取写字楼类型 8发布办公转租获取写字楼装修 9店铺标签 10办公转租押金押方式 11办公转租押金付方式 12起/免租期 13办公转租标签
export function getQuestionCate(token,type){
    let obj = {token,type}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.recovery.Plate/getQuestionCate`, formData)
}
//闲置转让  问题反馈提交
export function createQuestion(token,cate_id='',desc='',img=''){
    let obj = {token,cate_id,desc,img}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.recovery.Plate/createQuestion`, formData)
}
//闲置转让  我的发布
export function getMyPublishList(token,limit,page,is_sale){
    let obj = {token,limit,page,is_sale}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.recovery.Plate/getMyPublishList`, formData)
}
//闲置转让  个人中心 - 获取数量
export function getListCount(token){
    let obj = {token}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.recovery.Plate/getListCount`, formData)
}
//闲置转让  我卖出的 - 全部商品列表 默认传空(获取全部) 1出价中 2待取货 3已完成 4已取消
export function getMySaleList(token,status,page,limit){
    let obj = {token,status,page,limit}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.recovery.Plate/getMySaleList`, formData)

}
//闲置转让  我卖出的   同意或者拒绝
export function enableQuote(token,quote_id,product_id,is_agree){
    //  is_agree 1同意 2拒绝
    let obj = {token,quote_id,product_id,is_agree}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.recovery.Plate/enableQuote`, formData)
}
//闲置转让  我卖出的 - 待取货详情
export function getMySaleDetail(token,quote_id){
    let obj = {token,quote_id}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.recovery.Plate/getMySaleDetail`, formData)
}


//闲置转让  我买到的 - 全部商品列表
export function getBuyList(token,is_all,page,limit){
    let obj = {token,is_all,page,limit}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.recovery.Plate/getBuyList`, formData)
}
//闲置转让 我买到的 用户取消交易
export function cancelBuyQuote(token,quote_id,product_id){
    let obj = {token,quote_id,product_id}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.recovery.Plate/cancelBuyQuote`, formData)
}
//闲置转让 我买到的  删除订单
export function delBuyQuote(token,quote_id,product_id){
    let obj = {token,quote_id,product_id}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.recovery.Plate/delBuyQuote`, formData)
}
//闲置转让 我买到的  确认收货
export function enableBuyQuote(token,quote_id,product_id){
    let obj = {token,quote_id,product_id}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.recovery.Plate/enableBuyQuote`, formData)
}
//闲置转让 我买到的  确认收货






//闲置转让  我的收藏商品列表
export function collectList(token,is_sale,page,limit){
    let obj = {token,is_sale,page,limit}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.recovery.Product/collectList`, formData)
}

//闲置转让    常见问题 - 获取分类类型
export function getFeedBackData(){
    let obj = {}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.recovery.Plate/getFeedBackData`, formData)
}
//闲置转让  常见问题 - 问题详情页面
export function getFeedBackItem(token,f_id){
    let obj = {token,f_id}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.recovery.Plate/getFeedBackItem`, formData)
}
//闲置转让  新增回收/搬家入驻类型
export function createRecoveryShopType(token,recovery_type,type){
    let obj = {token,recovery_type,type}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.recovery.Plate/createRecoveryShopType`, formData)
}
//闲置转让 回收/搬家入驻
export function createRecovery(token,reg_type,city_id,area_id,recovery_type,type){
    let obj = {token,reg_type,city_id,area_id,recovery_type,type}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.recovery.Plate/createRecovery`, formData)
}

//配套设施
export function HousingMatchingSetting_list(token){
    let obj = {token}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.HousingMatchingSetting/HousingMatchingSetting_list`, formData)
}
//闲置发布    店铺列表
export function getCategoryShopList(category_id,word,limit,page){
    let obj = {category_id,word,limit,page}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.recovery.Plate/getCategoryShopList`, formData)
}
//发布店铺/办公转租 - 获取行业数据类型列表
export function getIndustryConfigList(token){
    let obj = {token}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.recovery.Product/getIndustryConfigList`, formData)
}
//闲置发布 - 发布店铺
export function publishShop(
    token,category_id,img,title,area,
    address,shop_type,insdustry,
    measure,rent,lease_term,transfer_cost,
    desc,plege,symbol,use,is_agree,number,
    tag,type,area_id,city_id,origin_id,logo,info,shop_user_img
){
    let obj = {shop_user_img,logo,info,area_id,city_id,origin_id,type,number,use,is_agree,symbol,plege,transfer_cost,lease_term,insdustry,token,category_id,img,title,area,address,shop_type,measure,rent,desc,tag}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.recovery.Product/publishShop`, formData)
}
//闲置发布
export function getcateProductList(token,page,limit,category_id,product_name){
    let obj = {token,page,limit,category_id,product_name}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.recovery.Product/getcateProductList`, formData)
}


//闲置   店铺闲置 - 店铺详情
export function PlategetProductdetail(token,id){
    let obj = {token,id};
    const obj1 = Object.assign({}, obj, store.state.sign.essential)
    let obj2 = sortObjFun(obj1)
    return service.get(
        `${devHost}/h5/v1.recovery.Plate//getProductshopDetail?token=${token}&id=${id}&app_id=${obj2.app_id}&sign_key=${obj2.sign_key}&version=${obj2.version}&sign=${initSign(obj2)}`)

    // return axios.get(
    //     `${devHost}/h5/v1.recovery.Plate//getProductshopDetail?token=${token}&id=${id}&app_id=${obj2.app_id}&sign_key=${obj2.sign_key}&version=${obj2.version}&sign=${initSign(obj2)}`)
}
//用户地址 列表
export function getUserShip(token){
    let obj = {token};
    const obj1 = Object.assign({}, obj, store.state.sign.essential)
    let obj2 = sortObjFun(obj1)
    return service.get(
        `${devHost}/h5/v1.user/getUserShip?token=${token}&app_id=${obj2.app_id}&sign_key=${obj2.sign_key}&version=${obj2.version}&sign=${initSign(obj2)}`)
}
//确认选择地址
export function getUserShip_ster(token,id){
    let obj = {token,id};
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.user/getUserShip_ster`,formData)
}

//用户地址 列表
export function editShip(token,
                         name,
                         address,
                         mobile,
                         is_def,
                         province_name,
                         city_name,
                         district_name,
                         province_id,
                         city_id,
                         district_id,
                         details_name,
                         id,
){
    let obj = {token,name,address,mobile,is_def,province_name,
        city_name,
        district_name,
        province_id,
        city_id,
        district_id,
        details_name,}
    if(id){
        obj.id = id
    }
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.user/editShip`,formData)
}
//删除地址
export function removeShip(token,id) {
    let obj = {token,id}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.user/removeShip`,formData)
}

//首页 金刚区 show_position   1首页头图 2首页金刚区 3首页轮播图 4室内快修 5超市轮播图 6闲置轮播图 7招聘首页轮播图 8招聘招聘者轮播图 9求职者轮播图 10 其它
export function getConfigImage(show_position,client_type){
    let obj = {show_position,client_type}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.recovery.Product/getConfigImage`,formData)
}
//支付宝  支付宝支付
export function Alipay_h5_pay(params,token,ids){
    let obj = {params,token,ids}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.user/h5_pay`,formData)
}
//闲置转让  获取用户店铺、
export function getUserShop(token){
    let obj = {token}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.recovery.Plate/getUserShop`,formData)
}
//闲置转让  个人商家主页详情
export function getUserShopDetail(token,user_id,category_id){
    let obj = {token,user_id,category_id}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.recovery.Product/getUserShopDetail`,formData)
}
//闲置转让  获取回收/搬家入住列表
export function getRecoveryShopList(token,type){
    let obj = {token,type}
    let formData = initForm(obj)
    return service.post(`${devHost}/h5/v1.recovery.Plate/getRecoveryShopList`,formData)
}



//求职招聘  行业列表
export function industry_list(){
    let obj = {}
    let formData = initForm(obj)
    return service.post(`${devHost}/app/v1.recruit.Industry/industry_list`,formData)
}
//求职招聘  创建求职 个人简历
export function createJobSeeker(
    token,user_name,user_age,job_type,industry_id,salary_min,salary_max,
    province_id,city_id,district_id,province_name,city_name,district_name,
    school,degree,profession,graduation_time,user_job_status,advantage,
    project_experience,work_experience,working_years,user_avatar
){
    let obj = {token,user_name,user_age,job_type,industry_id,salary_min,salary_max,
        province_id,city_id,district_id,province_name,city_name,district_name,
        school,degree,profession,graduation_time,user_job_status,advantage,
        project_experience,work_experience,working_years,user_avatar}
    let formData = initForm(obj)
    return service.post(`${devHost}/app/v1.recruit.JobSeeker/create`,formData)
}

//求职招聘  查询职个人简历
export function seekJobSeeker(token,userId){
    let obj = {token,userId}
    let formData = initForm(obj)
    return service.post(`${devHost}/app/v1.recruit.JobSeeker/detail`,formData)
}

//求职招聘  修改 个人简历
export function updateJobSeeker(
    token,
    user_name,
    user_age,
    job_type,
    industry_id,
    salary_min,
    salary_max,
    province_id,
    city_id,
    district_id,
    province_name,
    city_name,
    district_name,
    school,
    degree,
    profession,
    graduation_time,
    user_job_status,
    advantage,
    project_experience,
    work_experience,
    working_years,
    user_avatar,
    id
){
    let obj = {token,user_name,user_age,job_type,industry_id,salary_min,salary_max,
        province_id,city_id,district_id,province_name,city_name,district_name,
        school,degree,profession,graduation_time,user_job_status,advantage,
        project_experience,work_experience,working_years,user_avatar,id}
    let formData = initForm(obj)
    return service.post(`${devHost}/app/v1.recruit.JobSeeker/update`,formData)
}
//求职招聘  招聘列表
export function Job_list(token,page,limit,page_view,ctime_status,position,job_type,industry_id,industry_type_id){
    let obj = {token,page,limit,page_view,ctime_status,position,job_type,industry_id,industry_type_id}
    let formData = initForm(obj)
    return service.post(`${devHost}/app/v1.recruit.Job/Job_list`,formData)
}

//求职招聘
export function getJob(token,id){
    let obj = {token,id}
    let formData = initForm(obj)
    return service.post(`${devHost}/app/v1.recruit.Job/getJob`,formData)
}
//求职招聘  附件简历列表
export function resumelist(token){
    let obj = {token}
    let formData = initForm(obj)
    return service.post(`${devHost}/app/v1.recruit.Resume/resumelist`,formData)
}
//求职招聘 上传附件简历
export function createResume(token,file_url,file_name){
    let obj = {token,file_url,file_name}
    let formData = initForm(obj)
    return service.post(`${devHost}/app/v1.recruit.Resume/createResume`,formData)
}
//求职招聘  删除附件简历
export function deleteResume(token,id){
    let obj = {token,id}
    let formData = initForm(obj)
    return service.post(`${devHost}/app/v1.recruit.Resume/deleteResume`,formData)
}
//求职招聘  查询个人认证
export function authentication_detail(token,userId){
    let obj = {token,userId}
    let formData = initForm(obj)
    return service.post(`${devHost}/app/v1.recruit.PersonageAuthentication/authentication_detail`,formData)
}
//求职招聘  个人 认证 创建
export function authentication_create(token,user_name,Identity_id,identity_positive,identity_reverse) {
    let obj = {token,user_name,Identity_id,identity_positive,identity_reverse}
    let formData = initForm(obj)
    return service.post(`${devHost}/app/v1.recruit.PersonageAuthentication/authentication_create`,formData)
}
//求职招聘  添加收藏
export function add_invite_Collection(token,job_id,type){
    let obj = {token,job_id,type}
    let formData = initForm(obj)
    return service.post(`${devHost}/app/v1.recruit.Collection/add_Collection`,formData)
}
//求职招聘  取消收藏  全部
export function del_invite_Collection(token,id,type){
    let obj = {token,id,type}
    let formData = initForm(obj)
    return service.post(`${devHost}/app/v1.recruit.Collection/del_Collection`,formData)
}
// 求职招聘  收藏列表 岗位i
export function Collection_type2(token,page,limit){
    let obj = {token,page,limit}
    let formData = initForm(obj)
    return service.post(`${devHost}/app/v1.recruit.Collection/Collection_type2`,formData)
}
// 求职招聘  收藏列表 岗位i
export function Collection_type1(token,page,limit){
    let obj = {token,page,limit}
    let formData = initForm(obj)
    return service.post(`${devHost}/app/v1.recruit.Collection/Collection_type1`,formData)
}
//求职招聘  切换求职招聘身份
export function change_invite(token){
    let obj = {token}
    let formData = initForm(obj)
    return service.post(`${devHost}/app/v1.recruit.Job/qiehuan`,formData)
}
//求职招聘  查询企业下的职位
export function job_Company_list(token,f_id,page,limit,status=''){
    let obj = {token,f_id,page,limit,status}
    let formData = initForm(obj)
    return service.post(`${devHost}/app/v1.recruit.Job/job_Company_list`,formData)
}
//求职招聘  查询/1工作经验 /2学历/ 3求职状态/4薪资
export function jobcaet_list(token){
    let obj = {token}
    let formData = initForm(obj)
    return service.post(`${devHost}/app/v1.recruit.JobCaet/jobcaet_list`,formData)
}
//求职招聘   查询企业认证列表
export function Company_list(token){
    let obj = {token}
    let formData = initForm(obj)
    return service.post(`${devHost}/app/v1.recruit.Company/Company_list`,formData)
}
//个人简历上下架
export function up_seeker_status(token,id){
    let obj = {token,id}
    let formData = initForm(obj)
    return service.post(`${devHost}/app/v1.recruit.JobSeeker/up_seeker_status`,formData)
}
//查询 企业类型列表
export function getrecruitfirmtype(){
    let obj = {}
    let formData = initForm(obj)
    return service.post(`${devHost}/app/v1.recruit.RecruitFirmType/getrecruitfirmtype`,formData)
}
//创建企业认证
export function create_company(
    token,
    business_license_name,
    company_type,
    business_license_images,
    corporate_capacity_images,
    name,
    province_id,
    city_id,
    district_id,
    province_name,
    city_name,
    district_name,
    address,
    company_nums,
    logo_image,
){
    let obj = {district_name,city_id,token, business_license_name, company_type, business_license_images, corporate_capacity_images, name, province_id, district_id, province_name, city_name, address, company_nums, logo_image,}
    let formData = initForm(obj)
    return service.post(`${devHost}/app/v1.recruit.Company/create_company`,formData)
}

//切换企业
export function update_company_acquiesce(token,id){
    let obj = {token,id}
    let formData = initForm(obj)
    return service.post(`${devHost}/app/v1.recruit.Company/update_company_acquiesce`,formData)
}

//查询企业认证详情
export function getCompany(token,id){
    let obj = {token,id}
    let formData = initForm(obj)
    return service.post(`${devHost}/app/v1.recruit.Company/getCompany`,formData)
}
//修改 企业介绍
export function update_company_introduction(token,id,introduction,logo_image){
    let obj = {token,id,introduction,logo_image}
    let formData = initForm(obj)
    return service.post(`${devHost}/app/v1.recruit.Company/update_company_introduction`,formData)
}
//常用语句
export function getRecruitLanguageList(token){
    let obj = {token}
    let formData = initForm(obj)
    return service.post(`${devHost}/app/v1.recruit.RecruitLanguage/getRecruitLanguageList`,formData)
}
//添加常用语
export function createLanguage(token,content){
    let obj = {token,content}
    let formData = initForm(obj)
    return service.post(`${devHost}/app/v1.recruit.RecruitLanguage/createLanguage`,formData)
}
//修改常用语句
export function updateLanguage(token,id,content){
    let obj = {token,id,content}
    let formData = initForm(obj)
    return service.post(`${devHost}/app/v1.recruit.RecruitLanguage/updateLanguage`,formData)
}

//修改企业认证
export function update_company(
    token,
    id,
    business_license_name,
    company_type,
    business_license_images,
    corporate_capacity_images,
    name,
    province_id,
    city_id,
    district_id,
    province_name,
    city_name,
    district_name,
    address,
    company_nums,
    logo_image,
){
    let obj = {id,district_name,city_id,token, business_license_name, company_type, business_license_images, corporate_capacity_images, name, province_id, district_id, province_name, city_name, address, company_nums, logo_image,}
    let formData = initForm(obj)
    return service.post(`${devHost}/app/v1.recruit.Company/update_company`,formData)
}
//求职使用积分配置
export function jobpoints_list(token){
    let obj = {token}
    let formData = initForm(obj)
    return service.post(`${devHost}/app/v1.recruit.JobPoints/jobpoints_list`,formData)
}
//创建招聘
export function createJob(
    token,
    position,
    job_type,
    industry_id,
    // province_id,
    // city_id,
    // district_id,
    // province_name,
    // city_name,
    // district_name,
    // address,
    experience_required,
    salary_min,
    salary_max,
    job_requirements,
    is_phone,
    f_id,
    phone_count,
    chat_count,
    Integral_usage,
    benefits,
    u_phone,
    u_name,
    industry_type_id,
    ){
    let obj = {token,
        position,
        job_type,
        industry_id,
        // province_id,
        // city_id,
        // district_id,
        // province_name,
        // city_name,
        // district_name,
        // address,
        experience_required,
        salary_min,
        salary_max,
        job_requirements,
        is_phone,
        f_id,
        phone_count,
        chat_count,
        Integral_usage,
        benefits,
        industry_type_id,
    }
    if(u_phone){
        obj.u_phone = u_phone
    }
    if(u_name){
        obj.u_phone = u_name
    }
    let formData = initForm(obj)
    return service.post(`${devHost}/app/v1.recruit.Job/createJob`,formData)
}
//查询求职列表
export function jobseeker_list(token,page,limit,province_id,city_id,district_id,degree,job_type,industry_id,working_years,user_job_status,salary){
    let obj = {token,page,limit,province_id,city_id,district_id,degree,job_type,industry_id,working_years,user_job_status,salary}
    let formData = initForm(obj)
    return service.post(`${devHost}/app/v1.recruit.JobSeeker/jobseeker_list`,formData)
}


//岗位上下架
//个人简历上下架
export function up_seeker_status2(token,id){
    let obj = {token,id}
    let formData = initForm(obj)
    return service.post(`${devHost}/app/v1.recruit.job/up_seeker_status`,formData)
}
//获取空桶价格
export function getKongtong(token){
    let obj = {token}
    let formData = initForm(obj)
    return service.post(`${devHost}/app/v1.Goods/kongtong`,formData)
}
//获取空桶押金记录  获取空桶列表
export function order_kongtong_list(token){
    let obj = {token}
    let formData = initForm(obj)
    return service.post(`${devHost}/app/v1.order/order_kongtong_list`,formData)
}
//创建空桶订单 购买空桶
export function addOrder(token,cart_ids,ship,order_type){
    let obj = {token,cart_ids,ship,order_type}
    let formData = initForm(obj)
    return service.post(`${devHost}/app/v1.order/create`,formData)
}
//获取所有空桶数量
export function get_user_kt(token){
    let obj = {token}
    let formData = initForm(obj)
    return service.post(`${devHost}/app/v1.user/get_user_kt`,formData)
}
//获取空桶押金退回记录
export function user_ktyj(token){
    let obj = {token}
    let formData = initForm(obj)
    return service.post(`${devHost}/app/v1.user/user_ktyj`,formData)
}




//````````````````````````1.1.0接口``````````````````````````
export function v1Login(account,password){
    let formData = new FormData()
    formData.append('account',account)
    formData.append('password',password)
    return request.post(`${newDevHost}/api/login`,formData)
}
export function getUserInfo(){
    return request.get(`${newDevHost}/api/user`)
}
//获取品牌列表
export function getWaterBrand(){
    return request.get(`${newDevHost}/api/brand`)
}
//获取获取分类
export function getWatercategory(){
    return request.get(`${newDevHost}/api/category`)
}
//获取商品列表
export function getWaterproductsList(page,limit,type,cid,sid,tid){
    return request.get(`${newDevHost}/api/products?page=${page}&limit=${limit}&type=${type}&cid=${cid}&sid=${sid}&tid=${tid}`)
}
//购物车修改（新）
export function set_cart_num(product_id,num,unique,type,cat){
    let formData = new FormData()
    formData.append('product_id',product_id)
    formData.append('num',num)
    formData.append('unique',unique)
    formData.append('type',type) // -1 直接修改，0 减少， 1 增加
    formData.append('cat',cat) //1水站  2超市
    return request.post(`${newDevHost}/api/v2/set_cart_num`,formData)
}
//获取购物车数量
export function getCartNums(numType,cat){
    return request.get(`${newDevHost}/api/cart/count?numType=${numType}&store_id=&cat=${cat}`)/*cat 1水站 2超市*/
}
//获取购物车列表
export function getCartList(cat){
    return request.get(`${newDevHost}/api/v2/cart_list?cat=${cat}`) /*cat 1水站 2超市*/
}
//清空购物车
export function delCartList(ids){
    let formData = new FormData()
    formData.append('ids',ids)
    return request.post(`${newDevHost}/api/cart/del`,formData)
}
//获取商品详情
export function getProductDetails(id){
    return request.get(`${newDevHost}/api/product/detail/${id}`)
}
//商品规格（新）
export function get_attr(id,type){
    //type   0正常1秒杀2砍价3拼团
    return request.get(`${newDevHost}/api/v2/get_attr/${id}/${type}`)
}
//获取用户默认地址
export function defaultAddress(){
    return request.get(`${newDevHost}/api/address/default`)
}
//检测是否显示快递和自提标签
export function check_shipping(){
    return request.get(`${newDevHost}/api/address/default`)
}
// 用户地址列表
export function addressList(page,limit){
    return request.get(`${newDevHost}/api/address/list?page=${page}&limit=${limit}`)
}
// 修改/添加地址
export function addressEdit(data){
    // let formData = new FormData()
    // formData.append('address',address)
    // formData.append('is_default',is_default)
    // formData.append('real_name',real_name)
    // formData.append('phone',phone)
    // formData.append('id',id)
    return request.post(`${newDevHost}/api/address/edit`,data)
}
//获取城市数据
export function city_list(pid){
    return request.get(`${newDevHost}/api/city?pid=${pid}`)
}
//获取单个地址
export function addressDetails(id){
    return request.get(`${newDevHost}/api/address/detail/${id}`)
}
// 订单确认
export function orderConfirm(data){
    return request.post(`${newDevHost}/api/order/confirm`,data)
}
//获取楼层房号
export function user_ship_address(){
    return request.get(`${newDevHost}/api/address/user_ship_address`)
}
//删除地址
export function deleteAddress(data){
    return request.post(`${newDevHost}/api/address/del`,data)
}
//订单创建
export function createOrder(key,data){
    return request.post(`${newDevHost}/api/order/create/${key}`,data)
}
//订单支付
export function orderPay(data){
    return request.post(`${newDevHost}/api/order/pay`,data)
}
//计算订单金额
export function computedOrder(key,data){
    return request.post(`${newDevHost}/api/order/computed/${key}`,data)
}
/*
* 空桶购买记录
* */
export function apiorder_kt_list(){
    return request.get(`${newDevHost}/api/order_kt_list`)
}

/*
* 水票记列表
* */
export function newwater_list(type,page,limit,search){
    return request.get(`${newDevHost}/api/order/water_list?type=${type}&page=${page}&limit=${limit}&search=${search}`)
}
/*
* 一键送水
* */
export function water_create(addressId,mark,totalNum,water_id,nums){
    let data = {
        addressId,
        mark,
        totalNum,
        water_id,
        nums,
    }
    return request.post(`${newDevHost}/api/order/water_create`,data)
}
/*
* 个人水站配置
* */
export function user_kt_list(){
    return request.get(`${newDevHost}/api/kt_list`)
}
/*
* 是否自由空桶审核
* */
export function water_user_kt_list(){
    return request.post(`${newDevHost}/api/order/water_user_kt_list`)
}
/*
* 申请自由空桶
* */

export function water_kt_create(nums){
    let data = {
        nums
    }
    return request.post(`${newDevHost}/api/order/water_kt_create`,data)
}
/*
* 水票记录
* */
export function newwater_lists(type,page,limit,search){
    return request.get(`${newDevHost}/api/order/water_lists?type=${type}&page=${page}&limit=${limit}&search=${search}`)
}
/*
* 订单列表 ''=全部(不传),0=待支付,1=待发货,2=待收货,3=待评价,4=已完成,-1=退款中,-2=已退款,-3=退款
* */
export function order_list(type,page,limit,search){
    return request.get(`${newDevHost}/api/order/list?status=${type}&page=${page}&limit=${limit}&search=${search}`)
}
/*
* 订单取消
* */
export function cancelOrder(data){
    return request.post(`${newDevHost}/api/order/cancel`,data)
}
/*
* 订单详情
* */
export function orderDetails(uni){
    return request.get(`${newDevHost}/api/order/detail/${uni}`)
}
/*
* 用户发票列表
* */

export function invoiceList(){
    return request.get(`${newDevHost}/api/v2/invoice`)
}
/*
* 获取退款商品列表
* */
export function refund_cart_info(id){
    return request.get(`${newDevHost}/api/order/refund/cart_info/${id}`)
}
/*
* 订单退款理由
* */
export function refund_reason() {
    return request.get(`${newDevHost}/api/order/refund/reason`)
}
/*
* 订单申请退款V2
* */
export function refundApply(id,data){
    return request.post(`${newDevHost}/api/order/refund/apply/${id}`,data)
}
/*
* 获取退款商品列表
* */
export function cart_info(data){
    return request.post(`${newDevHost}/api/order/refund/cart_info`,data)
}
/*
* 是否是配送员
* */
export function is_peisongyuan(){
    return request.post(`${newDevHost}/api/store/delivery/is_peisongyuan`)
}
/*
* 获取配送员信息
* */
export function deliverInfo(){
    return request.get(`${newDevHost}/api/store/delivery/info`)
}
/*
* 配送员订单订单合并
* */
export function order_no_count(){
    return request.get(`${newDevHost}/api/store/delivery/order_no_count`)
}
/*
* 配送员订单列表
* */
export function deliverOrder(unit_id) {
    return request.get(`${newDevHost}/api/store/delivery/order?unit_id=${unit_id}`)
}
/*
* 配送员接单接口
* */
export function deliveryKeep(id,data){
    return request.post(`${newDevHost}/api/admin/order/delivery/keep/${id}`,data)
}

