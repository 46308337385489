!<template>
  <div>

      <router-view />
      <div class="outBox"  ref="homeTabbar">
<!--        <div class="appInstallBox" @click="goupdata" ref="appInstallBox">-->
<!--          <div class="text">前往淘百街APP，还有更多惊喜在等着您。</div>-->
<!--          <div class="btn">去下载</div>-->
<!--        </div>-->
        <van-tabbar replace  v-model="chooseTab" active-color="#FC223B" inactive-color="#1B1B1B" class="tabbar" router >
          <van-tabbar-item v-for="(item,index) in tabbarList" :key="index" :badge="item.badge>0?item.badge:''" :to="item.src">
            <span class="text" v-if="index!=2">{{ item.text }}</span>
            <div  v-if="index==2" class="centerOut">
              <div class="center">
                <div class="centeriNN" >
                                <img :src="$store.state.imageHost+'/img/vip_tabbar.png'" alt="" class="center_img">
                </div>
              </div>
              <span class="text">{{ item.text }}</span>
            </div>
<!--            <div  v-if="index==2&&chooseTab != 0" class="centerOut2">-->
<!--              <div class="center">-->
<!--                <div class="centeriNN" >-->
<!--                  <img :src="$store.state.imageHost+'/img/vip_tabbar2.png'" alt="" class="center_img">-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
            <template #icon="props">
              <span class="iconfont" :class=" props.active?item.chooseIcon:item.icon " v-if="index!=2"></span>
            </template>
          </van-tabbar-item>
        </van-tabbar>
      </div>

    <!-- <div class="tabbar">
      <div class="innerBox">
        <div class="home" v-for="(item, index) in tabbarList" :key="index" :class="index == chooseTab ? 'chooseTab' : ''"
          @click="switchTab(index)">
          <span class="iconfont" :class="item.icon"></span>
          <span class="text">{{ item.text }}</span>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
// import home from '../../common/home_tabbar_page/tabbar_home.vue'
// import car from '../../common/home_tabbar_page/tabbar_car.vue'
// import message from '../../common/home_tabbar_page/tabbar_message.vue'
// import mine from '../../common/home_tabbar_page/tabbar_mine.vue'

import Vue from 'vue';
import { Tabbar, TabbarItem } from 'vant';
Vue.use(Tabbar);
Vue.use(TabbarItem);
export default {
  name: 'home_tabbar',
  components: {
    // home,
    // car,
    // message,
    // mine,
  },
  props: ['active'],
  data() {
    return {
      tabbarList: [
        { icon: 'icon-Frame-11', text: '首页', chooseIcon: 'icon-Frame1', src: '/home' ,badge:0,},
        { icon: 'icon-Frame-12', text: '购物车', chooseIcon: 'icon-Frame2', src: '/car',badge:0, },
        { icon: 'icon-Frame-15', text: '会员中心', chooseIcon: 'icon-Frame21', src: '/share',badge:0, },
        { icon: 'icon-Frame5', text: '消息', chooseIcon: 'icon-Frame4', src: '/message' ,badge:0,},
        { icon: 'icon-Frame7', text: '我的', chooseIcon: 'icon-Frame6', src: '/mine',badge:0, },
      ],
      chooseTab:this.active,
    };
  },
  watch: {},
  computed: {},
  methods: {
    switchTab(index) {
      if (this.chooseTab == index) return;
      if (this.tabbarList[index].src) {
        this.chooseTab = index
      }
    },
    goupdata(){
      console.log('下载');
    },
  },
  created() { },
  mounted() { }
};
</script>
<style lang="scss" scoped>
.van-tabbar-item--active .text{
  color: rgb(252, 34, 59)!important;
}
.van-tabbar{
  height: 62px;
}
@media (min-width: 768px) {

}
.van-tabbar--fixed{
  position: relative;
}
.tabbar {
  // position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  z-index: 10;
  .innerBox {
    display: flex;

    .home {
      width: 25%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .text {
        font-size: 1em;
      }




    }

    .chooseTab {
      .iconfont {
        color: #FC223B;
        color: #6d22f9;
      }

      .text {
        color: #FC223B;
      }
    }
  }
  .text{
    font-size: 10px;
    color: #1b1b1b;
  }
  .iconfont{
    font-size: 1.2rem;
  }
}
.outBox{
  box-shadow: 0px -0.5px 2px #99999926;
  max-width: 750px;
  position: fixed;
  bottom: 0; 
  width: 100%;
  background-color: #fff;
  z-index: 10;
  .appInstallBox{
    position: absolute;
    top: 0%;
    left: 0;
    width: 100%;
    transform: translate(-0%,-100%);
    display: flex;
    background: rgba(0,0,0,0.7);
    justify-content: space-between;
    padding: 10px 0;
    align-items: center;
    .text{
      color: #FFFFFF;
      line-height: 20px;
      font-size: 13px;
      font-weight: 400;
      font-style: normal;
      margin-left: 16px;
      text-align: left;

    }
    .btn{
      line-height: 20px;
      font-size: 13px;
      color: #FFFFFF;
      border-radius: 35px 35px 35px 35px;
      background: #FC223B;
      margin-right: 16px;
      padding: 2px 11px;
      white-space:nowrap;
    }
  }
}


.centerOut{
  margin-bottom: 36px;
  .center{
    padding: 10px;
    border-radius: 50%;
    position: relative;
    z-index: 2;
    background: #FFFFFF;
    box-shadow: 0px -1px 2px 0px rgba(255,127,142,0.1);
    .centeriNN{
      border-radius: 50%;
      width: 38px;
      height: 38px;
      background-color: #FC223B;
      line-height: 54px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      .center_img{
        width: 200%;
        object-fit: cover;
      }
    }


  }
}
.centerOut2{
  .center{
    padding: 10px;
    border-radius: 50%;
    position: relative;
    z-index: 2;
    background: #FFFFFF;
    box-shadow: 0px -1px 2px 0px rgba(255,127,142,0.1);
    .centeriNN{
      border-radius: 50%;
      width: 38px;
      height: 38px;
      line-height: 54px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      .center_img{
        width: 200%;
        object-fit: cover;
      }
    }


  }

}
</style>