<script>
import NavigationBar from "@/components/navigation-bar/navigation-bar.vue";
import {
  add_work,
  change_list,
  change_order_receive, deliverInfo, deliverOrder, deliveryKeep,
  get_authority_count,
  get_authority_noReceiving_list,
  get_authority_OrderList,
  get_authority_Receiving_list,
  get_kanchayuan_count,
  get_weixiu_count,
  getNowTime,
  maintenance_order_ship_ok_list,
  maintenance_Receiving, order_no_count,
  order_ship_maintenance_list,
  order_ship_maintenance_ok_list,
  order_user_authority_agree,
  Receiving_authority_agree,
  reship_list,
  reship_order_receive,
  user_kt_num,
  user_opinions,
  wokr_type_cat,
  worker_user_info
} from "@/utils/api";
import {Toast} from "vant";
import {_throttle} from "@/utils/tool";
import Loding from "@/components/common/loding/loding.vue";
// import inventory from "@/components/common/employee/inventory/inventory.vue";

export default {
  name: "home",
  components: {Loding, NavigationBar},
  computed: {},
  data() {
    return {
      jianyiShow: false,
      ades: '三里屯SOHO服务站',
      shenfen: "",
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear(),
      workTab: [

      ],
      zhuangtai: ['待接单', '已接单', '维修中', '已提验', '未通过', '已通过', '待评价', '已评价'],
      shouhouTabList: [

      ],
      shouhouIndex: 0,
      workTab1: [

      ],
      workTab1All:[],
      workTabIndex: 0,
      workList: [],
      activeFlag: true,
      clock: '上班打卡',
      suggessValue: '',
      pandian: {
        bjyj: 0,
        kctzs: 0,
        yhjj: 0,
        kckt: 0,
        ktpd: 0,
        ssht: 0,
        yjttNum: 0,
      },
      status: 2,
      kanchastatus: 0,
      list: [],
      oldScrollTop: 0,
      scrollFlag: true,
      loading: false,
      dakatime: '',
      active: 0,
      serverTime: '',
      timeshow: false,
      workHead: {
        res: {}
      },

      deliverInfo:{},
    }
  },
  watch: {
    async workTabIndex() {

    },
  },
  methods: {
    jiedanBtn(item){
      if(item.status == 0){
        console.log(item.id)
        let data =  {
          sh_delivery_id: this.deliverInfo.phone,
          sh_delivery_name: this.deliverInfo.nickname,
          sh_delivery_uid: this.deliverInfo.uid,
          type: 2,
        }
        deliveryKeep(item.id,data).then(res=>{
          if(res.data.status == 200){
            Toast('接单成功')
            item.status = 1
          }else{
            Toast(res.data.msg)
          }
        })

      }
    },
    delivery(item){
      if(item.status == 1){
        console.log(item)
      }
    },
    jiluBtn() {
      console.log('打卡记录')
      this.$router.push({
        name: 'workRecords'
      })
    },
    jixiao() {
      this.$router.push({
        name: 'performance'
      })
    },
    goKtpd() {
      this.$router.push({
        name: 'inventory'
      })
    },
    futrue() {
      let date = new Date(this.serverTime * 1000).getMonth() + 1
      let years = new Date(this.serverTime * 1000).getFullYear()
      if (this.month >= date && years <= this.year) {
        return
      }
      if (this.month >= 12) {
        this.month = 1
        this.year += 1
      } else {
        this.month += 1
      }
      this.getWorkerDate()
    },
    last() {
      if (this.month <= 0) {
        return
      }
      if (this.month <= 1) {
        this.month = 12
        this.year += 1
      } else {
        this.month -= 1
      }
      this.getWorkerDate()
    },
    cforEach(e) {
      e.forEach(item => {
        if (item.ship_status == 1) {
          item.zhuangtai = 0
        }
        if (item.ship_status == 2) {
          item.zhuangtai = 1
          if (item.maintenance_user_status == 2) {
            item.zhuangtai = 2
          }
        }
        if (item.ship_status == 3) {
          item.zhuangtai = 3
          if (item.confirm == 1) {//1 未确认 2已通过  3有问题

          }
          if (item.confirm == 2) {
            item.zhuangtai = 5
            if (item.is_comment == 1) {
              item.zhuangtai = 6
            }
            if (item.is_comment == 2) {
              item.zhuangtai = 7
            }
          }
          if (item.confirm == 3) {
            item.zhuangtai = 4
          }
        }
      })
      return e
    },
    chooseTab(index) {
      if (index == this.workTabIndex) return;
      this.workTabIndex = index
      this.scrollFlag = true
      this.list = []
      console.log(this.$store.state.user)

      if (this.$store.state.user.user_authority == 5) {
        this.shouhouIndex = 0
        this.shouhouTabList[this.shouhouIndex].page = 1
        if (index == 0) {
          this.status = 2
        }
        if (index == 1) {
          this.status = 3
        }
        if (index == 2) {
          this.status = 4
        }
        // this.songshuigong()
      }
      if (this.$store.state.user.user_authority == 4) {
        this.workTab1[this.workTabIndex].page = 1
        if (index == 0) {
          this.kanchastatus = 1
        }
        if (index == 1) {
          this.kanchastatus = 1
        }
        if (index == 2) {
          this.kanchastatus = 2
        }
        if (index == 3) {
          this.kanchastatus = 3
        }

        // this.kanchayuan()
      }
      if(this.$store.state.user.user_authority == 3){
        this.workTab[this.workTabIndex].page = 1
        // this.weixiugong()
      }
    },
    chooseShouhou(index) {
      if (index == this.shouhouIndex) return;
      this.shouhouIndex = index
      this.scrollFlag = true
      this.list = []
      this.shouhouTabList[this.shouhouIndex].page = 1
      // this.songshuigong()
    },
    //获取身份
    get_work_type() {
      wokr_type_cat(this.$store.state.token, 1).then(res => {
        if (res.data.status) {
          const list = res.data.data
          if (this.$store.state.user.user_authority == 5) {
            this.shenfen = '送水工'
          }
          if (this.$store.state.user.user_authority == 4) {
            this.shenfen = '勘察员'
          }
          if (this.$store.state.user.user_authority == 3) {
            this.shenfen = list.find(e => {
              return e.id == this.$store.state.user.user_skill_name
            }).name
          }
        } else {
          Toast(res.data.msg)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    getKtNums() {
      user_kt_num(this.$store.state.token).then(res => {
        if (res.data.status == true) {
          console.log(res.data.data)
          this.pandian.bjyj = res.data.data.tbj_yj.value
          this.pandian.kctzs = res.data.data.tbj_tzs.value
          this.pandian.yhjj = res.data.data.kt_pledge.value
          this.pandian.kckt = res.data.data.tbj_kt.value
          this.pandian.ktpd = res.data.data.num
          this.pandian.yjtt = res.data.data.order_kt_list
          this.pandian.ssht = res.data.data.order_ss
          this.pandian.yjttNum = res.data.data.order_kt
        } else {
          Toast(res.data.msg)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    //topFun
    getWorkerDate() {
      let month = this.month
      month = month < 10 ? ('0' + month) : month;
      worker_user_info(this.$store.state.token, this.year + '-' + month).then(res => {
        if (res.data.status === true) {
          let workHead = res.data.data
          workHead.res.performance = workHead.res.performance ? workHead.res.performance.toFixed(2) : '0.00'
          workHead.res.kanchayuan = (workHead.res.performance * (this.$store.state.user.user_authority_proportion / 100)).toFixed(2)
          workHead.res.weixiugong = (workHead.res.performance * (this.$store.state.user.user_authority_proportion / 100)).toFixed(2)
          workHead.res.kanchasettlement = (workHead.res.kanchayuan - workHead.res.substract).toFixed(2)
          workHead.res.weixiusettlement = (workHead.res.weixiugong - workHead.res.substract).toFixed(2)
          workHead.res.settlementSongshui = (workHead.res.ticheng - workHead.res.substract).toFixed(2)
          workHead.res.settlementSongshui = workHead.res.settlementSongshui < 0 ? 0 : workHead.res.settlementSongshui
          workHead.res.weixiusettlement = workHead.res.weixiusettlement < 0 ? 0 : workHead.res.weixiusettlement
          workHead.res.kanchasettlement = workHead.res.kanchasettlement < 0 ? 0 : workHead.res.kanchasettlement
          workHead.res.performanceSongshui = Number(workHead.res.performance).toFixed(0)
          this.workHead = workHead

        } else {
          Toast(res.data.msg)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    tabrNums() {
      if (this.$store.state.user.user_authority == 5) {
        get_authority_count(this.$store.state.token).then(res => {
          if (res.data.status === true) {
            this.workTab[0].nums = res.data.data.dai
            this.workTab[1].nums = res.data.data.yi
          } else {
            Toast(res.data.msg)
          }
        }).catch(err => {
          console.log(err)
        })
      }
      if (this.$store.state.user.user_authority == 4) {
        get_kanchayuan_count(this.$store.state.token).then(res => {
          if (res.data.status === true) {
            let data = res.data.data
            this.workTab1[0].nums = data.no_jiedai
            this.workTab1[1].nums = data.jiedai
            this.workTab1[2].nums = data.yi_pai
          } else {
            Toast(res.data.msg)
          }
        }).catch(err => {
          console.log(err)
        })
      }
      if (this.$store.state.user.user_authority == 3) {
        get_weixiu_count(this.$store.state.token).then(res => {
          console.log(res)
          if (res.data.status === true) {
            this.workTab[0].nums = res.data.data.no_jie
            this.workTab[1].nums = res.data.data.ok_jie
          } else {
            Toast(res.data.msg)
          }
        }).catch(err => {
          console.log(err)
        })
      }
    },

    songshuigong() {
      if (this.shouhouIndex == 0) {
        get_authority_OrderList(this.$store.state.token, this.status, this.shouhouTabList[this.shouhouIndex].page, this.shouhouTabList[this.shouhouIndex].limit).then(res => {
          if (res.data.status != true) {
            this.scrollFlag = false
            return;
          }
          if (this.list.length >= res.data.data.count) {
            this.loading = false
            this.scrollFlag = false
            return
          }
          let list = res.data.data.list
          list.forEach(item => {
            item.actualNums = ''
            item.allnums = 0
            item.items.forEach(item2 => {
              item.allnums += item2.nums
            })
            if (item.order_type != 5) {
              if (item.service_time.split(',')[1]) {
                item.service_time1 = item.service_time.split(',')[0] + '~' + item.service_time.split(',')[1].split(' ')[1]
              } else {
                let date = new Date(item.yvyue_time * 1000)
                item.service_time1 = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + ' ' + item.service_time
              }
            } else {
              item.service_time1 = item.service_time
            }
          })
          this.list = this.list.concat(list)
          this.loading = false
        }).catch(err => {
          this.loading = false
          console.log(err)
        })
      }
      if (this.shouhouIndex == 1) {
        reship_list(this.$store.state.token, this.status, this.shouhouTabList[this.shouhouIndex].page, this.shouhouTabList[this.shouhouIndex].limit).then(res => {
          if (res.data.status == true) {
            console.log(res.data.data)
            if (this.list.length >= res.data.data.count) {
              this.loading = false
              this.scrollFlag = false
              return
            }
            this.list = this.list.concat(res.data.data.data)
            console.log(this.list)
          } else {
            this.loading = false
            Toast(res.data.msg)
          }
        }).catch(err => {
          this.loading = false
          console.log(err)
        })
      }
      if (this.shouhouIndex == 2) {
        change_list(this.$store.state.token, this.status, this.shouhouTabList[this.shouhouIndex].page, this.shouhouTabList[this.shouhouIndex].limit).then(res => {
          if (res.data.status == true) {
            if (res.data.status != true) {
              this.scrollFlag = false
              return;
            }
            if (this.list.length >= res.data.data.count) {
              this.loading = false
              this.scrollFlag = false
              return
            }
            this.list = this.list.concat(res.data.data.data)
          } else {
            this.loading = false
            Toast(res.data.msg)
          }
        }).catch(err => {
          this.loading = false
          console.log(err)
        })
      }

      this.tabrNums()
    },
    kanchayuan() {
      if (this.workTabIndex == 0) {
        get_authority_noReceiving_list(this.$store.state.token, this.workTab1[this.workTabIndex].page, this.workTab1[this.workTabIndex].limit).then(res => {
          if (res.data.status != true) {
            this.scrollFlag = false
            return;
          }
          if (res.data.status == true) {
            if (this.list.length >= res.data.data.count) {
              this.loading = false
              this.scrollFlag = false
              return
            }
            this.list = this.list.concat(res.data.data.data)
            // let list = res.data.data.list
          } else {
            Toast(res.data.msg)
          }
          console.log(res.data.status, res.data.status == true)
        }).catch(err => {
          console.log(err)
        })
      } else {
        get_authority_Receiving_list(this.$store.state.token, this.workTab1[this.workTabIndex].page, this.workTab1[this.workTabIndex].limit, this.kanchastatus).then(res => {
          if (res.data.status == true) {
            if (this.list.length >= res.data.data.count) {
              this.loading = false
              this.scrollFlag = false
              return
            }
            this.list = this.list.concat(res.data.data.list)

            this.list = this.cforEach(this.list)
          } else {
            Toast(res.data.msg)
          }
        }).catch(err => {
          console.log(err)
        })
      }
    },

    weixiugong(){

      if(this.workTabIndex == 0){
        order_ship_maintenance_list(this.$store.state.token, this.workTab[this.workTabIndex].page, this.workTab[this.workTabIndex].limit, this.kanchastatus).then(res=>{
          if (res.data.status == true) {
            console.log(res.data.count)
            if (this.list.length >= res.data.count) {
              this.loading = false
              this.scrollFlag = false
              return
            }
            this.list = this.list.concat(res.data.data)
            this.loading = false
            console.log(this.list)
          } else {
            Toast(res.data.msg)
          }
        }).catch(err=>{
          console.log(err)
        })
      }
      if(this.workTabIndex == 1){
        order_ship_maintenance_ok_list(this.$store.state.token, this.workTab[this.workTabIndex].page, this.workTab[this.workTabIndex].limit, this.kanchastatus).then(res=>{
          if (res.data.status == true) {
            if (this.list.length >= res.data.count) {
              this.loading = false
              this.scrollFlag = false
              return
            }
            console.log(res)
            this.list = this.list.concat(res.data.data)
            console.log(this.list)
            this.loading = false
          } else {
            Toast(res.data.msg)
          }
        }).catch(err=>{
          console.log(err)
        })
      }
      if(this.workTabIndex == 2){
        maintenance_order_ship_ok_list(this.$store.state.token, this.workTab[this.workTabIndex].page, this.workTab[this.workTabIndex].limit, this.kanchastatus).then(res=>{
          if (res.data.status == true) {
            if (this.list.length >= res.data.count) {
              this.loading = false
              this.scrollFlag = false
              return
            }
            this.list = this.list.concat(res.data.data)
            console.log(this.list)
            this.loading = false
          } else {
            Toast(res.data.msg)
          }
        }).catch(err=>{
          console.log(err)
        })
      }
    },
    gohome() {
      console.log(123)
      this.$router.push({
        name: '首页'
      })
    },
    kefu() {

    },
    suggest() {
      this.jianyiShow = true
    },
    submitSuggess() {
      user_opinions(this.$store.state.token, this.suggessValue).then(res => {
        console.log(res)
        if (res.data.status == true) {
          Toast('提交成功')
          this.suggessValue = ''
          this.jianyiShow = false
        } else {
          Toast(res.data.msg)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    containerScroll(event) {
      let scrollTop1 = event.target.scrollTop;
      // 滚动条滚动的距离
      let scrollStep = scrollTop1 - this.oldScrollTop;
      // 更新——滚动前，滚动条距文档顶部的距离
      this.oldScrollTop = scrollTop1;
      if (scrollStep < 0) {
        // console.log("滚动条向上滚动了！");
      } else {
        const {scrollHeight, clientHeight, scrollTop} = event.target;
        if (
            (scrollHeight - (scrollTop + clientHeight)) / clientHeight <=
            0.05
        ) {
          if (this.scrollFlag) {
            this.loading = true
          }
          this.loading = false
          // this.getQingqiu();
        }
      }
    },
    getQingqiu: _throttle(function () {
      if (this.$store.state.user.user_authority == 5) {
        if (!this.scrollFlag) {
          this.loading = false
          return
        }
        this.shouhouTabList[this.shouhouIndex].page += 1
        this.songshuigong()
      }


      if (this.$store.state.user.user_authority == 4) {
        if (!this.scrollFlag) {
          this.loading = false
          return
        }
        this.workTab1[this.workTabIndex].page += 1
        this.kanchayuan()
      }


      if(this.$store.state.user.user_authority == 3){
        if (!this.scrollFlag) {
          this.loading = false
          return
        }
        this.workTab[this.workTabIndex].page += 1
        this.weixiugong()

      }
    }, 2000),
    songshuigongFun() {
      this.getKtNums();
      this.tabrNums()
      this.songshuigong()
    },
    kanchayuanFun() {
      this.tabrNums()
      this.kanchayuan()
    },
    weixiuFun(){
      this.tabrNums()
      this.weixiugong()
    },
    jiedan(item, index) {
      if (this.shouhouIndex == 0) {
        order_user_authority_agree(this.$store.state.token, item.order_id).then(res => {
          if (res.data.status == true) {
            Toast('接单成功')
            Toast(res.data.msg)
            let msg = {type: 'u_order_bottled'}
            this.$store.state.websocket.send(JSON.stringify(msg))
            this.list.splice(index, 1)
          } else {
            Toast(res.data.msg)
          }
        }).catch(err => {
          console.log(err)
        })
      }
      if (this.shouhouIndex == 1) {//退货接单
        reship_order_receive(this.$store.state.token, item.reship_id).then(res => {
          if (res.data.status == true) {
            Toast('接单成功')
            this.list.splice(index, 1)
          } else {
            Toast(res.data.msg)
          }
        }).catch(err => {
          console.log(err)
        })
      }
      if (this.shouhouIndex == 2) {//换货接单
        change_order_receive(this.$store.state.token, item.change_id).then(res => {
          if (res.data.status == true) {
            Toast('接单成功')
            this.list.splice(index, 1)
          } else {
            Toast(res.data.msg)
          }
        }).catch(err => {
          console.log(err)
        })
      }
    },
    kanchajiedan(item, index) {
      Receiving_authority_agree(this.$store.state.token, item.order_id).then(res => {
        if (res.data.status == true) {
          Toast('接单成功')
          let msg = {type: 'u_order_bottled'}
          let msg1 = {type: 'u_order_4', user_id: item.user_id}
          console.log(msg)
          console.log(msg1)
          this.tabrNums()

          this.list.splice(index, 1)
        } else {
          Toast(res.data.msg)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    weixiujiedan(item,index){
      maintenance_Receiving(this.$store.state.token, item.order_id).then(res => {
        if (res.data.status == true) {
          Toast('接单成功')
          let msg={type:'u_order_4',user_id:item.order_id}
          let msg1={type:'u_order_weixiu'}
          let msg2={type:'u_order_bottled'}
          this.$store.state.websocket.send(JSON.stringify(msg))
          this.$store.state.websocket.send(JSON.stringify(msg1))
          this.$store.state.websocket.send(JSON.stringify(msg2))
          this.tabrNums()
          this.list.splice(index, 1)
        } else {
          Toast(res.data.msg)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    daka() {
      if (!this.activeFlag) {
        return
      }
      console.log(new Date(this.serverTime * 1000).getHours())
      let hour = new Date(this.serverTime * 1000).getHours() >= 10 ? new Date(this.serverTime * 1000).getHours() : '0' + new Date(this.serverTime * 1000).getHours()
      let Minutes = new Date(this.serverTime * 1000).getMinutes() >= 10 ? new Date(this.serverTime * 1000).getMinutes() : '0' + new Date(this.serverTime * 1000).getMinutes()
      this.dakatime = hour + ':' + Minutes
      if (this.active == 0) {
        this.add_work(1)
      } else {
        this.add_work(2)
      }
    },
    add_work(e) {
      add_work(this.$store.state.token, e).then(res => {
        if (res.data.status == true) {
          console.log(res.data.data)
          if (e == 1) {
            this.clock = '下班打卡'
            this.active = 1
          } else {
            this.clock = '上班打卡'
            this.activeFlag = false
          }
          this.timeshow = true
        } else {
          Toast(res.data.msg)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    checkDetails(item) {
      console.log(item)
      let order_id = item.order_id
      if (this.shouhouIndex == 1) {
        order_id = item.reship_id
      }
      if (this.shouhouIndex == 2) {
        order_id = item.change_id
      }

      this.$router.push({
        path: 'employee_order_details',
        query: {action: this.workTabIndex, order_type: this.shouhouIndex, order_id: order_id,}
      })
    },
    checkKanchaDetails(item) {
      let obj = item
      let fourStepFlag = 2
      console.log(obj.items);
      if (obj.status == 2) { //1正常 2完成 3取消
        if (this.$store.state.user.user_authority != 10) {
          fourStepFlag = 2
        }
        if (this.$store.state.user.user_authority == 4) {
          let obj1 = {
            item: JSON.stringify(obj),
            index: fourStepFlag,
            identity: 2,
          }
          this.$router.push({
            path: 'service_page',
            query: obj1
          })

        } else {
          let obj1 = {
            item: JSON.stringify(obj),
            index: fourStepFlag,
            identity: 2,
          }
          this.$router.push({
            path: 'service_page',
            query: obj1
          })
        }
        return
      }
      if (obj.items.status != 2) {
        fourStepFlag = 1
      }
      if (obj.items.status == 2) {
        fourStepFlag = 1
      }
      if (obj.items.maintenance_status == 2) {
        fourStepFlag = 2
        console.log('maintenance_status2')
      }
      if (obj.items.maintenance_status == 3) {
        fourStepFlag = 2
        console.log('maintenance_status')
      }
      if (obj.confirm == 2) {
        if (this.$store.state.user.user_authority != 10) {
          fourStepFlag = 2
        }
      }
      let obj1 = {
        item: JSON.stringify(obj),
        index: fourStepFlag,
        identity: 2,
      }
      if (this.$store.state.user.user_authority == 4) {
        console.log(obj);
        this.$router.push({
          path: 'service_page',
          query: obj1
        })
      } else {
        this.$router.push({
          path: 'service_detail',
          query: obj1
        })
      }

    },


    getdeliverOrder(){
      deliverOrder(this.shouhouTabList[this.shouhouIndex].id).then(res=>{
        if(res.data.status == 200){
          let list = res.data.data.list
          this.list = list
          console.log(list)
        }
      })
    },

  },
  async mounted() {
    getNowTime().then(res => {
      this.serverTime = res.data.data
    })
    this.activeFlag = false
    this.deliverInfo = (await deliverInfo()).data.data
    let order_no_count2 = await order_no_count()
    this.workTab1All = order_no_count2.data.data[0].data
    this.workTab1All = this.workTab1All.map(item=>{return {name: item.name, nums: 0,page: 1, limit: 10,id:item.id,data:item.data}})
    this.workTab = this.workTab1All
    this.shouhouTabList = this.workTab[0].data
    this.getdeliverOrder()

    // user_daka(this.$store.state.token).then(res => {
    //   if (res.data.status) {
    //
    //     if (res.data.data == null) {
    //       this.activeFlag = true
    //       this.clock = '上班打卡'
    //       this.active = 0
    //       console.log(res.data.data)
    //     } else {
    //       if (res.data.data.go_work_time) {
    //         this.activeFlag = true
    //         this.clock = '下班打卡'
    //         this.active = 1
    //       }
    //       if (res.data.data.come_work_time) {
    //         this.activeFlag = false
    //         this.clock = '上班打卡'
    //         this.active = 1
    //       }
    //     }
    //   } else {
    //     Toast(res.data.msg)
    //   }
    // }).catch(err => {
    //   console.log(err)
    // })
    // this.get_work_type();//获取身份
    // this.getWorkerDate()//topCard 月总单
    if (this.$store.state.user.user_authority == 5) {
      // this.songshuigongFun()
      this.getdeliverOrder()
    }
    if (this.$store.state.user.user_authority == 4) {
      // this.kanchayuanFun()
    }
    if (this.$store.state.user.user_authority == 3) {
      // this.weixiuFun()
    }
  }
}
</script>

<template>
  <div class="container" @scroll="containerScroll">
    <div class="area">
      <navigation-bar :backBtn="false" class="navigationBar">
        <template #search>
          <div class="workNav">
            <div class="left">
              <img src="/image/workLogo.png" alt="" class="logo">
            </div>
            <div class="right">
              <img src="/image/logoText.png" alt="" class="logoText">
              <div class="bottom">{{ ades }}</div>
            </div>
          </div>
        </template>
      </navigation-bar>
      <div class="background"></div>
      <div class="inner">
        <div class="topCard">
          <div class="head">
            <div class="left">
              <div class="img" v-if="$store.state.user">
                <img :src="$store.state.user.avatar" class="image" alt="">
              </div>
              <div class="userInfo">
                <div class="userNameOut">
                  <div class="userName" v-if="deliverInfo.id">{{ deliverInfo.nickname }}</div>
                  <div class="telephone" v-if="deliverInfo.id">{{ deliverInfo.phone }}</div>
                </div>
                <div class="left" v-if="deliverInfo.id">
                  <div class="IDNum">{{ shenfen }}</div>
                  ：BJS{{ deliverInfo.uid }}
                </div>
              </div>
            </div>
            <div class="right">
              <div class="jiluBtn Btn" @click="jiluBtn">打卡记录</div>
            </div>
          </div>
          <div class="bodyC">
            <div class="bottomBodyItem yuezongdan">
              <div class="title">月总单</div>
              <div class="num">{{ workHead.res.single_quantity ? workHead.res.single_quantity : 0 }}</div>
            </div>
            <div class="bottomBodyItem yuejixiao">
              <div class="title">月绩效</div>
              <div class="num">
                {{
                  $store.state.user.user_authority == 5 ? workHead.res.performanceSongshui ? workHead.res.performanceSongshui : 0 : workHead.res.performance ? workHead.res.performance : 0
                }}{{ $store.state.user.user_authority == 5 ? '件' : '元' }}
              </div>
            </div>
            <div class="bottomBodyItem ticheng">
              <div class="title">绩效提成</div>
              <div class="num">{{
                  $store.state.user.user_authority == 4 ? workHead.res.kanchayuan : $store.state.user.user_authority == 3 ? workHead.res.weixiugong : workHead.res.ticheng ? workHead.res.ticheng : 0
                }}

              </div>
            </div>
            <div class="bottomBodyItem haoping">
              <div class="title">月好评</div>
              <div class="num">{{ workHead.res.esteem ? workHead.res.esteem : 0 }}</div>
            </div>
            <div class="bottomBodyItem chaping">
              <div class="title">月差评</div>
              <div class="num">{{ workHead.res.Bad ? workHead.res.Bad : 0 }}</div>
            </div>
            <div class="bottomBodyItem koufen">
              <div class="title">绩效扣分</div>
              <div class="num"> {{ workHead.res.substract ? workHead.res.substract : 0 }}</div>
            </div>
            <div class="bottomBodyItem jiesuan">
              <div class="title">绩效结算</div>
              <div class="num">
                {{
                  $store.state.user.user_authority == 4 ? workHead.res.kanchasettlement : $store.state.user.user_authority == 3 ? workHead.res.weixiusettlement : workHead.res.settlementSongshui
                }}元
              </div>
            </div>
          </div>
          <div class="topBottomLine">
            <div class="left">
              <div class="center">
                <div class="last box" @click="last">
                  <van-icon name="arrow-left" class="icon"/>
                </div>
                <div class="now box">{{ month }}月</div>
                <div class="futrue box" @click="futrue">
                  <van-icon name="arrow" class="icon"/>
                </div>
              </div>
            </div>
            <div class="right">
              <div class="btn" @click="jixiao">绩效查询</div>
              <div class="btn" :class="!activeFlag?'dontDaka':''" @click="daka">{{ clock }}</div>
            </div>
          </div>
        </div>
        <div style="background: #FFFFFF">
          <div class="pandian" v-if="$store.state.user&&$store.state.user.user_authority==5">
            <div class="title">
              <div class="text">空桶盘点</div>
            </div>
            <div class="body">
              <div class="leftData">
                <div class="every">
                  <div class="intitle">空桶总量</div>
                  <div class="nums">{{ pandian.bjyj }}桶</div>
                </div>
                <div class="every">
                  <div class="intitle">售出空桶</div>
                  <div class="nums">{{ pandian.yhjj }}桶</div>
                </div>
                <div class="every">
                  <div class="intitle">库存桶装水</div>
                  <div class="nums">{{ pandian.kctzs }}桶</div>
                </div>
                <div class="every">
                  <div class="intitle" style="color: #286CFB">收回空桶</div>
                  <div class="nums" style="color: #286CFB">{{ pandian.kckt }}桶</div>
                </div>
                <div class="every">
                  <div class="intitle" style="color: #f96422!important">空桶盘点</div>
                  <div class="nums" style="color: #f96422!important">{{ pandian.ktpd }}桶</div>
                </div>
              </div>
              <div class="rightData" @click="goKtpd">
                <div class="text">空桶盘点</div>
                <span class="iconfont icon-jiantou1"></span>
              </div>
            </div>
          </div>
          <div class="shouhouBox" v-if="$store.state.user.user_authority==5">
<!--            <div v-for="(item,index) in shouhouTabList" :key="index" class="shouhouTabEvery"-->
<!--                 :class="shouhouIndex == index?'chooseShouhou':''" @click="chooseShouhou(index)">-->
              <div v-for="(item,index) in workTab" :key="index" class="shouhouTabEvery"
                   :class="workTabIndex==index?'chooseWorkTab':''" @click="chooseTab(index)">
              {{ item.name }}
              <span class="line" v-if="workTabIndex==index"></span>
            </div>
          </div>
          <div class="workTab" :class="$store.state.user?$store.state.user.user_authority==4?'workTab1':'':''">
            <div class="workTabEvery" :class="shouhouIndex==index?'chooseWorkTab':''"
                 v-for="(item,index) in shouhouTabList" :key="index"
                 @click="chooseShouhou(index)">
              {{ item.name }}
              <span class="nums" v-if="item.count>0">{{ item.count >= 100 ? '99+' : item.count }}</span>
            </div>
          </div>
          <div class="list">
            <div class="outItem" v-for="(item,index) in list" :key="index">

              <van-divider
                  :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }"
              >
                {{ item.nuit_name }}
              </van-divider>
              <div class="item newItem" v-for="(item2,index2) in item.date" :key="index2">
                <div class="titleBox">
                  <div class="address">{{item2.user_location}}</div>
                  <div class="order_id">{{item2.order_id}}</div>
                </div>
                <div class="info">
                  <div class="line">
                    <div class="label">客户姓名：</div>
                    <div class="value">{{ item2.real_name }}</div>
                  </div>
                  <div class="line">
                    <div class="label">送达时间：</div>
                    <div class="value">姓名22</div>
                  </div>
                  <div class="line">
                    <div class="label">用户备注：</div>
                    <div class="value">{{ item2.mark }}</div>
                  </div>
                </div>
                <div class="buttonBox">
<!--                  0待接单 1已接单-->
                  <div class="btn" v-if="item2.status == 0" @click="jiedanBtn(item2)">待接单</div>
                  <div class="btn daijiedan" v-if="item2.status == 1">已接单</div>
                  <div class="btn" :class="item2.status == 0?'daijiedan':''" @click="delivery(item2)">确认送达</div>
                  <div class="btn"><a :href="`tel:${item2.user_phone}`"><span class="iconfont icon-dianhua-F"></span></a></div>
                </div>
              </div>


              <!--              送水工-->
<!--              <div class="item Songshui_weijiedan" v-if="$store.state.user.user_authority == 5">-->
<!--                <div class="oneGoods">-->
<!--                  <div class="title">-->
<!--                    订单编号：{{ item.order_id }}-->
<!--                  </div>-->
<!--                  <div class="goods" v-for="(item2,index2) in item.items" :key="index2">-->
<!--                    <div class="leftImg">-->
<!--                      <img :src="$store.state.imageHost+item2.image_url" alt="" class="image">-->
<!--                    </div>-->
<!--                    <div class="rightInfo">-->
<!--                      <div class="nameAndNums">-->
<!--                        <div class="name">{{ item2.name == '空桶押金' ? '桶押金退回申请' : item2.name }}</div>-->
<!--                        <div class="nums" v-if="item.order_type!=11">x{{ item2.nums }}</div>-->
<!--                      </div>-->
<!--                      <div class="specification" v-if="item.order_type!=11">规格：{{ item2.specifications }}</div>-->
<!--                      <div class="unit" v-if="item.order_type!=11">单位：{{ item2.danwei }}</div>-->
<!--                      <div class="nums" v-if="item.order_type==11">-->
<!--                        申请退桶数量：{{ item.items[0].nums }}桶-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="userAddress">-->
<!--                    {{ item.order_type == 11 ? '回收地址' : '送单地址' }}：{{ item.ship_address_ds }}{{-->
<!--                      item.ship_address-->
<!--                    }}-->
<!--                  </div>-->
<!--                  <div class="time" v-if="shouhouIndex == 0">-->
<!--                    预约时间：{{ item.service_time1 }}-->
<!--                  </div>-->
<!--                  <div class="bootomBtnBox">-->
<!--                    <div class="remark weijiedanRemark" v-if="item.remark||item.reason">-->
<!--                      客户备注:{{ shouhouIndex == 0 ? item.remark : item.reason }}-->
<!--                    </div>-->
<!--                    <div class="btn jiedanBtn" @click="jiedan(item,index)">接单</div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
              <!--勘察员-->
              <div class="item kanchayuan_weijiedan kancha"
                   v-if="$store.state.user.user_authority == 4 && workTabIndex == 0">
                <div class="title surveyTitle">
                  <div class="left">
                    {{ item.title }}
                  </div>
                  <div class="right">
                    <!-- 待接单的 maintenance_type 在外层  没有在items -->
                    <span class="zhibaoBox"
                          :class="item.maintenance_type==2?'kuaixiuzhibao':item.maintenance_type==1?'zhibaowai':'zhuangxiuzhibao'">{{
                        item.maintenance_type == 1 ? '质保外' : item.maintenance_type == 2 ? '快修质保' : '装修质保'
                      }}</span>
                  </div>
                </div>
                <div class="time box">
                  勘察时间:{{ item.yvyue_time }}
                </div>
                <div class="address box">
                  维修地址:{{ item.ship_address_ds }}
                </div>
                <div class="orderNum box">
                  订单编号：: {{ item.order_id }}
                </div>
                <div class="bootomBtnBox">

                  <div class="btn jiedanBtn" @click="kanchajiedan(item,index)">接单</div>
                </div>
              </div>
              <div class="item kanchayuan_yijiedan kancha"
                   v-if="$store.state.user.user_authority == 4 && workTabIndex == 1">
                <div class="title surveyTitle">
                  <div class="left">
                    {{ item.items.title }}
                  </div>
                  <div class="right">

                    <span class="zhibaoBox"
                          :class="item.items.maintenance_type==2?'kuaixiuzhibao':item.items.maintenance_type==1?'zhibaowai':'zhuangxiuzhibao'">{{
                        item.items.maintenance_type == 1 ? '质保外' : item.items.maintenance_type == 2 ? '快修质保' : '装修质保'
                      }}</span>
                  </div>
                </div>
                <div class="time box">
                  勘察时间:{{ item.yvyue_time }}
                </div>
                <div class="address box">
                  维修地址:{{ item.ship_address_ds }}
                </div>
                <div class="orderNum box">
                  订单编号：: {{ item.order_id }}
                </div>
                <div class="bootomBtnBox">
                  <div class="btn jiedanBtn" @click="checkKanchaDetails(item,index)">查看订单</div>
                </div>
              </div>
              <div class="item kanchayuan_yijiedan kancha"
                   v-if="$store.state.user.user_authority == 4 && workTabIndex == 2">
                <div class="title surveyTitle">
                  <div class="left">
                    {{ item.items.title }}
                  </div>
                  <div class="right">
                    <!--                    {{item.items.maintenance_type == 1 ? '质保外' : item.items.maintenance_type == 2 ? '快修质保' : '装修质保'}}-->
                    <span class="zhibaoBox"
                          :class="item.items.maintenance_type==2?'kuaixiuzhibao':item.items.maintenance_type==1?'zhibaowai':'zhuangxiuzhibao'">
                      {{
                        item.items.maintenance_type == 1 ? '质保外' : item.items.maintenance_type == 2 ? '快修质保' : '装修质保'
                      }}
                    </span>
                  </div>
                </div>
                <div class="time box">
                  勘察时间:{{ item.yvyue_time }}
                </div>
                <div class="address box">
                  维修地址:{{ item.ship_address_ds }}
                </div>
                <div class="orderNum box">
                  订单编号：: {{ item.order_id }}
                </div>
                <div class="bootomBtnBox">
                  <div class="btn jiedanBtn" @click="checkKanchaDetails(item,index)">查看订单</div>
                </div>
                <div class="zhuangtai">
                  <div class="every"
                       :class="item.zhuangtai==indexs?'taojinse y':''+' '+indexs==4&&item.confirm==3?'red':''"
                       v-for="(items,indexs) in zhuangtai" :key="indexs">{{ items }}
                  </div>
                </div>
              </div>

<!--              维修工-->

              <div class="item weixiugong_weijiedan weixiugong" v-if="$store.state.user.user_authority == 3">
                <div class="title surveyTitle">
                  <div class="left">
                    {{ item.items.title }}
                  </div>
                  <div class="right">
                    <span v-if="item.confirm==3&&workTabIndex == 1" class="pase">验收未过</span>
                    <span class="zhibaoBox"
                          :class="item.items.maintenance_type==2?'kuaixiuzhibao':item.items.maintenance_type==1?'zhibaowai':'zhuangxiuzhibao'">{{
                        item.items.maintenance_type == 1 ? '质保外' : item.items.maintenance_type == 2 ? '快修质保' : '装修质保'
                      }}</span>
                  </div>
                </div>
                <div class="time box">
                  勘察时间:{{ item.yvyue_time }}
                </div>
                <div class="address box">
                  维修地址:{{ item.ship_address_ds }}
                </div>
                <div class="orderNum box">
                  订单编号：: {{ item.order_id }}
                </div>
                <div class="bootomBtnBox">

                  <div class="btn jiedanBtn" @click="weixiujiedan(item,index)" v-if="workTabIndex == 0">接单</div>
                  <div class="btn jiedanBtn" @click="checkKanchaDetails(item,index)" v-if="workTabIndex !=0">查看订单</div>
                </div>
              </div>

            </div>
            <div class="" v-if="list.length<=0">
              暂无订单
            </div>

          </div>
        </div>
        <div class="bttomBox">
          <div class="inn">
            <div class="left">
              <div class="home" @click="gohome">
                <span class="iconfont icon-shouye1"></span>
                首页
              </div>
              <a :href="'tel:'+13213074005" class="telA">
                <div class="kefu" @click="kefu">
                  <span class="iconfont icon-Frame14"></span>
                  客服
                </div>
              </a>
            </div>
            <div class="right">
              <div class="btn" @click="suggest">
                我要建议
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <loding v-if="loading"></loding>
    <van-popup v-model="jianyiShow" round closeable position="bottom" class="suggessPop">
      <div class="suggesttitle">我要建议</div>
      <!--      <van-icon name="cross" class-prefix="icon" @c></van-icon>-->
      <div class="textareaBox">
        <textarea v-model="suggessValue" name="" id="" cols="30" rows="10" class="textarea" maxlength="400"></textarea>
      </div>
      <div class="submit" @click="submitSuggess">提交</div>
    </van-popup>
    <van-popup v-model="timeshow" class="outBox dakaPop">
      <div class="">
        <div class="imageBox">
          <span class="iconfont icon-Frame15"></span>
        </div>
        <div class="time">
          {{ dakatime }}
        </div>
        <div class="tip">打卡成功</div>
        <div class="btn" @click="timeshow = false">我知道了</div>
      </div>
    </van-popup>
  </div>
</template>

<style scoped lang="scss">
.newItem{
  .titleBox{
    display: flex;
    justify-content: space-between;
    .address{
      color: #ff0000;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
    }
    .order_id{
      color: #1b1b1b;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
    }

  }
  .info{
    .line{
      display: flex;
      color: #1b1b1b;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      .label{
        min-width: 70px;
      }
    }
  }
  .buttonBox{
text-align: right;
    .btn{
      border-radius: 8px;
      background: #0085ff;
      display: inline-block;
      width: 23%;
      text-align: center;
      color: #fff;
      padding: 10px 0;
      margin-right: 10px;
      a{
        color: #fff;
      }
    }
    .daijiedan{
      border-radius: 8px;
      background: #999999;
    }
  }

}
.navigationBar {
  background: linear-gradient(0deg, #eaf3ff, #DEEBFF);
  position: relative;
  z-index: 2;
}

.container {
  min-height: 100vh;
  height: 100vh;

  .area {
    min-height: 100vh;
  }
}

.workNav {
  display: flex;
  align-items: center;
  padding: 0 16px;

  .left {
    .logo {
      width: 32px;
      object-fit: cover;
    }
  }

  .right {
    margin-left: 10px;
    text-align: left;

    .logoText {
      width: 55px;
      object-fit: cover;
    }

    .bottom {
      font-weight: 400;
      font-size: 13px;
      color: #999999;
      line-height: 15px;
      text-align: left;
    }
  }
}

.dontDaka {
  background-color: #666666 !important;
}

.background {
  background: linear-gradient(0deg, rgba(248, 251, 255, 0), #DEEBFF);
  height: 280px;
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.inner {
  position: relative;
  z-index: 2;
  padding-bottom: 100px;
  background: #fff;
  background: linear-gradient(0deg, #fff, rgba(255, 0, 0, 0));

  .topCard {
    margin: 10px 16px;
    overflow: hidden;
    background: #fff;
    box-shadow: 0 0 4px 0 rgba(102, 102, 102, 0.25);
    border: 1rpx solid transparent;
    border-radius: 10px;
    text-align: left;

    .head {
      padding: 10px 10px 0 10px;
      display: flex;
      justify-content: space-between;

      .left {
        display: flex;
        font-size: 12px;

        .IDNum {
          font-size: 12px;
          color: #666666;
        }

        .img {
          margin-right: 10px;

          .image {
            width: 44px;
            height: 44px;
            background: #e5e5e5;
            border-radius: 8px;
          }
        }
      }

      .userInfo {
        .userNameOut {
          display: flex;
          align-items: center;

          .userName {
            font-size: 16px;
            color: #1b1b1b;
            font-weight: 500;
          }

          .telephone {
            display: flex;
            align-items: center;
            margin-left: 10px;
            font-size: 13px;

            .text {
              font-size: 13px;
              color: #666666;
              margin-right: 10px;
            }
          }
        }

        .left {
          align-items: center;
        }
      }

      .right {
        color: #666666;
        font-size: 14px;
      }
    }

    .bodyC {
      padding: 0 10px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .bottomBodyItem {
        width: 20%;
        text-align: center;
        color: #666666;
        margin: 5px 0;

        .title {
          font-size: 14px;
        }

        .num {
          margin-right: 5px;
          font-size: 16px;
          white-space: nowrap;
          overflow: scroll;
          -webkit-overflow-scrolling: touch;
        }

        .num::-webkit-scrollbar {
          display: none;
        }
      }

      .yuezongdan, .yuejixiao, .haoping {
        .num {
          color: #7DC797;
        }
      }

      .ticheng, .jiesuan {
        .num {
          color: #F29D2E;
        }
      }

      .chaping, .koufen {
        .num {
          color: #C77D7D;
        }
      }
    }
  }

  .topBottomLine {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    margin-bottom: 10px;

    .left {
      .center {
        display: flex;
        align-items: center;
        background: #0085ff;
        border-radius: 5px;
        padding: 1px;

        .last, .futrue {
          background-color: #f1f6fd;
          padding: 7px;
          border-radius: 5px;
          display: flex;

          .icon {
            color: #0085ff;
          }
        }

        .now {
          color: #fff;
          font-size: 14px;
          box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.35);
          margin: 0 6px;
        }

      }
    }

    .right {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 50%;

      .btn {
        font-size: 14px;
        color: #fff;
        background: #0085ff;
        display: flex;
        align-items: center;
        border-radius: 8px;
        justify-content: center;
        padding: 8px 10px;
      }
    }
  }

  .pandian {
    margin: 24px 16px 10px 16px;
    background: linear-gradient(180deg, #E7EEFD 0%, #FFFFFF 100%);
    border-radius: 10px;
    box-shadow: 0px 0px 2px 0px rgba(102, 102, 102, 0.25);
    background: #fff;

    .title {
      width: 100%;
      height: 15px;
      color: white;
      text-align: center;
      font-size: 16px;
      position: relative;

      .text {
        background-color: #286CFB;
        color: white;
        border-radius: 8px;
        padding: 3px 10px;
        font-size: 16px;
        position: absolute;
        left: 50%;
        top: -100%;
        transform: translate(-50%, 0%);
        font-weight: 400;
      }
    }

    .body {
      display: flex;
      flex-direction: column;
      overflow: hidden;

      .leftData {
        display: flex;
        text-align: center;
        margin: 0 0 6px 0;
        flex-wrap: wrap;
        justify-content: space-around;

        .every {
          margin: 5px 0;

          .intitle, .nums {
            font-size: 14px;
            color: #1b1b1b;
          }

          .nums {
            margin-top: 5px;
          }
        }
      }

      .rightData {
        display: flex;
        justify-content: space-between;
        background-color: #286CFB;
        padding: 8px 10px;
        border-radius: 0 0 10px 10px;

        .text {
          font-size: 14px;
          color: #fff;
          text-align: center;
          padding: 2px 7px;
          border-radius: 5px;
        }

        .iconfont {
          transform: rotate(90deg);
          color: #fff;
          font-size: 25px;
        }
      }

    }
  }

  .workTab {
    margin-top: 1px;
    padding: 0 16px;
    justify-content: space-between;
    background: #fff;
    position: relative;
    text-align: left;
    .line {
      background-color: #0085ff;
      position: absolute;
      width: 20%;
      height: 20%;
      border-radius: 15px;
      transform: translate(-50%, -50%);
      left: 50%;
      bottom: -50%;
      transition: .2s;
    }
    .workTabEvery {
      display: inline-block;
      padding: 8px 20px;
      text-align: center;
      border-radius: 8px;
      position: relative;
      border: 1px solid #d9d9d9;
      margin: 10px 10px 0 0;

      .nums {
        position: absolute;
        right: 5px;
        top: 0;
        background-color: red;
        min-width: 14px;
        height: 14px;
        line-height: 14px;
        border-radius: 100px;
        transform: translate(50%, -50%);
        color: #fff;
        font-size: 11px;
        padding: 2px;
      }
    }

    .chooseWorkTab {
      color: #0085ff;
      border: 1px solid #0085ff;
      background-color: #f1f6fd;
      font-size: 16px;
      position: relative;

    }
  }

  .workTab1 {
    .workTabEvery {
      width: 24%;
      position: relative;
    }
  }

  .shouhouBox {
    display: flex;
    margin: 0px 16px 0 16px;
    padding-top: 5px;
    padding-bottom: 10px;
    justify-content: space-between;

    .shouhouTabEvery {
      width: 25%;
      text-align: center;
      color: #000000;
      position: relative;
      .line {
        background-color: #0085ff;
        position: absolute;
        width: 20%;
        height: 20%;
        border-radius: 15px;
        transform: translate(-50%, -50%);
        left: 50%;
        bottom: -50%;
        transition: .2s;
      }

    }

    .chooseShouhou {
      font-size: 15px;

      .line {
        background-color: #0085ff;
        position: absolute;
        width: 20%;
        height: 20%;
        border-radius: 15px;
        transform: translate(-50%, -50%);
        left: 50%;
        bottom: -50%;
        transition: .2s;
      }
    }
  }

  .list {
    background: #fff;

    .outItem {

    }

    .item {
      background-color: white;
      position: relative;
      margin: 10px 16px;
      box-shadow: 0px 0px 2px 0px rgba(102, 102, 102, 0.25);
      border-radius: 10px;
      padding: 10px;

      .oneGoods {
        .title {
          text-align: left;
          font-size: 14px;
          width: 100%;
          margin-left: 0;
          color: #1B1B1B;
          margin-bottom: 10px;
        }

        .goods {
          display: flex;

          .leftImg {
            width: 50px;
            height: 50px;
            border-radius: 10px;
            overflow: hidden;
            background: #fff;

            .image {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .rightInfo {
            flex: 1;
            margin-left: 10px;
            align-items: flex-start;

            .nameAndNums {
              display: flex;
              justify-content: space-between;

              .name {
                width: 90%;
                text-align: left;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              .name, .nums {
                font-size: 16px;
                color: #1B1B1B;
              }
            }

            .specification {
              margin-top: 2px;
              font-size: 16px;
              color: #7f7f7f;
              line-height: 24px;
              text-align: left;
            }

            .unit {
              font-size: 16px;
              color: #7f7f7f;
              text-align: left;

            }

            .nums {
              font-size: 16px;
              color: #7f7f7f;
              line-height: 24px;
              text-align: left;
            }
          }
        }

        .userAddress {
          text-align: left;
          margin-top: 10px;
          color: #1B1B1B;
          font-size: 16px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          word-break: break-all;
          -webkit-box-orient: vertical;
          margin-bottom: 5px;
        }

        .time {
          font-size: 18px;
          text-align: left;
          color: #0085FF;
        }

        .bootomBtnBox {
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;

          .btn {
            border-radius: 8px;
          }

          .jiedanBtn {
            position: relative;
            color: #fff;
            background: #0085ff;
            padding: 10px 20px;
            font-size: 14px;
          }

          .remark {
            margin-top: 10px;
            flex: 1;
            margin-right: 22px;
            border: 1px solid #0085ff;
            background-color: #f1f6fd;
            border-radius: 10px;
            font-size: 12px;
            text-align: left;
            padding: 2px 10px;
            color: #0085ff;
            min-height: 50px;
          }
        }

      }

      .moreGoods {

        .title {
          padding-bottom: 5px;
          display: flex;
          justify-content: space-between;

          .leftNums {
            width: 30% !important;
            color: #1b1b1b;
            font-weight: 600;
            font-size: 14px;
            display: flex;
            align-items: center;
          }

          .rightOrderId {
            text-align: left;
            width: auto;
            color: #1B1B1B;
            font-size: 14px;
            margin-right: 10px;
          }
        }

        .Goods {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          font-size: 16px;
          color: #1B1B1B;

          .goodItem {
            width: 50%;

            .weijiedanName {
              color: #1b1b1b;
              overflow: auto;
              white-space: nowrap;
              font-size: 14px;
              font-weight: 500;
              margin-top: 5px;
              line-height: 22px;
              text-align: left;
            }

            .goodsInner {
              display: flex;

              .image {
                width: 50px;
                height: 50px;
                border-radius: 10px;
                background-color: #F9FCFF;
                object-fit: cover;
              }

              .itemRight {
                color: #7F7F7F;
                font-size: 13px;
                text-align: left;
                line-height: 18px;
                margin-left: 5px;

                .commdTitle {
                  margin-top: 2px;
                }

                .unit {
                  margin-top: 2px;
                }

                .nums {
                  margin-top: 2px;
                }
              }
            }
          }
        }

        .address {
          text-align: left;
          margin-top: 10px;
          color: #1B1B1B;
          font-size: 16px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          word-break: break-all;
          -webkit-box-orient: vertical;
          margin-bottom: 5px;
        }

        .time {
          font-size: 18px;
          text-align: left;
          color: #0085FF;
        }

        .bootomBtnBox {
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;

          .btn {
            border-radius: 8px;
          }

          .jiedanBtn {
            position: relative;
            color: #fff;
            background: #0085ff;
            padding: 10px 20px;
            font-size: 14px;
          }

          .remark {
            margin-top: 10px;
            flex: 1;
            margin-right: 22px;
            border: 1px solid #0085ff;
            background-color: #f1f6fd;
            border-radius: 10px;
            font-size: 12px;
            text-align: left;
            padding: 2px 10px;
            color: #0085ff;
            min-height: 50px;
          }
        }
      }
    }

    .Songshui_yijiedan, .Songshui_yiwancheng {
      position: relative;

      .remark {
        margin-right: 0 !important;
      }

      .time {
        font-size: 18px;
      }

      .isSetY {
        color: #f74440 !important;
      }

      .bottom {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-repeat: no-repeat;
        background-position: -0% 30px;
        background-size: 20%;
        z-index: 11;
      }

      .bttom2 {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-repeat: no-repeat;
        background-size: 20%;
        z-index: 11;
        background-position: 100% 30px;
      }
    }


    .item {
      text-align: left;

      .surveyTitle {
        display: flex;
        padding: 10px 0px 5px 0px;
        font-weight: 800;
        color: #286CFB;

        .left {
          max-width: 55%;
          font-size: 16px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .right {
          color: #1b1b1b;
          font-size: 14px;
          margin-left: 10px;
          text-align: right;

          .zhibaowai {
            background-color: #666666 !important;
          }

          .zhibaoBox {
            padding: 2px 7px;
            border-radius: 25px;
            color: white;
            background-color: #286CFB;
            font-weight: 400;
            font-size: 12px;
          }

          .kuaixiuzhibao {
            //color: #0085FF;
            background-color: #0085FF !important;
          }

          .zhuangxiuzhibao {
            background-color: #f96422 !important;
          }
        }

        .pase{
          padding: 1px 5px;
          border-radius: 4px;
          color: white;
          background-color: red;
          margin-right: 5px;
          font-size: 12px;
        }
      }

      .time {
        color: #0085FF !important;
        font-size: 18px;
      }
    }

    .kancha {
      .time {
        width: 100%;
        margin-top: 5px;
        color: #1b1b1b;
      }

      .box {
        margin-top: 0;
        width: 100%;
        font-size: 16px;
        color: #1b1b1b;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        word-break: break-all;
        -webkit-box-orient: vertical;
        margin-bottom: 5px;
      }

      .bootomBtnBox {
        display: flex;
        justify-content: flex-end;

        .btn {
          background-color: #286CFB;
          font-size: 14px;
          padding: 8px 22px;
          margin-right: 10px;
          border-radius: 8px;
          color: #fff;
        }


      }

      .zhuangtai {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        .every {
          background-color: #F6F8F5;
          border: 1px solid #E3E5E2;
          color: #666666;
          padding: 2px 5px;
          border-radius: 25px;
          width: 20%;
          text-align: center;
          margin: 10px 0px;
          /* padding: 12rpx 32rpx; */
        }

        .red {
          background-color: red;
          color: white;
        }

        .y {
          color: white;
          background-color: #0085FF;
        }
      }
    }
    .weixiugong{
      .time {
        width: 100%;
        margin-top: 5px;
        color: #1b1b1b;
      }

      .box {
        margin-top: 0;
        width: 100%;
        font-size: 16px;
        color: #1b1b1b;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        word-break: break-all;
        -webkit-box-orient: vertical;
        margin-bottom: 5px;
      }

      .bootomBtnBox {
        display: flex;
        justify-content: flex-end;

        .btn {
          background-color: #286CFB;
          font-size: 14px;
          padding: 8px 22px;
          margin-right: 10px;
          border-radius: 8px;
          color: #fff;
        }


      }
    }
  }


  .bttomBox {
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    background: #fff;
    border-top: 1px solid #eeeeee;
    padding-top: 10px;

    .inn {
      display: flex;
      margin: 0 16px;
      justify-content: space-between;

      .left {
        display: flex;
        width: 50%;
        text-align: center;

        .telA {
          width: 25%;

          .kefu {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            color: #666666;
            margin: 0 5px;

            .iconfont {
              font-size: 32px;
              color: #286CFB;
            }
          }
        }

        .home {
          width: 25%;
          margin: 0 5px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          color: #666666;

          .iconfont {
            font-size: 24px;
            color: #286CFB;
          }

        }

        .kefu {
          .iconfont {
            font-size: 32px;
          }
        }
      }

      .right {
        width: 50%;
      }

      .btn {
        text-align: center;
        font-size: 14px;
        color: #fff;
        width: 100%;
        background: #0085ff;
        border-radius: 50px;
        padding: 10px 0;
      }
    }
  }
}

.suggessPop {
  background-color: #fff;

  .suggesttitle {
    text-align: left;
    //width: 100%;
    margin: 16px;
    font-size: 16px;
    color: #1B1B1B;
  }

  .textareaBox {
    margin: 0 16px;
    background: #fff;
    border-radius: 5px;
    border: 1px solid rgba(153, 153, 153, 0.15);
    position: relative;

    .textarea {
      font-size: 14px;
      padding: 6px 10px;
      color: #1B1B1B;
      width: 97%;
      border: none;
    }
  }

  .submit {
    text-align: center;
    font-size: 16px;
    margin: 10px 16px;
    background: #f96422;
    color: #fff;
    border-radius: 10px;
    padding: 13px;
  }
}

.dakaPop {
  width: 60%;
  border-radius: 20px;
  padding: 10px;

  .imageBox {
    min-height: 100px;
    text-align: center;
    position: relative;

    .iconfont {
      position: absolute;
      left: 50%;
      top: 40%;
      transform: translate(-50%, -50%);
      font-size: 50px;
      color: #0085ff;
    }
  }

  .time {
    font-size: 32px;
    color: #0085FF;
    text-align: center;
    line-height: 48px;
  }

  .tip {
    color: #3e9dfc;
    font-size: 16px;
    text-align: center;
    margin: 0 0 25px 0;
  }

  .btn {
    color: #0085ff;
    font-size: 16px;
    text-align: center;
    padding: 10px 0 0 0;
    border-top: 1px solid #e4e4e3;
  }
}

</style>