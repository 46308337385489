<template>
  <div style="position: relative;">
    <img
        class="topImg"
        src="https://picture.bjbjkj.top/image/Mask%20group%402x%20%281%29.png"
        alt=""
    />
    <div class="container" ref="container" @scroll="containerScroll">


      <div class="areaT">
        <navigationBar class="nivaBar">
          <template #search>
            <div class="navBox">
              <div class="centertext">
                百街水站
              </div>
              <div class="right">
                <div class="ever">
                  <span class="iconfont icon-kefu1" @click="kefu"></span>
                  客服
                </div>
                <div class="ever">
                  <span class="iconfont icon-dingdan" @click="goOrderLi"></span>
                  订单
                </div>
              </div>
            </div>
          </template>
        </navigationBar>
        <div class="swiperBox">
          <img class="swiperImg" :src="$store.state.imageHost+'/img/Group_4209@2x.png'" alt="">
        </div>
        <div class="areaOne">
          <!--          <div class="function">-->
          <!--            <div class="left" @click="waterCoupon">-->
          <!--              <div class="title">-->
          <!--                <div class="text">百街水票</div>-->
          <!--                <span class="iconfont icon-Frame-4"></span>-->
          <!--              </div>-->
          <!--              <div class="detailsText">桶装水票使用详情</div>-->
          <!--              <div class="line"></div>-->
          <!--            </div>-->
          <!--            <div class="right" @click="sender">-->
          <!--              <div class="title">-->
          <!--                <div class="text">一键送水</div>-->
          <!--                <span class="iconfont icon-Frame-4"></span>-->
          <!--              </div>-->
          <!--              <div class="detailsText">桶装送水一键搞定</div>-->
          <!--              <div class="line"></div>-->
          <!--            </div>-->
          <!--          </div>-->
          <div class="function">
            <img class="imgFun" :src="$store.state.imageHost+'/img/Frame_4187%402x.png'" alt="" @click="waterCoupon">
            <img class="imgFun" :src="$store.state.imageHost+'/img/Frame_4188%402x.png'" alt="" @click="sender">
          </div>
        </div>
      </div>
      <!--      /////////////////////////////1.0.0/////////////////////////////////////////////////////-->
      <van-sticky @change="stickchange" @scroll="stickscroll">
        <div class="outoverFlow" ref="sticky" :style="fixedFlag?'background:#fff;margin-top:0px':''">
          <!--          <div class="innerScroll" v-if="commodityLList.length>0">-->
          <!--            <div-->
          <!--                class="li"-->
          <!--                v-for="(item, index) in commodityLList"-->
          <!--                :key="index"-->
          <!--                :class="topIndex == index ? 'chooseTop' : ''"-->
          <!--                @click="chooseTop(index)"-->
          <!--            >-->
          <!--              <div class="imgBox">-->
          <!--                <img-->
          <!--                    class="img"-->
          <!--                    :src="$store.state.imageHost+item.image"-->
          <!--                    alt=""-->
          <!--                />-->
          <!--              </div>-->
          <!--              <div class="name">{{ item.name }}</div>-->
          <!--            </div>-->
          <!--          </div>-->
        </div>
      </van-sticky>
      <!--      /////////////////////////////////1.0.0/////////////////////////////////////////////////-->
      <div class="area">
        <div class="body">
          <div class="left" :class="fixedFlag?'fixed1':''" :style="'top:'+(this.stickyHieght+10)+'px'">
            <div
                class="li"
                v-for="(item, index) in leftLi"
                :key="index"
                :class="leftIndex == index ? 'chooseLeft' : ''"
                @click="chooseLeft(index)"
            >
              <!--              {{ item.name }}-->
              {{ item.cate_name }}
            </div>
          </div>
          <div class="left" v-if="fixedFlag"></div>

          <div class="right">
            <!--            <div class="top">-->
            <!--              <div class="leftSort" :class="sortType==1?'defaultS':''" @click="defaultS">默认排序 <span-->
            <!--                  class="iconfont icon-a-Frame32741"></span></div>-->
            <!--              <div class="rightSort">-->
            <!--                <div class="sale" :class="sortType==2?'saleS':''" @click="sale">销量</div>-->
            <!--                <div class="priceSort" @click="price" :class="sortType==3||sortType==4?'priceS'+sortType:''">-->
            <!--                  价格-->
            <!--                  <div class="iconfontBox">-->
            <!--                    <span class="iconfont icon-Vector"></span>-->
            <!--                    <span class="iconfont icon-a-Group2009"></span>-->
            <!--                  </div>-->
            <!--                  &lt;!&ndash;                  <span class="iconfont icon-a-Group20093"></span>&ndash;&gt;-->
            <!--                  &lt;!&ndash;                  <svg class="icon" aria-hidden="true">&ndash;&gt;-->
            <!--                  &lt;!&ndash;&lt;!&ndash;                    <use xlink:href="#icon-a-Group20093"></use>&ndash;&gt;&ndash;&gt;-->
            <!--                  &lt;!&ndash;                    <use xlink:href="#icon-a-Group2009-11"></use>&ndash;&gt;-->
            <!--                  &lt;!&ndash;                  </svg>&ndash;&gt;-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->
            <div class="ul">
              <div class="li" v-for="(item, index) in goodsLi" :key="index" @click.stop="godetails(item)">
                <div class="leftImgBox">
                  <!--                  <img :src="$store.state.imageHost+item.images" alt="" class="img"/>-->
                  <img :src="item.image" alt="" class="img"/>
                  <img v-if="item.product_type == 5" src="https://picture.bjbjkj.top/img/Group_3921%402x.png" alt=""
                       class="ticketTag">
                </div>
                <div class="info">
                  <div class="name">{{ item.store_name }} {{item.product_type == 5?item.attrvalue[0].write_times+'张':''}}</div>
<!--                  {{item.is_vip}}-->
                  <div class="detail">
                    <div class="spacl">
                      <!--                      规格:{{ item.unit_name }}-->
                    </div>
                    <!--                    <div class="line"></div>-->
                    <div class="sale">已售{{ item.sales }}</div>
                  </div>
                  <div class="price_add">
                    <div class="leftpriced">
                      <div class="leftPrice">
                        <div class="sellPrice">
                          <div>￥</div>
                          <div class="yuan">{{ item.yuan }}</div>
                          <div>.{{ item.mao }}</div>
                        </div>
                        <div class="lastPrice" :class="$store.state.is_vip.is_vip?'oldPrice':''" v-if="!$store.state.is_vip.is_vip">￥{{ item.ot_price }}</div>
                        <img v-if="$store.state.is_vip.is_vip" :src="$store.state.imageHost+'/img/vip_price_tag.png'"
                             alt="" class="vip_price_tag">
                      </div>
                      <div class="vipBox" v-if="item.is_vip">
                        <div class="vipPrice">￥{{ $store.state.is_vip.is_vip ? item.price : item.vip_price }}</div>
                        <img v-if="!$store.state.is_vip.is_vip" :src="$store.state.imageHost+'/img/vip_price_tag.png'"
                             alt="" class="vip_price_tag">
                        <div class="lastPrice" :class="$store.state.is_vip.is_vip?'oldPrice':''" v-if="$store.state.is_vip.is_vip">￥{{ item.ot_price }}</div>
                      </div>
                    </div>
                    <div class="rightAdd">
                      <div class="unadd" v-if="item.cart_num>0&&item.spec_type === 0" @click.stop="unadd(item)"></div>
                      <div class="input" v-if="item.cart_num>0&&item.spec_type === 0">{{ item.cart_num }}</div>
                      <div class="add" @click.stop="add(item)"></div>
                    </div>
                  </div>

                </div>
              </div>
              <!--            ///////////////////  水票/////////////////////////////-->
              <!--              <div class="li" v-for="(item,index) in ticketLi" :key="index+item.id">-->
              <!--                <div class="leftImgBox">-->
              <!--                  <img :src="$store.state.imageHost+item.water_img" alt="" class="img"/>-->
              <!--                </div>-->
              <!--                <div class="info">-->
              <!--                  <div class="brand">-->
              <!--                    <div class="brandBox">品牌</div>-->
              <!--                    {{ item.brand.name }}-->
              <!--                  </div>-->
              <!--                  <div class="name">{{ item.water_name }}</div>-->
              <!--                  <div class="detail">-->
              <!--                    <div class="spacl">{{ item.spacl }}</div>-->
              <!--                    <div class="spacl">-->
              <!--                      规格:{{ item.goods_type_id == 6 ? item.package_nember + '张/套餐' : item.specifications }}-->
              <!--                    </div>-->
              <!--                    <div class="line"></div>-->
              <!--                    <div class="sale">已售{{ item.buy_count }}</div>-->
              <!--                  </div>-->

              <!--                  <div class="price_add">-->
              <!--                    <div class="left">-->
              <!--                      <div class="leftPrice">-->
              <!--                        <div class="sellPrice">-->
              <!--                          <div>￥</div>-->
              <!--                          <div class="yuan">{{ item.yuan }}</div>-->
              <!--                          <div>.{{ item.mao }}</div>-->
              <!--                        </div>-->
              <!--                        <div class="lastPrice">￥{{ item.costprice }}</div>-->
              <!--                      </div>-->
              <!--                      <div class="vipBox">-->

              <!--                      </div>-->
              <!--                    </div>-->
              <!--                    <div class="rightAdd">-->
              <!--                      <div class="addBox" @click="buy(item)">-->
              <!--                        购买-->
              <!--                      </div>-->
              <!--                    </div>-->
              <!--                  </div>-->
              <!--                  &lt;!&ndash;                  <div class="vipBox">&ndash;&gt;-->
              <!--                  &lt;!&ndash;                    <div class="vipPrice">￥{{ item.vipPr }}</div>&ndash;&gt;-->
              <!--                  &lt;!&ndash;                    <div class="">会员价</div>&ndash;&gt;-->
              <!--                  &lt;!&ndash;                  </div>&ndash;&gt;-->
              <!--                </div>-->
              <!--              </div>-->
              <div class="loader-box">
                <div id="loader" v-if="loadLock"></div>
                <div id="nothing" v-if="!loadLock&&!hasMore">没有更多了~</div>
              </div>
            </div>
          </div>
        </div>
        <van-popup v-model="carshow" round position="bottom">
          <div class="popup">
            <div class="tioline"></div>
            <div class="title">
              <div class="left">购物车</div>
              <div class="right" @click="clearCar">清空</div>
            </div>
            <div class="carBox">
              <div class="item" v-for="(item, index) in cartLi" :key="index">

                <div class="leftImgBox">
                  <!--                  <img :src="$store.state.imageHost+item.products.images" alt="" class="img">-->
                  <img :src="item.productInfo.image" alt="" class="img">
                </div>
                <div class="rightInfo">
                  <div class="name">{{ item.productInfo.store_name }}</div>
                  <div class="detail">
                    <div class="spacl">
                    </div>
                    <div class="sale">已售{{ item.productInfo.sales }}</div>
                  </div>
                  <div class="price_add">
                    <div class="leftpriced">
                      <div class="leftPrice">
                        <div class="sellPrice">
                          <div>￥</div>
                          <div class="yuan">{{ item.productInfo.yuan }}</div>
                          <div>.{{ item.productInfo.mao }}</div>
                        </div>
                        <div class="lastPrice" :class="$store.state.is_vip.is_vip?'oldPrice':''" v-if="!$store.state.is_vip.is_vip">￥{{ item.sum_price }}</div>
                        <img v-if="$store.state.is_vip.is_vip" :src="$store.state.imageHost+'/img/vip_price_tag.png'"
                             alt="" class="vip_price_tag">
                      </div>
                      <div class="vipBox" v-if="item.productInfo.is_vip">
<!--                        <div class="vipPrice">-->
<!--                          ￥{{ $store.state.is_vip.is_vip ? item.productInfo.price : item.productInfo.vip_price }}-->
<!--                        </div>-->
                        <img v-if="!$store.state.is_vip.is_vip" :src="$store.state.imageHost+'/img/vip_price_tag.png'"
                             alt="" class="vip_price_tag">
                        <div class="lastPrice" :class="$store.state.is_vip.is_vip?'oldPrice':''" v-if="$store.state.is_vip.is_vip">￥{{ item.sum_price }}</div>
                      </div>
                    </div>
                    <div class="rightAdd">
                      <div class="unadd" v-if="item.cart_num>0" @click.stop="unadd(item)"></div>
                      <div class="input" v-if="item.cart_num>0">{{ item.cart_num }}</div>
                      <div class="add" @click.stop="add(item)"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="bottom">
              <div class="bottomBigbox">
                <div class="left">
                  <div class="car">

                    <img src="https://picture.bjbjkj.top/img/waterCar.png" alt="" class="image busPos">
                    <div class="nums" v-if="cartNums>0">{{ cartNums }}</div>
                  </div>
                  <div class="price">
                    ￥<span>
                  <span class="yuan">{{ allPirce.yuan }}</span
                  ><span class="mao">.{{ allPirce.mao }}</span></span
                  >
                  </div>
                </div>
                <div class="right">
                  <!-- <div class="songshui btnBox">一键送水</div> -->
                  <div class="zhifu btnBox" @click="submit">去支付</div>
                </div>
              </div>
            </div>

          </div>
        </van-popup>
        <div class="bottom" v-if="cartLi.length>=1">
          <div class="bottomBigbox">
            <div class="left">
              <div class="car" @click="opencar">
                <img src="https://picture.bjbjkj.top/img/waterCar.png" alt="" class="image busPos">
                <div class="nums" v-if="cartNums>0">{{ cartNums }}</div>
              </div>
              <div class="price">
                ￥<span>
                  <span class="yuan">{{ allPirce.yuan }}</span
                  ><span class="mao">.{{ allPirce.mao }}</span></span
              >
              </div>
            </div>
            <div class="right">
              <!-- <div class="songshui btnBox">一键送水</div> -->
              <div class="zhifu btnBox" @click="submit">去支付</div>
            </div>
          </div>
        </div>
        <suk-pop v-if="chooseGoods.id" @buyClicked="buyClicked" :initial_sku="initialSku" ref="suk" :skuParent="sku"
                 :goods="chooseGoods"></suk-pop>
      </div>

      <loding v-if="loding"></loding>
    </div>
  </div>
</template>

<script>
import navigationBar from "../../navigation-bar/navigation-bar.vue";
import loding from "@/components/common/loding/loding.vue";
import Vue from 'vue';
import {Dialog, Toast} from 'vant';
import {
  addCar, delCartList, get_attr,
  get_cat_package_list,
  getAllCatlist, getCartList, getCartNums, getWaterBrand, getWatercategory, getWaterproductsList, set_cart_num,
  // getFiveCus, kefu_list
} from "@/utils/api";
import {_getChatRoom, _throttle, getParentIdNew} from "@/utils/tool"
import buyPail from "@/components/common/water/buyPail.vue";
import SukPop from "@/components/common/sukPop.vue";

// 全局注册
Vue.use(Dialog);
export default {
  components: {
    SukPop,
    navigationBar,
    loding,
    [Dialog.Component.name]: Dialog.Component,
  },
  props: {},
  data() {
    return {
      chooseGoods: {},
      waterObj: {},
      loding: false,
      commodityLList: [
        {name: "桶装水", img: ""},
        {name: "一次性包装水", img: ""},
        {name: "瓶装水", img: ""},
        {name: "Logo订制水", img: ""},
      ],
      leftLi: [
        "推荐",
        "活动",
        "热销",
        "恒大",
        "农夫山泉",
        "怡宝",
        "哇哈哈",
        "景田",
        "今麦郎",
      ],
      leftIndex: 0,
      topIndex: 0,
      goodsLi: [],
      ticketLi: [],
      container: null,
      cartLi: [],
      allPirce: {
        yuan: 0,
        mao: 0,
      },
      cartNums: 0,
      amount: 0,
      fixedFlag: false,
      stickyHieght: 0,
      vipFlag: false,
      carshow: false,
      sortType: 1,


      page: 1,
      limit: 10,
      cid: 0, //一级分类
      sid: 0, //二级分类
      tid: 0, //三级分类
      hasMore: true,//是否可以继续滚动
      loadLock: true,//是否展示loding


      sku: {
      // 所有sku规格类目与其值的从属关系，比如商品有颜色和尺码两大类规格，颜色下面又有红色和蓝色两个规格值。
      // 可以理解为一个商品可以有多个规格类目，一个规格类目下可以有多个规格值。
      tree: [
        {
          k: "颜色",
          k_id: "1",
          v: [
            {
              id: "30349",
              name: "天蓝色",
              imgUrl:
                  "https://img.yzcdn.cn/upload_files/2017/02/21/FjKTOxjVgnUuPmHJRdunvYky9OHP.jpg!100x100.jpg",
            },
            {
              id: "1215",
              name: "白色",
            },
          ],
          k_s: "s1",
          count: 2,
        },
        {
          k: "尺寸",
          k_id: "2",
          v: [
            {
              id: "1193",
              name: "1",
            },
            {
              id: "1194",
              name: "2",
            },
          ],
          k_s: "s2",
          count: 2,
        },
      ],
          // 所有 sku 的组合列表，如下是：白色1、白色2、天蓝色1、天蓝色2
          list: [
        {
          id: 2259,
          price: 12000, //价格（单位分）
          s1: "1215",
          s2: "1193",
          stock_num: 20, //库存
          goods_id: 946755,
          a: 0,
        },
        {
          id: 2260,
          price: 11000,
          s1: "1215",
          s2: "1194",
          stock_num: 2, //库存
          goods_id: 946755,
        },
        {
          id: 2257,
          price: 13000,
          s1: "30349",
          s2: "1193",
          stock_num: 40, //库存
          goods_id: 946755,
        },
        {
          id: 2258,
          price: 10000,
          s1: "30349",
          s2: "1194",
          stock_num: 50, //库存
          goods_id: 946755,
        },
      ],
          price: "59.00-129.00", // 默认价格（单位元）
          stock_num: 227, // 商品总库存
          none_sku: false, // 是否无规格商品 false正常显示那些可供选择的标准，此处是颜色和尺寸
          hide_stock: false, // 是否隐藏剩余库存 false正常显示剩余多少件的那个库存,
    },
      initialSku: {},



    };
  },
  watch: {
    amount(n) {
      this.allPirce.yuan = n.split(".")[0]
      this.allPirce.mao = n.split(".")[1]
    },
    topIndex(n) {
      if (this.commodityLList[n].name != '桶装水') {
        this.ticketLi = []
      }
    },
    goodsLi(n) {
      n.forEach(item => {
        if (!item.yuan) {
          if (item.is_vip == 0) {
            item.yuan = item.price.split('.')[0]
            item.mao = item.price.split('.')[1]
          } else if (item.is_vip == 1) {
            item.yuan = (this.$store.state.is_vip.is_vip ? item.vip_price : item.price).split('.')[0]
            item.mao = (this.$store.state.is_vip.is_vip ? item.vip_price : item.price).split('.')[1]
          }
        }
      })
    },
  },
  computed: {
    buyPail() {
      return buyPail
    },
  },
  methods: {
    containerScroll(event){
      let scrollTop1 = event.target.scrollTop;
      // 滚动条滚动的距离
      let scrollStep = scrollTop1 - this.oldScrollTop;
      // 更新——滚动前，滚动条距文档顶部的距离
      this.oldScrollTop = scrollTop1;
      if (scrollStep < 0) {
        // console.log("滚动条向上滚动了！");
      } else {
        const {scrollHeight, clientHeight, scrollTop} = event.target;
        if (
            (scrollHeight - (scrollTop + clientHeight)) / clientHeight <=
            0.05
        ) {
          console.log(this.loadLock)

          if (this.hasMore) {
            this.loadLock = true
            this.qingqiu(this);
          }
        }
      }
    },
    buyClicked(e) {
      if(e.selectedSkuComb.suk){
        //多规格
        set_cart_num(e.goodsId, e.selectedNum, e.selectedSkuComb.unique, 1,1).then(res => {
          if (res.data.status == 200) {
            Toast('加入成功')
            this.getCart()
            this.$refs.suk.closePop()
            this.initialSku.selectedNum = 1
            this.updataGoods()
            // e.goodsId
            // this.$nextTick(()=>{
            //   console.log(this.cartLi)
            //   console.log(this.cartLi.filter(item=>{return item.product_id == e.goodsId}).reduce((total, item) =>
            //           total += item.cart_num
            //       , 0))
            // })

            // this.goodsLi.forEach(it => {
            //   it.cart_num = mapc[it.id] ?? 0
            // })
          }else{
            Toast(res.data.msg)
          }
        })
      }
    },
    goOrderLi() {
      this.$router.push({
        name: "order_list",
        params: {id: '0',},
      })
    },
    kefu() {
      if (!this.$store.state.token) {
        Toast('您还未登录，请登录')
        return
      }
      //type 2 辅材 2 水站 3 超市 4 快修 5 房产
      _getChatRoom(2, 1).then(res => {
        if (res) {
          this.$router.push({
            name: 'chat_details',
          })
        }
      })
      // window.open(this.$router.resolve({name: 'chat_details'}).href, '_blank');
      // console.log('水站客服')
    },
    godetails(item) {
      this.$router.push({
        path: '/water_details',
        query: {goods: item.id}
      })
    },
    waterCoupon() {
      this.$router.push({name: 'water_coupon'})
    },
    sender() {
      this.$router.push({name: 'one_key_send_water'})
    },
    chooseTop(e) {
      if (this.topIndex == e) return;
      this.topIndex = e;
      this.leftIndex = 0
      this.leftLi = this.commodityLList[e].child
      this.loding = true
      this.updataGoods()
    },
    chooseLeft(e) {
      if (this.leftIndex == e) return;
      this.leftIndex = e;
      this.loding = true
      this.loadLock = true
      this.hasMore = true
      this.updataGoods()

    },
    opencar() {
      this.carshow = true
    },
    submit() {
      if (this.carshow) {
        this.$router.push({
          name: 'createOrderSecond',
          params: {list: this.cartLi, allPrice: this.allPirce},
        })
        this.$store.commit('updateWaterOrder', this.cartLi)
      } else {
        this.carshow = true
      }
    },
    stickchange(isFixed) {
      this.fixedFlag = isFixed
    },
    stickscroll() {
      // console.log(e)
    },
    add(item) {
      if (!this.$store.state.token) {
        Toast('请登录')
        return;
      }
      let nums = 1
      if (item.cart_num < item.sell_num) {
        nums = item.sell_num
      } else {
        nums = 1
      }
      if (item.spec_type == 1) {
        //多规格
        this.chooseGoods = item
        this.chooseGoods.storeInfo = this.chooseGoods
        this.$nextTick(async () => {
          this.multi_specification()
        })
      } else {
        //单规格
        set_cart_num(item.product_id, nums, item.product_attr_unique?item.product_attr_unique:'', 1,1).then(res => {
          if (res.data.status == 200) {
            item.cart_num += 1
            get_attr(item.id, 0)
            this.getCartNum()
            this.getCart()
            if (item.productInfo) {
              let mapc = Object.fromEntries(this.cartLi.map(({product_id, cart_num}) => [product_id, cart_num]));
              this.goodsLi.forEach(it => {
                it.cart_num = mapc[it.id] ?? 0
              })
            }
          } else {
            Toast(res.data.msg)
          }
        })
      }

    },
    unadd(item) {
      if (!this.$store.state.token) {
        Toast('请登录')
        return;
      }
      let num = 1
      if ((item.cart_num <= item.sell_num) && item.sell_num < 1) {
        num = item.sell_num
      } else {
        num = 1
      }
      set_cart_num(item.product_id, num, item.product_attr_unique?item.product_attr_unique:'', 0,1).then(res => {
        if (res.data.status == 200) {
          item.cart_num -= num
          this.getCartNum()
          this.getCart()
          if (item.productInfo) {
            let mapc = Object.fromEntries(this.cartLi.map(({product_id, cart_num}) => [product_id, cart_num]));
            this.goodsLi.forEach(it => {
              it.cart_num = mapc[it.id] ?? 0
            })
          }
        } else {
          Toast(res.data.msg)
        }
      })

    },
    add1(item) {
      if (!this.$store.state.token) {
        Toast('请登录')
        return;
      }
      let nums = 1
      addCar(this.$store.state.token, item.product_id, nums, 1, 1).then(res => {
        if (res.data.status == true) {
          item.nums += nums
          let mapc = Object.fromEntries(this.cartLi.map(({product_id, nums}) => [product_id, nums]));
          this.goodsLi.forEach(it => {
            it.cart_num = mapc[it.id] ?? 0
          })
        } else {
          Toast(res.data.msg)
        }
      }).catch(err => {
        console.log(err)
        // Toast(err.data.msg)
      })
    },
    unadd1(item) {
      if (!this.$store.state.token) {
        Toast('请登录')
        return;
      }
      let nums = 1
      if (item.nums <= item.products.sell_num) {
        nums = item.products.sell_num
      } else {
        nums = 1
      }
      addCar(this.$store.state.token, item.product_id, nums, 2, 1).then(res => {
        if (res.data.status == true) {
          item.nums -= nums
          let mapc = Object.fromEntries(this.cartLi.map(({product_id, nums}) => [product_id, nums]));
          this.goodsLi.forEach(it => {
            it.cart_num = mapc[it.id] ?? 0
          })
        } else {
          Toast(res.data.msg)
        }
      }).catch(err => {
        console.log(err)
        // Toast(err.data.msg)
      })
    },
    clearCar() {
      Dialog.confirm({
        title: '确认清空购物车？',
      })
          .then(() => {
            // on confirm
            if (!this.$store.state.token) return;
            let goods_id = []
            this.cartLi.forEach(item => {
              goods_id.push(item.id)
            })
            goods_id = String(goods_id)
            delCartList(goods_id).then(res => {
              if (res.data.status == 200) {
                this.goodsLi.forEach(it => {
                  it.cart_num = 0
                })
                this.cartLi = []
                this.carshow = false
              } else {
                Toast(res.data.msg)
              }
            })
          })
          .catch(() => {
            // on cancel
          });
    },
    defaultS() {
      this.sortType = 1
      this.goodsLi1
      this.goodsLi = this.goodsLi.sort(this.compareAlign('id', 'inverted'))
      // this.goodsLi.sort(function (a, b) {return a.id - b.id;})
    },
    sale() {
      this.sortType = 2
      let list = JSON.parse(JSON.stringify(this.goodsLi))
      this.goodsLi = list.sort(this.compareAlign('buy_count', 'inverted'))
    },
    compareAlign(prop, align) {
      return function (a, b) {
        var value1 = a[prop];
        var value2 = b[prop];
        if (align == "positive") {//正序
          return new Date(value1) - new Date(value2);
        } else if (align == "inverted") {//倒序
          return new Date(value2) - new Date(value1);
        }
      }
    },
    price() {
      let list = JSON.parse(JSON.stringify(this.goodsLi))
      list.forEach(item => {
        item.price = Number(item.price)
      })
      if (this.sortType == 3) {
        this.sortType = 4
        this.goodsLi = list.sort(this.compareAlign('price', 'positive'))
      } else {
        this.sortType = 3
        this.goodsLi = list.sort(this.compareAlign('price', 'inverted'))
      }
      console.log(this.goodsLi)
    },
    async initList() {
      await getAllCatlist(1)
      const {data} = await getWatercategory()
      if (data.status == 1 || data.status == 200) {
        let list = data.data.find(item => {
          return item.cate_name == '百街水站'
        }).children
        this.waterObj = data.data.find(item => {
          return item.cate_name == '百街水站'
        })
        list = JSON.parse(JSON.stringify(list))
        // 头部li
        this.commodityLList = list
        // 左侧li
        this.leftLi = list[this.topIndex].children
        let goods_cat_id = this.leftLi[this.leftIndex].id == 0 ? this.commodityLList[this.topIndex].id : this.leftLi[this.leftIndex].id
        let is_hot = this.leftLi[this.leftIndex].id == 0 ? 1 : 2
        //获取商品
        this.page = 1;
        this.cid = this.waterObj.id //一级分类
        this.sid = list[this.topIndex].id //二级分类
        this.tid = this.leftLi[this.leftIndex].id //三级分类
        const goodsData = await getWaterproductsList(this.page, this.limit, 1, this.cid, this.sid, this.tid)
        if (goodsData.data.status == true || goodsData.data.status == 200) {
          let list = goodsData.data.data
          this.goodsLi = this.goodsLi.concat(list)
          if (list.length <= 0) {
            this.hasMore = false
          }
          this.loadLock = false
        } else {
          Toast(goodsData.data.msg)
        }

        if (list[this.topIndex].name == '桶装水') {
          //获取水票
          const ticket = await get_cat_package_list(goods_cat_id, is_hot)
          if (ticket.data.status == true) {
            this.ticketLi = ticket.data.data.data
            this.ticketLi.forEach((item => {
              item.yuan = item.price.split('.')[0]
              item.mao = item.price.split('.')[1]
            }))
          } else {
            Toast(ticket.msg)
          }
        }


      } else {
        Toast(data.msg)
      }
    },
    getCartNum() {
      getCartNums(0,1).then(res => {
        if (res.data.status == 200) {
          this.cartNums = res.data.data.count
          this.amount = res.data.data.sum_price
        } else {
          Toast(res.data.msg)
        }
      })
    },

    async updataGoods() {
      this.goodsLi = []
      this.ticketLi = []
      let goods_cat_id = this.leftLi[this.leftIndex].id == 0 ? this.commodityLList[this.topIndex].id : this.leftLi[this.leftIndex].id
      let is_hot = this.leftLi[this.leftIndex].id == 0 ? 1 : 2
      this.cid = this.waterObj.id //一级分类
      this.sid = this.waterObj.children[this.topIndex].id //二级分类
      this.tid = this.leftLi[this.leftIndex].id //三级分类
      const {data} = await getWaterproductsList(this.page, this.limit, 1, this.cid, this.sid, this.tid)

      if (data.status == true || data.status == 200) {
        this.loding = false
        let list = data.data
        if (list.length<this.limit) {
          this.hasMore = false
        }
        this.goodsLi = this.goodsLi.concat(list)
        this.loadLock = false
      } else {
        Toast(data.msg)
      }
      this.loding = false
      if (this.commodityLList[this.topIndex].name == '桶装水') {
        //获取水票
        const ticket = await get_cat_package_list(goods_cat_id, is_hot)
        // const goodsData = await getWaterproductsList(this.page,this.limit,1,this.cid,this.sid ,this.tid)
        // res.data.data
        if (ticket.data.status == true) {
          this.ticketLi = ticket.data.data.data
          this.ticketLi.forEach((item => {
            item.yuan = item.price.split('.')[0]
            item.mao = item.price.split('.')[1]
          }))
        } else {
          Toast(ticket.data.msg)
        }
      }

    },
    getCart() {
      if (this.$store.state.token) {
        getCartList(1).then(res => {
          if (res.data.status == 200) {
            this.getCartNum()
            this.cartLi = res.data.data
            this.cartLi.forEach(item => {
              if (!item.productInfo.yuan) {
                if (item.productInfo.is_vip == 0) {
                  // item.productInfo.yuan = item.productInfo.price.split('.')[0]
                  // item.productInfo.mao = item.productInfo.price.split('.')[1]
                  item.productInfo.yuan = item.truePrice.toFixed(2).split('.')[0]
                  item.productInfo.mao = item.truePrice.toFixed(2).split('.')[1]
                } else {
                  // item.productInfo.yuan = (this.$store.state.is_vip.is_vip ? item.productInfo.vip_price : item.productInfo.price).split('.')[0]
                  // item.productInfo.mao = (this.$store.state.is_vip.is_vip ? item.productInfo.vip_price : item.productInfo.price).split('.')[1]
                  item.productInfo.yuan = item.truePrice.toFixed(2).split('.')[0]
                  item.productInfo.mao = item.truePrice.toFixed(2).split('.')[1]
                }
              }
            })
          } else {
            Toast(res.data.msg)
          }
        })
        // getCartListAll(this.$store.state.token).then(res => {
        //   console.log(res.data.data.data)
        //   if (res.data.status == true) {
        //     this.cartLi = res.data.data.data.list
        //     this.cartLi.forEach(item => {
        //       item.products.yuan = item.products.price.split('.')[0]
        //       item.products.mao = item.products.price.split('.')[1]
        //     })
        //     console.log(this.cartLi)
        //   }
        // })
      }
    },


    qingqiu:_throttle(async function () {
      this.limit += 10
      this.updataGoods(this);
    },200),
    async multi_specification() {
      const {data} = await get_attr(this.chooseGoods.id, 0)
      this.chooseGoods.storeInfo.picture = this.chooseGoods.storeInfo.image
      this.sku.price = this.chooseGoods.storeInfo.is_vip?(this.$store.state.is_vip.is_vip?this.chooseGoods.storeInfo.vip_price:this.chooseGoods.storeInfo.price):this.chooseGoods.storeInfo.price
      this.sku.collection_id = this.chooseGoods.storeInfo.id
      this.sku.stock_num = this.chooseGoods.storeInfo.stock
      this.sku.quota = this.chooseGoods.storeInfo.limit_num
      let tree = JSON.parse(JSON.stringify(data.data.productAttr))
      let list2 = Object.values(data.data.productValue)
      let initialSku = {}
      tree.forEach((item) => {
        item.v=[];
        item.attr_value.forEach((item2,index2)=>{
          item.v.push({
            id:  item.id+''+index2,
            name:  item2.attr
          })
        })
        item.k = item.attr_name
        item.k_s = String(item.id)
        initialSku[item.k_s] = item.v[0].id
        item.imgUrl=''
        item.previewImgUrl=''
        // item.v.id = item.attr_value.id
        delete item.attr_value
        delete item.attr_values
        delete item.attr_name
        delete item.product_id
        delete item.type
      })
      list2.forEach(item=>{
        item.stock_num = item.stock
        item.goods_id = item.id
        item.price =  item.price*100
        tree.forEach(item2=>{
          if(!item[item2.k_s]){
            item[item2.k_s]  = ''
          }
        })
        item.suk.split(',').forEach(item2=>{
          let id = getParentIdNew(tree,'name',item2,'v').id
          item[id.slice(0,id.length-1)]= id
        })

      })
      console.log(tree,'tree')
      console.log(list2,'list2')
      this.sku.tree = tree
      this.sku.list = list2
      this.initialSku = initialSku
      this.$refs.suk.openPop()
    },
    get_version_1_1_brand() {
      getWaterBrand().then(res => {
        if (res.data.status == 200) {
          console.log(res.data.data)
        }
      })
    },
  },
  created() {
  },
  mounted() {
    this.container = this.$refs.container;
    this.stickyHieght = this.$refs.sticky.offsetHeight
    // this.getAllCatlist()
    this.initList()
    this.get_version_1_1_brand()

    this.getCart()
  },
  onShow() {
    this.getCart()
  },
};
</script>
<style lang="scss" scoped>
.loader-box {
  width: auto;
}

.noGoods {
  font-size: 14px;
  text-align: center;
  line-height: 50px;
  color: #666666;
}

.popup {
  position: relative;
  padding: 20px 16px 0 16px;
  background: #fff;

  .carBox {
    max-height: 50vh;
    overflow: scroll;
    margin-top: 10px;
    padding-bottom: 70px;

    .item {
      display: flex;
    }

    .leftImgBox {
      width: 75px;
      height: 75px;
      border-radius: 10px;
      overflow: hidden;
      position: relative;

      .img {
        width: 100%;
        object-fit: cover;
      }
    }

    .rightInfo {
      flex: 1;
      padding-left: 10px;
      padding-bottom: 10px;

      .brand {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        display: flex;
        text-overflow: ellipsis;
        align-items: center;
        font-size: 13px;
        color: #1B1B1B;
        line-height: 18px;

        .brandBox {
          font-weight: 500;
          font-size: 12px;
          color: #1b1b1b;
          line-height: 18px;
          padding: 0 4px;
          background: #e8e8e8;
          border-radius: 4px 4px 4px 4px;
          margin-right: 5px;
        }
      }

      .name {
        font-weight: 500;
        font-size: 14px;
        color: #1b1b1b;
        line-height: 24px;
        white-space: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        align-items: center;
        text-align: left;

        display: -webkit-box;
        /* 必须结合的属性 ，设置或检索伸缩盒对象的子元素的排列方式 。*/
        -webkit-box-orient:vertical;
        /*要显示的行数*/
        -webkit-line-clamp:2;
        /* 溢出部分隐藏 */
        overflow:hidden;
      }

      .detail {
        display: flex;
        font-weight: 400;
        font-size: 12px;
        color: #7f7f7f;
        line-height: 18px;
        overflow: hidden;
        text-overflow: ellipsis;
        align-items: center;

        .line {
          margin: 0 6px;
          width: 1px;
          height: 9px;
          background: #dddddd;
        }
      }

      .price_add {
        display: flex;
        justify-content: space-between;
        margin-right: 16px;
        align-items: flex-end;
        font-weight: 500;

        .leftPrice {
          display: flex;
          //align-items: baseline;
          align-items: flex-end;
          align-items: center;

          .sellPrice {
            display: flex;
            font-size: 14px;
            color: #fd2a39;
            line-height: 18px;
            align-items: baseline;

            .yuan {

              font-size: 20px !important;
            }
          }

          .lastPrice {
            text-decoration-line: line-through;
            line-height: 18px;
            color: #7f7f7f;
            font-size: 12px;
          }
        }

        .vip_price_tag {
          width: 34px;
          height: 14px;
          margin-left: 5px;
        }

        .rightAdd {
          display: flex;

          .unadd,
          .add {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background-color: #398ae9;
            position: relative;
          }

          .add::after,
          .unadd:after {
            content: "";
            display: block;
            position: absolute;
            transform: translate(-50%, -50%);
            width: 50%;
            height: 10%;
            background-color: white;
            top: 50%;
            left: 50%;
            border-radius: 100px;
          }

          .add::before {
            content: "";
            display: block;
            position: absolute;
            transform: translate(-50%, -50%);
            width: 10%;
            height: 50%;
            background-color: white;
            top: 50%;
            left: 50%;
            border-radius: 100px;
          }

          .input {
            height: 22px;
            width: 22px;
            line-height: 22px;
            margin: 0 5px;
            text-align: center;
            font-size: 20px;
            font-weight: 600;
            color: #1b1b1b;
            white-space: nowrap;
            overflow: scroll;
          }

          .input::-webkit-scrollbar {
            display: none;
          }

          .addBox {
            background: #0085FF;
            border-radius: 5px 5px 5px 5px;
            font-weight: 500;
            font-size: 12px;
            color: #FFFFFF;
            line-height: 24px;
            text-align: center;
            padding: 0 10px;
          }
        }
      }

      .vipBox {
        display: flex;
        font-weight: 600;
        font-size: 14px;
        color: #392723;
        line-height: 18px;
        align-items: center;

        .vipPrice {
          margin-right: 5px;
        }
      }
    }
  }

  .carBox::-webkit-scrollbar {
    display: none;
  }

  .tioline {
    position: absolute;
    top: 10px;
    left: 50%;
    width: 10%;
    height: 5px;
    background: #0085ff;
    transform: translate(-50%, -0%);
    border-radius: 25px;
  }

  .title {
    display: flex;
    justify-content: space-between;
  }
}

.fixed1 {
  position: fixed;
}

.nivaBar {
  position: relative;
  z-index: 2;
  color: #fff;

  .navBox {
    width: 85%;
    display: flex;
    justify-content: center;
    position: relative;
    font-size: 18px;

    .right {
      position: absolute;
      right: 0;
      display: flex;

      .iconfont {
        font-size: 24px;
      }

      .ever {
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        font-size: 11px;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.topImg {
  position: absolute;
  top: 0;
  right: 0;
  height: 278px;
  width: 100%;
}

.swiperBox {
  margin: 10px 10px 0 10px;

  .swiperImg {
    width: 100%;

  }
}

.areaOne {
  margin: 0 10px;

  .function {
    display: flex;
    justify-content: space-between;

    .left,
    .right {
      width: calc(45% - 10px);
      background-repeat: no-repeat;
      background-size: 35%;
      padding: 10px 10px 14px 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-color: white;
      background-position: 100% 100%;
      overflow: hidden;
      position: relative;
      border-radius: 10px;

      .title {
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        color: #3263ac;

        .iconfont {
          font-size: 25px;
        }
      }

      .detailsText {
        font-size: 12px;
        text-align: left;
        color: #83a2ca;
        line-height: 18px;
        margin-top: 4px;
      }

      .line {
        position: absolute;
        background: linear-gradient(
                270deg,
                #c0e0ff 0%,
                rgba(229, 240, 255, 0) 100%
        );
        width: 50%;
        height: 12px;
        bottom: 0;
        right: 0;
      }
    }

    .right {
      background-image: url("https://bjsf.bjsky.top/img/ba50c8c946924b522a5979e2b54a6b9@2x.png");
    }


    .imgFun {
      width: 48%;
    }
  }
}

.outoverFlow {
  //   background: red;
  position: relative;
  z-index: 2;
  //margin: 15px 16px 0 16px;
  padding: 15px 16px 0 16px;

  .innerScroll {
    text-align: left;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    overflow: scroll;

    .li {
      // flex-direction: column;
      display: inline-block;
      width: 25%;
      text-align: center;
      margin-right: 10px;

      .imgBox {
        width: 74px;
        height: 74px;
        background: #fff;
        border-radius: 10px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid transparent;

        .img {
          width: 80%;
          height: 80%;
        }
      }

      .name {
        font-weight: 400;
        font-size: 13px;
        color: #1b1b1b;
        line-height: 15px;
        text-align: center;
        margin-top: 5px;
        white-space: nowrap;
      }
    }

    .li:nth-last-of-type(1) {
      margin-right: 0px;
    }

    .chooseTop {
      .imgBox {
        border: 2px solid #0085ff;
      }
    }
  }

  .innerScroll::-webkit-scrollbar {
    display: none;
  }
}

.body {
  position: relative;
  z-index: 2;
  display: flex;
  margin-top: 10px;
  padding-bottom: 100px;
  background: #fff;

  .left {
    width: 20%;
    background: #fff;
    max-width: 170px;

    .li {
      font-weight: 400;
      font-size: 13px;
      color: #1b1b1b;
      line-height: 24px;
      padding: 14px 0;
      background: #fafafa;
    }

    .chooseLeft {
      background: #fff;
      font-size: 14px;
      color: #0085ff;
    }
  }

  .right {
    width: 80%;
    padding-left: 10px;

    .top {
      margin-right: 24px;
      display: flex;
      justify-content: space-between;
      //padding: 14px 0;
      font-weight: 400;
      font-size: 13px;
      color: #7f7f7f;
      line-height: 24px;
      align-items: center;
      padding: 14px 0;

      .leftSort {
        display: flex;
        align-items: center;

        .iconfont {
          font-size: 20px;
        }
      }

      .defaultS {
        color: #0085FF;
      }

      .rightSort {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .saleS {
          color: #0085FF;
        }

        .priceS3 {
          color: #0085FF !important;

          .icon-Vector {
            color: #0085FF !important;
          }
        }

        .priceS4 {
          color: #0085FF !important;

          .icon-a-Group2009 {
            color: #0085FF !important;
          }
        }

        .iconfont {
          font-size: 25px;
        }

        .sale {
          margin-right: 20px;
        }

        .priceSort {
          display: flex;
          align-items: center;

          .iconfontBox {
            display: flex;
            align-items: center;
            justify-content: center;

            .iconfont {
              font-size: 10px;
              margin-right: -5px;
              display: flex;
              color: #7f7f7f;
            }
          }
        }
      }
    }

    .ul {
      .li {
        display: flex;
        padding-top: 10px;

        .leftImgBox {
          display: flex;
          position: relative;

          .img {
            width: 75px;
            height: 75px;
            object-fit: cover;
            border-radius: 5px;
          }

          .ticketTag {
            position: absolute;
            width: 32px;
            height: 18px;
          }
        }

        .info {
          width: 90%;
          border-bottom: 1px solid #e8e8e8;
          padding-left: 10px;
          padding-bottom: 10px;

          .brand {
            display: flex;
            width: 100%;
            white-space: normal;
            overflow: hidden;
            text-overflow: ellipsis;
            align-items: center;
            font-weight: 500;
            font-size: 13px;
            color: #1b1b1b;
            line-height: 18px;

            .brandBox {
              font-weight: 500;
              font-size: 12px;
              color: #1b1b1b;
              line-height: 18px;
              padding: 0 4px;
              background: #e8e8e8;
              border-radius: 4px 4px 4px 4px;
              margin-right: 5px;
            }
          }

          .name {
            font-weight: 500;
            font-size: 14px;
            color: #1b1b1b;
            line-height: 24px;
            white-space: normal;
            overflow: hidden;
            text-overflow: ellipsis;
            align-items: center;
            text-align: left;
            display: -webkit-box;
            /* 必须结合的属性 ，设置或检索伸缩盒对象的子元素的排列方式 。*/
            -webkit-box-orient:vertical;
            /*要显示的行数*/
            -webkit-line-clamp:2;
            /* 溢出部分隐藏 */
            overflow:hidden;


          }

          .detail {
            display: flex;
            font-weight: 400;
            font-size: 12px;
            color: #7f7f7f;
            line-height: 18px;
            overflow: hidden;
            text-overflow: ellipsis;
            align-items: center;

            .line {
              margin: 0 6px;
              width: 1px;
              height: 9px;
              background: #dddddd;
            }
          }

          .price_add {
            display: flex;
            justify-content: space-between;
            margin-right: 10px;
            align-items: flex-end;
            font-weight: 500;
            min-height: 38px;

            .vip_price_tag {
              width: 34px;
              height: 14px;
            }

            .lastPrice {
              text-decoration-line: line-through;
              line-height: 18px;
              color: #7f7f7f;
              font-size: 12px;
            }

            .leftPrice {
              display: flex;
              align-items: baseline;
              align-items: flex-end;
              align-items: center;

              .sellPrice {
                display: flex;
                font-size: 14px;
                color: #fd2a39;
                line-height: 18px;
                margin-right: 5px;

                align-items: baseline;

                .yuan {
                  font-size: 20px !important;
                }
              }


            }

            .rightAdd {
              display: flex;

              .unadd,
              .add {
                width: 24px;
                height: 24px;
                border-radius: 50%;
                background-color: #398ae9;
                position: relative;
              }

              .add::after,
              .unadd:after {
                content: "";
                display: block;
                position: absolute;
                transform: translate(-50%, -50%);
                width: 50%;
                height: 10%;
                background-color: white;
                top: 50%;
                left: 50%;
                border-radius: 100px;
              }

              .add::before {
                content: "";
                display: block;
                position: absolute;
                transform: translate(-50%, -50%);
                width: 10%;
                height: 50%;
                background-color: white;
                top: 50%;
                left: 50%;
                border-radius: 100px;
              }

              .input {
                height: 22px;
                width: 22px;
                line-height: 22px;
                margin: 0 5px;
                text-align: center;
                font-size: 20px;
                font-weight: 600;
                color: #1b1b1b;
                white-space: nowrap;
                overflow: scroll;
              }

              .input::-webkit-scrollbar {
                display: none;
              }

              .addBox {
                background: #0085FF;
                border-radius: 5px 5px 5px 5px;
                font-weight: 500;
                font-size: 12px;
                color: #FFFFFF;
                line-height: 24px;
                text-align: center;
                padding: 0 10px;
              }
            }
          }

          .vipBox {
            display: flex;
            font-weight: 600;
            font-size: 14px;
            color: #392723;
            line-height: 18px;
            align-items: flex-end;

            .vipPrice {
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
}

.bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #ffffff;
  width: 100%;
  box-shadow: 0px -1px 2px 0px rgba(153, 153, 153, 0.15);

  z-index: 10;
}

.bottomBigbox {
  padding: 10px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left {
    display: flex;
    align-items: center;

    .car {
      width: 72rpx;
      position: relative;
      display: flex;
      align-items: center;
    }

    .price {
      margin-left: 10rpx;
      font-size: 14px;
    }

    .yuan {
      font-size: 24px;
    }

    .image {
      width: 36px;
      max-height: 36px;
    }

    .nums {
      position: absolute;
      top: -20%;
      right: -20%;
      background-color: red;
      color: white;
      min-width: 14px;
      height: 14px;
      border-radius: 50px;
      text-align: center;
      line-height: 14px;
      padding: 2px;
      font-size: 12px;
    }
  }

  .right {
    display: flex;
    align-items: center;

    .btnBox {
      padding: 10px 24px;
      border-radius: 32px;
      color: white;
      font-size: 14px;
      background: #0085FF;
    }
  }
}

.price {
  color: #fd2a39;
}

.container {
  height: 100vh;
  position: relative;
}
.oldPrice{
  font-weight: 400;
  font-size: 12px;
  color: #7F7F7F;
  line-height: 18px;
  text-align: left;
  text-decoration-line: line-through
}
</style>