<template>
  <div>
    <div class="area" @scroll="areaScroll">
      <div class="topNav">
        <!--        <div class="left">-->
        <!--          <img-->
        <!--            src="https://picture.bjbjkj.top/small_program_image/logo.png"-->
        <!--            alt=""-->
        <!--            class="logo"-->
        <!--          />-->
        <!--          <img-->
        <!--            src="https://picture.bjbjkj.top/small_program_image/logo_text.png"-->
        <!--            alt=""-->
        <!--            class="logoText"-->
        <!--          />-->
        <!--        </div>-->
      </div>

      <!--      <div class="topImgBack" :style="'background-image:url('+$store.state.imageHost+'/img/home_Mask_group@2x.png)'"></div>-->
      <img :src="$store.state.imageHost+'/img/home_Mask_group@2x.png'" alt="" class="home_Mask_geoup">
      <div class="contioner">
        <!--        <div class="swiperOut">-->
        <!--          <div class="swiper">-->
        <!--            <div class="swiper-wrapper">-->
        <!--              &lt;!&ndash; <div class="swiper-slide"><img src="../../../../public/image/asdasd.png" alt="" class="img"></div> &ndash;&gt;-->
        <!--              <div-->
        <!--                class="swiper-slide"-->
        <!--                :style="`background-image:url(https://picture.bjbjkj.top/tp2024071803001500257731617.png)`"-->
        <!--              ></div>-->
        <!--              &lt;!&ndash; <div class="swiper-slide"><img src="../../../../public/image/asdasd1.png" alt="" class="img"></div> &ndash;&gt;-->
        <!--            </div>-->
        <!--            &lt;!&ndash; 如果需要分页器 &ndash;&gt;-->
        <!--            <div class="pagination">-->
        <!--              <div class="swiper-pagination"></div>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
        <div class="searchBox">
          <span class="iconfont icon-grommet-icons_form-search"></span>
          <span class="placeholder">搜索商品</span>
        </div>
        <div class="card">
          <div class="topFunList">
            <div
                class="fun-item"
                v-for="(item, index) in topFunctionList"
                :key="index"
                @click="nivagationTo(item)"
            >
              <img :src="$store.state.imageHost+item.img" loading="eager" alt="" class="img"/>
              <span class="text">{{ item.name }}</span>
            </div>
          </div>
          <div class="double_Door_Fun">
            <div class="leftFun btn" @click="pointsBtn">
              <div class="left">
                <div style="flex: 1">
                  <div class="title">积分商城</div>
                  <div class="text">
                    兑换精美礼品<br>
                    兑换优质商家服务
                  </div>
                </div>
                <div class="btnG">GO<span class="iconfont icon-Frame-4"></span></div>
              </div>
              <img class="image" :src="$store.state.imageHost+'/img/Frame_18832@2x.png'" alt="">
            </div>
            <div class="rightFun btn" @click="shareBtn">
              <div class="left">
                <div style="flex: 1">
                  <div class="title">邀请好友</div>
                  <div class="text">
                    邀请好友可获取积分
                  </div>
                </div>
                <div class="btnG">GO<span class="iconfont icon-Frame-4"></span></div>
              </div>
              <img class="image" :src="$store.state.imageHost+'/img/nmjuiwvpulkeeszm@2x.png'" alt="">
            </div>
          </div>
        </div>
        <div class="body">
          <div id="content">
            <div class="left" ref="left">
              <div class="item" v-for="(item, index) in leftList" :key="index" @click.stop="goDeatils(item)">
                <img :src="$store.state.imageHost+item.goods.showImg" class="img" alt=""/>
                <div class="bottom">
                  <div class="name">{{ item.goods.name }}</div>
                  <div class="sellNums">月售{{ item.sell_count }}</div>
                  <div class="priceLine">
                    <div class="lef">
                      <div class="nowPrice">
                        <div class="leftUnit">￥</div>
                        <div class="rightPrice">{{ item.goods.sell_price }}</div>
                      </div>
                      <div class="lastPrice">
                        <div class="leftUnit">￥</div>
                        <div class="rightPrice">{{ item.goods.cost_price }}</div>
                      </div>
                    </div>
                    <div class="rightAdd" @click.stop="addCar(item)">

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="right" ref="right">
              <div class="item" v-for="(item, index) in rightList" :key="index" @click="goDeatils(item)">
                <img :src="$store.state.imageHost+item.goods.showImg" class="img" alt=""/>
                <div class="bottom">
                  <div class="name">{{ item.goods.name }}</div>
                  <div class="sellNums">月售{{ item.sell_count }}</div>
                  <div class="priceLine">
                    <div class="lef">
                      <div class="nowPrice">
                        <div class="leftUnit">￥</div>
                        <div class="rightPrice">{{ item.goods.sell_price }}</div>
                      </div>
                      <div class="lastPrice">
                        <div class="leftUnit">￥</div>
                        <div class="rightPrice">{{ item.goods.cost_price }}</div>
                      </div>
                    </div>
                    <div class="rightAdd" @click.stop="addCar(item)">

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="loader-box">
            <div id="loader" v-if="loadLock"></div>
            <div id="nothing" v-if="!loadLock&&!hasMore">没有更多了~</div>
          </div>
        </div>
        <div style="width: 100%; height: 100px"></div>
      </div>
      <tabbar :active="0"></tabbar>
    </div>

  </div>
</template>

<script>
import tabbar from "@/components/common/tabbar/home_tabbar.vue";
// import Swiper from "swiper/bundle";
import "swiper/swiper-bundle.css";
import {_throttle} from "../../../utils/tool.js";
import {addCar, getConfigImage, getPromoteGoodsList} from "@/utils/api";
import Bus from "@/utils/Bus";
// import {Toast} from "vant";

export default {
  components: {
    tabbar,
  },
  props: {},
  data() {
    return {
      swiper: {},
      topFunctionList: [
        {
          name: "百街快修",
          img: "image/repair.png",
          id: 0,
          src: "/service_page",
        },
        // {name:'工程服务',img:'/images/EngineeringService.png',id:10},
        // {name:'保洁消杀',img:'/images/cleaning.png',id:1},
        // {name:'逛商场',img:'/images/tickets.png',id:2},
        // {
        //   name: "建材市场",
        //   img: "materials.png",
        //   id: 3,
        //   src: "/materials_home",
        // },
        // {name:'商铺租赁',image:'/images/shopleasing.png',id:4},
        // {name:'店铺转让',image:'/images/shoptransfer.png',id:5},
        // {name:'闲置转让',image:'/images/idletransfer.png',id:6},
        // {name:'店家招聘',image:'/images/recruitment.png',id:7},
        // {name:'写字楼租赁',image:'/images/officeLeasing.png',id:8},
        {
          name: "百街水站",
          img: "image/water.png",
          id: 11,
          src: "/water",
        },
        {
          name: "百街超市",
          img: "image/supermarket.png",
          id: 12,
          src: "/supmarket",
        },
        // {
        //   name: "房产业务",
        //   img: "housing.png",
        //   id: 9,
        //   src: "/house_home",
        // },
        {
          name: "闲置转让",
          img: "image/materials.png",
          id: 13,
          src: "/idle_home",
        },
        {
          name: "求职招聘",
          img: "image/materials.png",
          id: 14,
          src: this.$store.state.user.job_standing == 2 ? "/invite_talents" : '/invite_home',
        },
      ],
      allGoodsList: [],
      mainMenuList: [],
      leftList: [],
      rightList: [],
      leftHeight: 0,
      rightHeight: 0, //分别定义左右两边的高度

      page:0,
      limit:10,
      hasMore: true,//是否可以继续滚动
      loadLock: true,//是否展示loding
    };
  },
  watch: {},
  computed: {},
  methods: {
    addCar(item){
      console.log(item)
      if (item.goods_type == 2) {//水站
        // addCar(this.$store.state.token, item.id, nums, 1, 1).then(res => {})

      }
      if (item.goods_type == 3) {//超市
        // addCar(this.$store.state.token, item.id, 1, 1, 1)
      }

      if (item.goods_type == 5) {//
        addCar(this.$store.state.token, item.id, 1, 1, 1)

      }
    },
    pointsBtn(){
      console.log('积分商城')
    },
    shareBtn(){
      console.log('邀请好友')
    },
    async getKong() {
//1首页头图 2首页金刚区 3首页轮播图 4室内快修 5超市轮播图 6闲置轮播图 7招聘首页轮播图 8招聘招聘者轮播图 9求职者轮播图 10 其它
      console.log((await getConfigImage(1, this.$store.state.client_type)).data, '1首页头图')
      console.log((await getConfigImage(2, this.$store.state.client_type)).data, '2首页金刚区')
      console.log(await getConfigImage(3, this.$store.state.client_type), '3首页轮播图')
      // Toast(1)
    },
    goDeatils(item) {
      if (item.goods_type == 2) {//水站
        console.log(item)
        this.$router.push({
          path: '/water_details',
          query: {goods: item.goods_id}
        })
      }
      if (item.goods_type == 3) {//超市
        this.$router.push({
          path: '/water_details',
          query: {goods: item.goods_id}
        })
      }
    },
    // 跳转页面
    nivagationTo(item) {
      if (item.src) {
        Bus.$emit("updataUserInfo", '1');
        this.$store.state.user.job_standing
        this.$router.push(item.src);
      }
    },

    areaScroll(event) {
      // const dialog = event.target
      // if(dialog.scrollHeight - dialog.scrollTop === dialog.clientHeight){
      //     console.log('触底');
      // }
      //   console.log(event);
      let scrollTop1 = event.target.scrollTop;
      // 滚动条滚动的距离
      let scrollStep = scrollTop1 - this.oldScrollTop;
      // 更新——滚动前，滚动条距文档顶部的距离
      this.oldScrollTop = scrollTop1;
      //   console.log(scrollStep, this.oldScrollTop);
      if (scrollStep < 0) {
        // console.log("滚动条向上滚动了！");
      } else {
        const {scrollHeight, clientHeight, scrollTop} = event.target;
        // console.log(scrollHeight - (scrollTop + clientHeight));
        if (
            (scrollHeight - (scrollTop + clientHeight)) / clientHeight <=
            0.05
        ) {
          // console.log((scrollHeight - (scrollTop + clientHeight)) / clientHeight);
          if (this.hasMore) {
            this.loadLock = true
            this.qingqiu(this);
          }
        }
      }
    },
    qingqiu() {
      this.fenlei(this);
    },
    fenlei: _throttle(async function (_that) {
      // const { leftList, rightList, mainMenuList, leftHeight, rightHeight } =
      // _that;
      this.page+=1
      getPromoteGoodsList(this.page,this.limit).then(async res => {
        let list = res.data.data
        if(list.length<=0){
          this.hasMore = false
        }
        list = list.sort(function (a, b) {
          return a.sort - b.sort
        })
        list.forEach(item => {
          if (item.good) {
            item.goods = {}
            let image = ''
            if (item.good.images) {
              image = item.good.images
            }
            if (item.good.img) {
              image = item.good.img
            }
            item.goods.showImg = image.split(',')[0]
            // item.goods.images = item.good.images.split(',')[0]
            // cost 市场价  market  成本价   sell  销售
            item.goods.sell_price = item.good.sell_price
            item.goods.market_price = item.good.market_price
            item.goods.cost_price = item.good.cost_price
            item.goods.name = item.good.name
          } else if (item.goods) {
            if (item.goods.img) {
              item.goods.showImg = item.goods.img.split(',')
            }
            if (item.goods.images) {
              item.goods.showImg = item.goods.images.split(',')
            }
            item.goods.cost_price = item.goods.cost_price ? item.goods.cost_price : 0
          }
        })
        this.mainMenuList = list
        console.log(this.mainMenuList)


        for (const item of _that.mainMenuList) {
          console.log("左边", _that.leftHeight, "右边", _that.rightHeight);
          if (_that.leftHeight <= _that.rightHeight) {
            _that.leftList.push(item);
          } else {
            _that.rightList.push(item);
          }
          // leftHeight <= rightHeight ? leftList.push(item) : rightList.push(item); //判断两边高度，来觉得添加到那边
          await _that.getBoxHeight(_that.leftList, _that.rightList, _that);
          // this.$nextTick(async ()=>{
          //   this.$nextTick(async ()=>{
          //   })
          // })
        }
        this.loadLock = false


      }).catch(err => {
        console.log(err)
      })

      // _that.leftList = leftList;
      // _that.rightList = rightList;
      // _that.leftHeight = _that.$refs.left.offsetHeight;
      // _that.rightHeight = _that.$refs.right.offsetHeight;
      // console.log("左边", leftHeight, "右边", rightHeight);
      // console.log(_that);
    }, 500),
    getBoxHeight(leftList, rightList, _this) {
      //获取左右两边高度
      return new Promise((resolve) => {
        _this.$nextTick(() => {
          _this.leftList = leftList;
          _this.rightList = rightList;
          _this.leftHeight = _this.$refs.left.offsetHeight;
          _this.rightHeight = _this.$refs.right.offsetHeight;
          // console.log("左边", _this.leftHeight, "右边", _this.rightHeight);
        });

        resolve();
        // this.setData(
        //   {
        //     leftList,
        //     rightList,
        //   },
        //   () => {
        //     query.select("#left").boundingClientRect();
        //     query.select("#right").boundingClientRect();
        //     query.exec((res) => {
        //       setTimeout(() => {
        //         leftHeight = res[0].height; //获取左边列表的高度
        //         rightHeight = res[1].height; //获取右边列表的高度
        //         console.log("左边", leftHeight, "右边", rightHeight);
        //         resolve();
        //       }, 200);
        //     });
        //   }
        // );
      });
    },
  },
  created() {
    this.qingqiu();
  },
  beforeDestroy() {
    Bus.$off("sendUserInfo");
  },
  mounted() {
    // this.swiper = new Swiper(".swiper", {
    //   pagination: {
    //     el: ".swiper-pagination",
    //     type: "fraction",
    //   },
    //   loop: false,
    //   autoplay: {
    //     delay: 3000,
    //     disableOnInteraction: false,
    //   },
    // });

    this.imgsArr = this.mainMenuList;
    // let self = this;
    // //渲染列 列表,根据如的col生成对应列数,并置为空的二维数组
    // for (let i = 0; i < this.col; i++) {
    //   this.renderList[i] = []
    // }
    // //请求首次数据:
    // this.getDataList();
    // //监听滚动事件
    window.onscroll = function (e) {
      e;
      // console.log(e);
      //   //监测触底
      //   //瀑布流高度 + 瀑布流的相对top < 可视区高度+滚动距离 ==触底
      //   //获取到瀑布流盒子
      //   let box = self.$refs.fallbox;
      //   //获取到盒子相对于文档的位置
      //   let top = self.computedOffset(box, 'offsetTop');
      //   let height = box.offsetHeight;
      //   //可视区高度
      //   let clientHeight = document.documentElement.clientHeight;
      //   //滚动距离
      //   let scrollTop = document.documentElement.scrollTop;
      //   if (top + height < clientHeight + scrollTop + 50 && !self.lock && !self.rendering) {
      //     //触底判断,50用于提前触发,不用完全到底才触发
      //     // 触底成功
      //     // self.lock = true;
      //     // self.getDataList();
      //   }
    };
    // // this.fullData(0);
    this.getKong()
  },
  beforeUnmount() {
    // //取消滚动事件，重要，否则路由跳转后执行scroll事件将会有一堆的undefined
    // window.onscroll = null;
    // //滚动条置顶，否则路由跳转后滚动条的位置没有变化
    // document.documentElement.scrollTop = 0;
  },
  unmounted() {
  },
};
</script>
<style lang="scss" scoped>
.card{
  background: #fff;
  margin: 0 10px;
  border-radius: 10px;
}
.double_Door_Fun{
  display: flex;
  margin: 10px 0px 0 0;
  justify-content: space-between;
  .btn{
    background: #fff;
    display: flex;
    margin: 0 10px;
    padding: 10px;
    border-radius: 10px;
    text-align: left;
    flex: 1;
    .left{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .title{
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
    }
    .text{
      font-weight: 400;
      font-size: 10px;
      line-height: 14px;
    }
    .btnG{
      width: 38px;
      display: inline-block;
      place-content: flex-start;
      place-items: center;
      gap: 2px;
      flex-shrink: 0;
      padding: 0px 4px;
      border-radius: 16px;
      color: #ffffff;
      font-weight: 500;
      font-size: 12px;
      line-height: 17px;
      background: #0085ff;
      margin: 5px 0 0 0;
      vertical-align: middle;
      .iconfont{
        vertical-align: middle;
      }
    }
    .image{
      height: 55px;
    }
  }
  .leftFun{
    border: 1px solid #ffffff;
    background: linear-gradient(180deg, #fefef2 0%, #ffffff 17.05%);
    box-shadow: 0px 0px 4.2px #f5fffa;
    .title{
      color: #ce7800;
    }
    .text{
      color: #D8863E;
    }
    .btnG{
      background: #d8863e;
    }
  }
  .rightFun {
    border: 1px solid #ffffff;
    background: linear-gradient(180deg, #fef2f6 0%, #ffffff 17.05%);
    box-shadow: 0px 0px 4.2px #f5fffa;
    .title{
      color: #F15258;
    }
    .text{
      color: #FF7275;
    }
    .btnG{
      background: #FF7779;
    }
  }
}
.searchBox {
  border-radius: 10px;
  border: 0.5px solid #ffffff;
  box-sizing: border-box;
  background: #ffffffb3;
  margin: 0 10px 8px 10px;
  color: #8F8F8F;
  text-align: left;
  padding: 4px 0 4px 15px;

  .placeholder {
    color: #8f8f8f;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
  }

  .iconfont {
    font-size: 20px;
    vertical-align: middle;
    margin-right: 10px;
  }
}

.area {
  height: 100vh;
  background-color: #F5F5F5;
}

.swiper-slide {
  // background: red;
  height: 160px;
  background-size: cover;
  background-position: center center;
}

.swiper {
  margin: 10px 16px;
  overflow: hidden;
  position: relative;
  border-radius: 10px;

  .swiper-slide {
    max-height: 180px;
  }

  .img {
    width: 100%;
  }

  // 分页器
  /* 两种都可以 */
  .swiper-pagination-total {
    font-size: 12px;
  }

  .swiper-pagination {
    background: rgba(51, 51, 51, 0.75);
    width: auto;
    // right: 10px;
    border-radius: 64px;
    padding: 0 5px;
    color: white;
    line-height: 24px;
    font-size: 13px;
  }

  // 分页器
  .pagination {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 50px;
    height: 10px;
    z-index: 3;
    margin: 0;
  }
}

.topNav {
  height: 77px;
  //background: linear-gradient(180deg, #ffefe7, #fff4f0) !important;
  background: transparent;
  position: relative;
  z-index: 10;
  display: flex;
  padding: 0 16px;
  justify-content: center;

  .left {
    display: flex;
    align-items: center;
  }

  .logo {
    width: 24px;
  }

  .logoText {
    width: 58px;
    margin-left: 10px;
  }
}

.topImgBack {
  position: absolute;
  top: 0;
  left: 0;
  height: 180px;
  //background: linear-gradient(180deg, #ffefe7, #ffffff) !important;
  width: 100%;
  z-index: 1;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 100% 100%;
}

.home_Mask_geoup {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  object-fit: cover;
}

.contioner {
  position: relative;
  z-index: 4;
  margin-top: 30px;
}

.topFunList {
  display: flex;
  align-items: center;
  margin: 4px 10px;
  justify-content: space-between;
  flex-shrink: 0;
  border-radius: 10px;
  background: #ffffff;
  padding: 10px 0;

  .fun-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 25%;

    .text {
      white-space: nowrap;
      font-size: 12px;
      // font-size: .8rem;
    }
  }

  .img {
    width: 40px;
  }
}

.body {
  position: relative;
  width: 100%;
  // height: 100vh;
  background: #f6f6f6;

  #content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 0 10px;
    background:#F5F5F5 ;
    padding-top: 10px;
  }

  .left,
  .right {
    width: 48.5%;
    margin: 0%;

    .img {
      width: 100%;
      //background-color: #fff;
      min-height: 100px;
    }
  }

  .item {
    margin-bottom: 10px;
    border-radius: 10px;
    overflow: hidden;
    background-color: #fff;
    /* box-shadow: 0px 0px 2px 0px rgba(102,102,102,0.25); */
    display: flex;
    flex-direction: column;
    text-align: left;

    .bottom {
      padding: 6px 10px;

      .unit {
        font-size: 10px;
      }

      .price {
        color: #FC223B;
        font-size: 18px;
      }

      .priceLine {
        display: flex;
        color: #FC223B;
        align-items: center;
        width: 100%;
        margin-top: 6px;
        .lef{
          display: flex;
          align-items: baseline;
          width: 80%;
        }
        .rightAdd{
          display: flex;
          place-content: center;
          place-items: center;
          gap: 10px;
          flex-shrink: 0;
          padding: 12px;
          border-radius: 10px;
          background: #fd2a39;
          position: relative;
        }
        .rightAdd:before,.rightAdd:after{
          position: absolute;
          display: inline-block;
          content: "";
          background: #fff;
          transform: translate(-50%,-50%);
          left: 50%;
          top: 50%;
        }
        .rightAdd:after{
          width: 10%;
          height: 50%;
          border-radius: 10px;
        }
        .rightAdd:before{
          width: 50%;
          height: 10%;
          border-radius: 10px;
        }
        .nowPrice,
        .lastPrice {
          display: flex;
          align-items: baseline;

          .leftUnit,
          .rightPrice {
            font-size: 12px;
            color: #7f7f7f;
          }
        }

        .leftUnit {
          font-size: 10px;
          line-height: 10px;
        }

        .rightPrice {
          font-weight: 400;
          width: 95%;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .nowPrice {
          .rightPrice {
            font-size: 18px;
          }

          .leftUnit,
          .rightPrice {
            color: #FC223B !important;
          }
        }
      }

      .nowPrice {
        max-width: 70%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .lastPrice {
        text-decoration: line-through;
        color: #7f7f7f;
      }
    }

    .name {
      display: -webkit-box;
      /* 必须结合的属性 ，设置或检索伸缩盒对象的子元素的排列方式 。*/
      -webkit-box-orient: vertical;
      /*要显示的行数*/
      -webkit-line-clamp: 2;
      /* 溢出部分隐藏 */
      overflow: hidden;
      font-size: 14px;
      color: #1b1b1b;
    }

    .sellNums {
      line-height: 18px;
      font-size: 12px;
      color: #7f7f7f;
    }
  }
}

.area {
  overflow: scroll;
}

@media (min-width: 768px) {
  .area {
    width: 750px;
    margin: 0 auto;
  }
}

#content {
  // position: absolute;
  // top: 0px;
  // bottom: 0;
  // left: 50%;
  // width: 90%;
  // transform: translate(-50%, 0);
  // padding-bottom: 20px;
}

.waterfall {
  height: 800px;
}

.info {
  text-align: center;
}
</style>