<script>
import navigationBar from "@/components/navigation-bar/navigation-bar.vue";
import {
  orderDetails,
  refund_cart_info,
  refund_reason,
  refundApply,
  upload_file,
  upload_files
} from "@/utils/api";
import {Toast} from "vant";
import Compressor from "compressorjs";

export default {
  name: "goods_return_list",
  data() {
    return {
      list: [],
      result: [],

      type: '',
      reasonText: '',
      textarea: '',


      headers: {"Content-Type": "multipart/form-data"},
      fileType: ["png", "jpg", "jpeg", 'mp4', 'mp3'],
      yanshouload: [],
      yanshoufileList: [],//照片 files
      yanshouLimit: 5,//照片最大数量
      userYanshouValue: '',//整改提交问题


      returnGoods:['仅退款(无需退货)', '退货退款(快递退回)','退货退款(到店退货)'],
      reason: [],
      popshow:false,
      returnGoodsValue:'',
      returnGoodsIndex:-1,
      reasonValue:'',
      popType:'',
    }
  },
  components: {navigationBar},
  watch:{
    type(n){
      this.returnGoodsIndex = this.returnGoods.findIndex(item=>{return item == n})
    }
  },
  methods: {


    async onsubmit(){


      if(this.result.length <=0){
        Toast('请选择退货商品')
        return
      }
      if(!this.type){
        Toast('请选择申请类型')
        return;
      }
      if(!this.reasonText){
        Toast('请选择售后原因')
        return;
      }
      let cart_ids1 = this.result.map(item=>{return {cart_id:item,cart_num:0,}})
      let cart_ids = []
      let refund_price = 0
      this.list.forEach(item=>{
        if(cart_ids1.find(iteme=>{return iteme.cart_id == item.id})){
          if(item.id == cart_ids1.find(iteme=>{return iteme.cart_id == item.id}).cart_id){
            cart_ids1.find(iteme=>{return iteme.cart_id == item.id}).cart_num = Number(item.nums)
            cart_ids.push({cart_id:item.cart_id,cart_num:Number(item.nums),})
            refund_price += (item.nums*item.cart_info.truePrice)
          }
        }
      })
      let data = {
        cart_ids,
        refund_price,
        refund_reason_wap_explain:this.textarea,
        refund_reason_wap_img:String(this.yanshouloadsrcList),
        refund_type:parseInt(this.returnGoodsIndex)+1,
        text:this.reasonText,
        uni:this.obj.order_id,
      }

      refundApply(this.obj.id,data).then(res => {
        if(res.data.status == 200){
          console.log(res)
          Toast('申请成功')
          this.$router.go(-1)
        }else{
          Toast(res.data.msg)
        }
      })




    },
    openPop(e){
      this.popshow = true
      this.popType = e
    },
    getList() {
      refund_cart_info(this.$route.query.id).then(res => {
        let list = res.data.data, goods = []
        list.forEach((item) => {
          item.checked = false
          item.numShow = item.surplus_num
          item.nums = item.numShow
          if (!item.is_gift) {
            goods.push(item)
          }
        })
        this.list = goods
        console.log(this.list)
      })
    },

    limitMaxValue(value, max, index) {
      if (value > max) {
        this.list[index].nums = max
      }
      // if(value <= 1){
      //   this.list[index].nums = 1
      // }
    },

    getReason() {
      refund_reason().then(res => {
        if (res.data.status == 200) {
          this.reason = res.data.data
        }
      })
    },


    deleteImg(list, index, fileList) {
      list.splice(index, 1)
      fileList.splice(index, 1)
    },


    yasuo(files) {
      return new Promise(resolve => {
        if (files.type.startsWith('image/')) {
          new Compressor(files, {
            success: (result) => {
              resolve(result)
              // this.uploadImage(result);
            },
            error: (error) => {
              console.log(error.message);
            },
            // 可选的压缩选项
            quality: 0.7, // 图片压缩质量
            maxWidth: 1024, // 图片最大宽度
            maxHeight: 1024, // 图片最大高度
            resize: true, // 是否改变图片大小
          });
        } else if (files.type.startsWith('video/')) {
          // 压缩视频
          // this.compressVideo(file); // 视频压缩和上传通常需要服务器端支持
          resolve(files)

        }


      })
    },
    async beforeUpload(files) {
      console.log('bef')

      let file = await this.yasuo(files)
      if (file.type != "" || file.type != null || file.type != undefined) {
        //截取文件的后缀，判断文件类型
        const FileExt = file.name.replace(/.+\./, "").toLowerCase();
        if (files.type.startsWith('image/')) {   //图片
          //计算文件的大小
          const isLt5M = file.size / 1024 / 1024 < 1; //这里做文件大小限制
          //如果大于50M
          if (!isLt5M) {
            this.$message.error("上传文件大小不能超过 1MB!");
            return false;
          }
        } else if (files.type.startsWith('video/')) {//视频
          const isLt5M = file.size / 1024 / 1024 < 5; //这里做文件大小限制
          //如果大于50M
          if (!isLt5M) {
            this.$message.error("上传视频大小不能超过 5MB!");
            return false;
          }
        }
        //如果文件类型不在允许上传的范围内
        if (this.fileType.includes(FileExt)) {
          return true;
        } else {
          this.$message.error("上传文件格式不正确!");
          return false;
        }
      }
    },
    uploadFile(item) {
      // console.log(JSON.stringify(item))
      this.$message("文件上传中........");
      if (item.file.type.startsWith('image/')) {
        console.log('图片')
        console.log(item.file)
        upload_file(item.file).then(res => {
          if (res.data.status == true) {
            this.$message.close()
            this.$message.success("上传成功");
            if (item.filename == 22) {
              this.yanshouload.push({tempFilePath: res.data.data.saveurl, fileType: 'image'})
            }
            // this.userUpload.push({tempFilePath:res.data.data.saveurl,fileType:'image'})
          }
        })
      } else if (item.file.type.startsWith('video/')) {
        upload_files(item.file).then(res => {
          if (res.data.status == true) {
            this.$message.close()
            this.$message.success("上传成功");
            if (item.filename == 11) {
              this.kanchaUpload.push({tempFilePath: res.data.data.saveurl, fileType: 'video'})
            }
            if (item.filename == 22) {
              this.DoorUpload.push({tempFilePath: res.data.data.saveurl, fileType: 'video'})
            }
            console.log(res.data.data)
            // this.userUpload
            // this.userUpload.push({tempFilePath:res.data.data.saveurl,fileType:'video'})
          }
        })
      }
    },
    //上传成功后的回调
    handleSuccess() {
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.handleClose();
    },
    fileChange2(file, yanshoufileList) {
      console.log('change')
      this.yanshoufileList = yanshoufileList;
    },
    async handleRemove() {
    },
    handleClose(i) {
      this.yanshoufileList.splice(i, 1); //删除上传的文件
    },
    //超出文件个数的回调
    handleExceed() {
      this.$message({
        type: "warning",
        message: "超出最大上传文件数量的限制！",
      });
      return;
    },

    change(){
      this.popshow = false
    },
    popClose(){
      this.popType = ''
    },
  },
  async mounted() {
    const {data} = await orderDetails(this.$route.query.order_id)
    this.obj = data.data
    if (data.data.id == this.$route.query.id) {
      this.getList()
    } else {
      Toast('缺少订单id,无法选择商品')
    }

    this.getReason()

  },
  computed:{
    yanshouloadsrcList() {
      let list = []
      JSON.parse(JSON.stringify(this.yanshouload)).forEach(item => {
        list.push(this.$store.state.imageHost + item.tempFilePath)
      })
      return list
    },
  },
}
</script>

<template>
  <div class="container">
    <div class="area">
      <navigationBar :title="'申请售后'" class="navigation"></navigationBar>
<!--      <div class="title">请选择售后商品和数量</div>-->
      <div class="splitOrder">
        <div class="every" v-for="(item,index) in list" :key="index">
          <div class="img">
            <img :src="item.cart_info.productInfo.image" class="image" alt="">
          </div>
          <div class="center">
            <div class="name">{{ item.cart_info.productInfo.store_name }}</div>
            <div class="nums">
              <div class="leftNums">购买:{{ item.numShow }}</div>
              <div class="rightInput">
                售后：<input type="number"
                            @input="limitMaxValue($event.target.value, item.numShow,index)"
                            v-model="item.nums" class="input">
              </div>
            </div>
          </div>
          <van-checkbox-group v-model="result" class="group">
            <van-checkbox :name="item.id"></van-checkbox>
          </van-checkbox-group>
        </div>
      </div>

      <div class="line" @click="openPop('type')">
        <div class="label">申请类型</div>
        <div class="value" :class="type?'':'placeholder'">{{ type ? type : '请选择申请类型' }}</div>
        <span class="iconfont icon-Frame-4"></span>
      </div>
      <div class="line" @click="openPop('reasonText')">
        <div class="label">售后原因</div>
        <div class="value" :class="reasonText?'':'placeholder'">{{ reasonText ? reasonText : '请选择售后原因' }}</div>
        <span class="iconfont icon-Frame-4"></span>
      </div>
      <div class="textareaBox">
        <div class="topLabel">
          <div class="label">问题描述</div>
          <div class="rightR">还可以输入{{ 170 - textarea.length }}字</div>
        </div>
        <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入内容"
            resize="none"
            class="textarea"
            aria-placeholder="必填，请您详细填写申请说明"
            maxlength="170"
            v-model="textarea">
        </el-input>
      </div>


      <div class="img_box">
        <div class="img_list">
          <div class="evert li" v-for="(item,index) in yanshouload" :key="index+index">
            <el-image
                class="avatar"
                fit="cover"
                :src="$store.state.imageHost+item.tempFilePath"
                :preview-src-list="yanshouloadsrcList">
            </el-image>
            <van-icon name="clear" class="icon" @click.stop="deleteImg(yanshouload,index,yanshoufileList)"/>
          </div>
          <el-upload
              v-if="yanshouload.length<=4"
              class="avatar-uploader"
              action
              :http-request="uploadFile"
              ref="upload"
              :limit="yanshouLimit"
              :on-remove="handleRemove"
              :file-list="yanshoufileList"
              :on-exceed="handleExceed"
              :before-upload="beforeUpload"
              :show-file-list="false"
              :headers="headers"
              :on-change="fileChange2"
              multiple
              name="22"
          >
            <i class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </div>
      </div>


      <div class="btottomBox">
        <div class="btn" @click="onsubmit">提交</div>
      </div>


      <van-popup v-model="popshow" round closeable position="bottom" class="popup" @closed="popClose">
        <van-radio-group v-model="type" v-if="popType=='type'" @change="change">
          <van-cell-group>
            <van-cell v-for="(item,index) in returnGoods" :key="index" :title="item" clickable @click="type=item">
              <template #right-icon>
                <van-radio :name="item" />
              </template>
            </van-cell>
          </van-cell-group>
        </van-radio-group>
        <van-radio-group v-model="reasonText" v-if="popType=='reasonText'" @change="change">
          <van-cell-group>
            <van-cell v-for="(item,index) in reason" :key="index" :title="item" clickable @click="reasonText=item">
              <template #right-icon>
                <van-radio :name="item" />
              </template>
            </van-cell>
          </van-cell-group>
        </van-radio-group>
      </van-popup>
    </div>
  </div>
</template>

<style scoped lang="scss">
.popup {
  padding-top: 40px;
  background-color: #fff!important;
  text-align: left;
}
.title{
  text-align: left;
}
.splitOrder {
  background-color: #fff;
  border-radius: 9px;
  margin: 13px auto 0 auto;
  padding: 18px 14px;

  .every {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    box-shadow: 0px 0px 2px #66666640;
    padding: 10px;
    border-radius: 10px;

    .group {
      padding-right: 15px;
    }

    .img {
      .image {
        width: 77px;
        height: 77px;
      }
    }

    .center {
      width: 65%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-align: left;
      padding-left: 15px;
      height: 77px;

      .name {
        color: #1b1b1b;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
      }

      .nums {
        display: flex;
        justify-content: space-between;

        .leftNums {
          color: #666666;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
        }

        .input {
          width: 51px;
          height: 24px;
          border-radius: 20px;
          border: 1px solid var(--线框, #d9d9d9);
          box-sizing: border-box;
          text-align: center;
          color: #666666;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
        }
      }
    }
  }

}

.placeholder {
  color: var(--999999, #999999);
  font-weight: 400;
  line-height: 24px;
}

.line {
  display: flex;
  margin: 10px 16px;
  padding-top: 10px;
  border-top: 1px solid #99999926;

  .label {
    //width: 25%;
    width: 90px;
    max-width: 90px;
    min-width: 90px;
    text-align: left;
    color: #666666;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px
  }

  .value {
    width: 80%;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .iconfont {
    font-size: 24px;
  }
}

.textareaBox {
  border-top: 1px solid #99999926;
  padding-top: 10px;
  text-align: left;
  margin: 10px 16px;
  border-top: 1px solid #99999926;

  .topLabel {
    display: flex;
    justify-content: space-between;

    .rightR {
      color: #666666;
      font-weight: 400;
      font-size: 13px;
      line-height: 24px;
    }
  }

  .label {
    color: #666666;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px
  }

  .textarea {
    margin-top: 5px;
    font-size: 16px;
  }
}

.line:nth-last-of-type(1) {
  border-bottom: 1px solid #99999926;
  padding-bottom: 10px;
}

.img_box {
  margin-top: 10px;
  margin-left: 16px;

  .title {
    text-align: left;
    font-size: 12px;
    color: #999999;
  }

  .img_list {
    margin-top: 4px;
    display: flex;

    .li {
      width: 65px;
      height: 65px;
      margin-right: 5px;
      position: relative;
      border-radius: 10px;

      .avatar {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
      }

      .icon {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(20%, -50%);
        font-size: 20px;
      }
    }

    .addImg {
      border: 1px dashed #999999;
      border-radius: 10px;
      position: relative;

    }

    .addImg:after,
    .addImg:before {
      position: absolute;
      content: '';
      display: block;
      width: 30%;
      height: 2px;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
      background-color: #a6a6a6;
    }

    .addImg:before {
      width: 2px;
      height: 30%;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
      background-color: #a6a6a6;
    }
  }

}

.area {
  padding-bottom: 100px;
}

.btottomBox {
  background-color: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  .btn {
    border-radius: 10px;
    background: #fc223b;
    margin: 20px 16px;
    padding: 14px;
    color: #fff;
  }
}
</style>