!
<template>
  <div>
    <div class="container">
      <div class="area">
        <div class="topNav" :style="'margin-bottom:'+($store.state.token?'50px':'0px')">
          <div class="topInfo" :class="!$store.state.token?'nologinInfo':''">
            <div class="userInfo" >
              <div class="leftImg" v-if="$store.state.token">
                <!-- <img class="avatar" src="https://picture.bjbjkj.top/tp2024062803111511572202305.png" alt="" /> -->
                <el-image
                    v-if="$store.state.user.avatar&&$store.state.user.avatar"
                    class='avatar'
                    :src="$store.state.imageHost+$store.state.user.avatar"
                    :preview-src-list="[$store.state.imageHost+$store.state.user.avatar]">
                </el-image>
              </div>
              <div class="rightInfo" v-if="$store.state.token">
                <div class="username"><span class="text">{{ $store.state.user.nickname }}</span><img class="vip_tag" :src="$store.state.imageHost+'/img/vip_tag.png'" alt=""></div>
                <div class="mobile">{{ $store.state.user.mobile }}</div>
              </div>
              <div class="noLogin" v-if="!$store.state.token" @click="gologin">
                 <img class="avatar" :src="$store.state.imageHost+'/touxiang/7fc545aedbedcaf7f6ef03cea3ef206.png'" alt="" />
                请登录~
              </div>
            </div>
            <div v-if="$store.state.token"
                 style="text-align: right; width: 10%; min-width: 30px;display:flex;align-items: center;"
                 @click='setUserInfo'>
              <van-icon name="setting-o"/>
            </div>
          </div>
          <div class="outVip" v-if="$store.state.token">
            <div class="vipBox" @click="goVip">
              <div class="left">
                <div class="top">
                  <img class="img" :src="$store.state.imageHost+'/image/Group%203474%402x.png'" alt=""/><span
                    class="text">尊享权益</span>
                </div>
                <div class="bottom" v-if="$store.state.is_vip?$store.state.is_vip.is_vip:false">到期时间：{{vipTime($store.state.vipTime)}}</div>
                <div class="bottom" v-else>SVIP会员 | 开通畅享会员权益</div>
              </div>
              <div class="rightBtn" v-if="!$store.state.is_vip.is_vip"><img class="img" :src="$store.state.imageHost+'/image/Frame%2018785%402x.png'"
                                         alt="">去开通<span class="iconfont icon-Frame-13"></span></div>
            </div>
          </div>
        </div>
        <div class="box">
          <div class="innerarea innerareaOne">
            <div class="title">
              <div class="left">我的订单</div>
              <div class="right" @click='checkAll'>
                查看全部<span class="iconfont icon-Frame-4"></span>
              </div>
            </div>
            <div class="body">
              <div class="ul">
                <div class="li" v-for="(item, index) in orderFun" :key="index" @click='checkOrder(item)'>
                  <img class="img" :src="item.img" alt=""/>
                  <div class="text">{{ item.text }}</div>
                  <div class="tagNums" v-if="item.nums&&item.nums>0">{{item.nums}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="innerarea innerareaOne">
            <div class="lunbo" @click='checkSetting'>
              <div class="left">
                <span class="iconfont icon-a-Frame3434"></span>
                您有一个室内快修订单在进行中...
              </div>
              <div class="right">
                去查看<span class="iconfont icon-Frame-4"></span>
              </div>
            </div>
          </div>
          <div class="innerarea innerareaTwo">
            <div class="lunbo">
              <div class="left">
                <img :src="$store.state.imageHost+'/img/my_point.png'" class="img_my_Point" alt="">
                我的积分：0
              </div>
              <div class="right">
                获取积分<span class="iconfont icon-Frame-13"></span>
              </div>
            </div>
          </div>
        </div>
        <div class="otherOut">
          <div class="title">
            <div class="left">其他功能</div>
          </div>
          <div class="body">
            <div class="otherUl">
<!--              displayNone-->
             <div class="out" v-for="(item, index) in otherFun" :key="index" @click='bodyFun(item)' :class="(item.id != 9||($store.state.user&&($store.state.user.user_authority!=10&&item.id == 9)))?'':'displayNone'">
               <div class="li" v-if="item.id != 9||($store.state.user&&($store.state.user.user_authority!=10&&item.id == 9))">
                 <img class="img" :src="item.image" alt=""/>
                 <div class="text">{{ item.text }}</div>
               </div>
             </div>
            </div>
          </div>
        </div>
        <footer class="copyright">
          <p>Copyright © 2024 </p>
          <p>北京淘百街科技有限公司</p>
          <a href="http://beian.miit.gov.cn/publish/query/indexFirst.action" target="_blank">ICP备案/许可证号：京ICP备2023016119号</a>
        </footer>
        <tabbar :active="4"></tabbar>
      </div>
    </div>
  </div>
</template>

<script>
import tabbar from "@/components/common/tabbar/home_tabbar.vue";
import Bus from "@/utils/Bus";
import {Toast} from "vant";


export default {
  components: {
    tabbar,
  },
  props: {},
  data() {
    return {
      orderFun: [
        {img: require('/public/image/weizhifu.png'), text: "待付款", id: 1,},
        {img: require('/public/image/weijiedan.png'), text: "待发货", id: 2,},
        {img: require('/public/image/yijiedan.png'), text: "待收货", id: 3,},
        {img: require('/public/image/yiwancheng.png'), text: "待评价", id: 4,},
        {img: require('/public/image/tuikuanshouhou.png'), text: "售后", id: 5,},
      ],
      otherFun: [
        {
          text: "购买空桶",
          id: 1,
          image: require('/public/image/Empty.png'),
          src:'buyPail'
        },
        {
          text: "退桶押金",
          src: 'exitPail',
          image: require('/public/image/deposit.png'),
          id: 2,
        },
        // {
        //   text: "我的钱包",
        //   src: 'myWallet',
        //   image: require('/public/image/purse.png'),
        //   id: 3,
        // },
        // {
        //   text: "我的店铺",
        //   id: 4, image: require('/public/image/myshop.png'),
        // },
        {
          text: "百街水票",
          src: 'water_coupon',
          id: 5, image: require('/public/image/ticket.png'),

        },
        {
          text: "用户地址",
          src: 'userAddress',
          id: 6, image: require('/public/image/information.png'),

        },
        {
          text: "发票管理",
          src: 'invoiceManagement',
          id: 7, image: require('/public/image/Invoice.png'),

        },
        {
          text: "用户协议",
          src: 'agreement',
          id: 10, image: require('/public/image/userProtocol.png'),

        },
        {
          text: "隐私协议",
          src: 'agreement',
          id: 11, image: require('/public/image/agreement.png'),

        },
        {
          text: "联系客服",
          id: 8, image: require('/public/image/kf.png'),

        },
        // {
        //   text: "员工申请",
        //   id:9,          image: require('/public/image/Empty.png'),

        // },
        {
          text: "员工登录",
          id: 9, image: require('/public/image/Employee2.png'),
          src: 'employee_home'

        },

        {
          text: "问题反馈",
          src: '/problem_feedback',
          id: 13, image: require('/public/image/agreement.png'),
        },
      ],
      userInfo: {},

    };
  },
  watch: {},
  computed: {

  },
  methods: {
    vipTime() {
      let data = new Date(this.$store.state.vipTime * 1000)
      let y = data.getFullYear()
      let m = data.getMonth() + 1
      let d = data.getDate()
      m = m < 10 ? '0' + m : m
      d = d < 10 ? '0' + d : d
      return this.$store.state.vipTime ? y + '-' + m + '-' + d : 0
    },
    goVip() {
      if(this.$store.state.user&&this.$store.state.token){
        this.$router.push({path: 'vip_page'})
      }else{
        Toast('请登录')
      }
    },
    gologin() {
      this.$router.push({name: '登录'})
    },
    setUserInfo() {
      // console.log('设置用户信息')
      this.$router.push({
        path: '/userInfo'
      })
    },
    checkAll() {
      this.$router.push({
        name: "order_list",
        params: {id: '0',},
      })
    },
    checkOrder(item) {
      if(!this.$store.state.token){
        Toast('请登录')
        return
      }
      this.$router.push({
        name: "order_list",
        params: {id: item.id},
      })
    },
    checkSetting() {
      let obj = {
        title: "正在快修",
        type: 1,
      };
      this.$router.push({
        path: "/service_order",
        query: {title:obj.title,type:1},
      });
    },
    bodyFun(item) {
      // if(!this.$store.state.token){
      //   Toast('请登录')
      //   return
      // }
      if (item.src) {
        if(item.id == 10||item.id == 11){
          window.open(this.$router.resolve({path: item.src,query:{id:item.id}}).href, '_blank');
        }else{
          this.$router.push({path: item.src})
        }
      }
    },
    initUserAddress(){
      Bus.$on("sendUserInfo", (msg) => {

        console.log(msg)

        console.log(msg.orderStatusNum)
        // {img: require('/public/image/weizhifu.png'), text: "待付款", id: 1,},
        // {img: require('/public/image/weijiedan.png'), text: "待发货", id: 2,},
        // {img: require('/public/image/yijiedan.png'), text: "待收货", id: 3,},
        // {img: require('/public/image/yiwancheng.png'), text: "待评价", id: 4,},
        // {img: require('/public/image/tuikuanshouhou.png'), text: "售后", id: 5,},

        this.orderFun[0].nums = msg.orderStatusNum.unpaid_count
        this.orderFun[1].nums = msg.orderStatusNum.unshipped_count
        this.orderFun[2].nums = msg.orderStatusNum.received_count
        this.orderFun[3].nums = msg.orderStatusNum.evaluated_count
        this.orderFun[4].nums = msg.orderStatusNum.refund_count
        // unpaid_count // 待付款
        // unshipped_count // 代发货
        // received_count // 待收货
        // evaluated_count // 待评价
        // refund_count // 售后/退款 总数量
        // refunded_count // 售后/退款 确定的
        // refunding_count // 售后/退款 正在售后的

        // ali_pay_status:true
        // complete_count:"0"
        // evaluated_count:"0"
        // order_count:"14"
        // pay_weixin_open:1
        // received_count:"2"
        // refund_count:"0"
        // refunded_count:"0"
        // refunding_count:"0"
        // sum_price:"873"
        // unpaid_count:"0"
        // unshipped_count:"12"
        // unwritoff_count:"0"
        // yue_pay_status:1
      })
    },
  },
  created() {
  },
  mounted() {
    Bus.$emit("updataUserInfo", '1');
    this.userInfo = this.$store.state.user
    if (this.$store.state.token) {
      this.initUserAddress()
    }
  },
  beforeDestroy() {
    Bus.$off("sendUserInfo");
  }
};
</script>
<style lang="scss" scoped>
.copyright{
  margin-top: 30px;
  color: #666666;
  a{
    color: #666666;
  }
}
.area {
  position: relative;
  background: #f4f6fa;
  padding-bottom: 100px;
}

.topNav {
  top: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(110deg, #fff8f2, #ffecec, #f4f6fa);
  z-index: 2;
  padding-top: 20px;
  position: relative;
  margin-bottom: 50px;
}

.box {
  margin: 0 16px;
  position: relative;
  z-index: 3;
}

.topInfo {
  margin: 0 16px;
  display: flex;
  align-items: center;
  padding-bottom: 50px;

  .userInfo {
    width: 90%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    .rightInfo {
      width: 75%;
      text-align: left;
      padding-left: 10px;

      .username {
        font-weight: 500;
        font-size: 18px;
        color: #1b1b1b;
        line-height: 24px;
        display: flex;
        align-items: center;
        width: 100%;
        .text{
          display: inline-block;
          max-width: 90%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          padding-right: 5px;
        }
        .vip_tag{
          width: 24px;
          height: 24px;
          object-fit: cover;
        }
      }

      .mobile {
        font-weight: 400;
        font-size: 14px;
        color: #1b1b1b;
        line-height: 18px;
        margin-top: 6px
      }
    }

    .noLogin {
      .avatar{
        border-radius: 50%;
        width: 54px;
        height: 54px;
        object-fit: cover;
      }
      display: flex;
      align-items: center;
      text-indent: 1em;
      line-height: 40px;
    }

    .leftImg {
      display: flex;
    }
  }

  .avatar {
    width: 54px;
    height: 54px;
    border-radius: 10px;
    object-fit: cover;
    // object-fit: fill; // 填充，撑满
    // object-fit: contain; // 保持原比例缩小
    // object-fit: cover;
    // object-fit: none;
    // object-fit: scale-down;
  }
}
.nologinInfo{
  padding-bottom: 20px;
}
.outVip {
  position: absolute;
  width: calc(100% - 52px);
  transform: translate(-50%, -50%);
  left: 50%;
  background: linear-gradient(90deg, #FEE1A9 0%, #FFCE7B 100%);
  border-radius: 10px 10px 10px 10px;
  // bottom: 50%;
  .vipBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;

    .left {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding-left: 10px;

      .top {
        .img {
          width: 46px;
          height: 16px;
          margin-right: 5px;
          vertical-align: middle;
        }

        .text {
          font-weight: 500;
          font-size: 14px;
          color: #f5a078;
          line-height: 18px;
        }
      }

      .bottom {
        font-weight: 400;
        font-size: 12px;
        color: #535363;
        line-height: 18px;
        text-align: left;
      }
    }

    .rightBtn {
      //padding-left: 15px;
      //padding-right: 10px;
      border-radius: 50px;
      display: flex;
      align-items: center;
      //background: linear-gradient(90deg, #F8DBD1 0%, #F6A17A 100%);
      background: #6E451E;
      font-weight: 400;
      font-size: 14px;
      color: #FDEACD;
      line-height: 24px;
      //padding-top: 4px;
      //padding-bottom: 4px;
      padding: 4px 10px 4px 15px;
      cursor: pointer;

      .img {
        width: 16px;
        height: 16px;
        margin-right: 3px;
      }

      .iconfont {
        transform: rotate(90deg);
      }
    }
  }
}

.vipBox {
  //background: #21212d;
  border-radius: 10px 10px 10px 10px;
  height: 64px;
  color: #f4f6fa;
  background-image: url("https://picture.bjbjkj.top/image/image%20666%402x.png");
  background-position: 130% 0;
  background-repeat: no-repeat;
  background-size: auto 100%;
}

.innerareaOne {
  padding: 12px;
  text-align: left;
  margin-block: 10px;

  .title {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .left {
      font-weight: 400;
      font-size: 16px;
      color: #1b1b1b;
      line-height: 24px;
    }

    .right {
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 14px;
      color: #5c5c5c;
      line-height: 24px;
      cursor: pointer;

      //.iconfont {}
    }
  }

  .body {
    margin-top: 10px;

    .ul {
      display: flex;

      .li {
        width: 20%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        cursor: pointer;
        position: relative;
        .tagNums{
          position: absolute;
          left: 65%;
          top: 0;
          display: flex;
          overflow: hidden;
          box-sizing: border-box;
          justify-content: center;
          flex-direction: row;
          height: 20px;
          min-width: 20px;
          padding: 0 4px;
          line-height: 18px;
          color: #fff;
          border-radius: 100px;
          background-color: initial;
          border: 1px solid #fff;
          text-align: center;
          font-feature-settings: "tnum";
          font-size: 12px;
          z-index: 999;
          cursor: pointer;
          background-color: red;
        }


        .img {
          width: 30px;
          height: 30px;
        }
      }
    }
  }

  .lunbo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    .left {
      font-size: 13px;
      min-width: 60%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      align-items: center;

      .iconfont {
        margin-right: 5px;
      }
    }

    .right {
      white-space: nowrap;
      display: flex;
      align-items: center;
      font-size: 13px;
      color: #1b1b1b;
      line-height: 24px;

      .iconfont {
        font-weight: 500;
        font-size: 24px;
      }
    }
  }
}
.innerareaTwo{
  border-radius: 10px;
  border: 1px solid #ffffff;
  background: #fff5f5;
  box-shadow: 0px 0px 4px #f5fffa;
  margin-bottom: 10px;

  .lunbo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    .img_my_Point{
      width: 25px;
      height: 25px;
      object-fit: cover;
      margin-right: 5px;
    }
    .left{
      display: flex;
      align-items: center;
      color: #1b1b1b;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }
    .right{
      width: fit-content;
      height: fit-content;
      display: inline-flex;
      place-content: center;
      place-items: center;
      gap: 10px;
      padding: 6px 10px;
      border-radius: 20px;
      background: linear-gradient(90.24deg, #ffa460 0.21%, #ff80a4 99.83%);
      color: #ffffff;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      .iconfont{
        display: inline-block;
        transform: rotate(90deg);
      }

    }
  }
}

.otherOut {
  background: #ffffff;
  margin: 0 16px;
  border-radius: 10px;
  padding-bottom: 5px;

  .title {
    display: flex;
    justify-content: space-between;
    margin: 0 12px;
    padding-top: 10px;
    text-align: left;

    .left {
      font-weight: 400;
      font-size: 16px;
      color: #1b1b1b;
      line-height: 24px;
      text-align: left;
    }

    .right {
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 14px;
      color: #5c5c5c;
      line-height: 24px;

      //.iconfont {}
    }
  }

  .otherUl {
    display: flex;
    flex-wrap: wrap;
    .out{
      width: 25%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 13px;
      margin-top: 10px;
      margin-bottom: 10px;
      cursor: pointer;
    }
    .displayNone{
      display: none;
    }
    .li {


      .img {
        width: 26px;
        height: 26px;
      }
    }
  }
}

.icon-Frame-4 {
  font-size: 20px;
}
</style>