<template>
  <div>
    <div class="container">
      <van-sticky @change="stickchange" @scroll="stickscroll">
        <div class="topBox" ref="sticky">
          <navigationBar class="nivatop">
            <template #search>
              <div class="searchBox">
                <div class="leftSearch">
                  <div class="icon">
                    <span
                        class="iconfont icon-grommet-icons_form-search-1"
                    ></span>
                  </div>
                  <div class="line"></div>
                  <div class="placeHolder">搜索你想要的物品...</div>
                </div>
                <div class="rightBtn">
                  <span class="iconfont icon-kefu" @click="kefu"></span>
                  <span class="iconfont icon-chaoshidingdan" @click="goOrderLi"></span>
                </div>
              </div>
            </template>
          </navigationBar>
          <div class="overScroll">
            <div class="innerSroll" ref="innerSroll">
              <div
                  class="li"
                  v-for="(i, t) in commodityLList"
                  :key="t"
                  :class="t == chooseTop ? 'chooseTop' : ''"
                  @click="chooseTopFun(t)"
                  ref="nvali"
              >
                <img
                    class="img"
                    :src="i.pic"
                    alt=""
                />
                <div class="name">{{ i.cate_name }}</div>
              </div>
            </div>
            <div class="moreBtn" @click="openTopNav" v-if="commodityLList.length>5">
              更多
              <span class="iconfont icon-Frame12"></span>
            </div>
            <van-popup v-model="topNavShow" round position="top">
              <div class="topBox" ref="sticky">
                <navigationBar class="nivatop">
                  <template #search>
                    <div class="searchBox">
                      <div class="leftSearch">
                        <div class="icon">
                    <span
                        class="iconfont icon-grommet-icons_form-search-1"
                    ></span>
                        </div>
                        <div class="line"></div>
                        <div class="placeHolder">搜索你想要的物品...</div>
                      </div>
                      <div class="rightBtn">
                        <span class="iconfont icon-kefu"></span>
                        <span class="iconfont icon-chaoshidingdan"></span>
                      </div>
                    </div>
                  </template>
                </navigationBar>
                <div class="overWrap">
                  <div class="innerSroll">
                    <div
                        class="li"
                        v-for="(i, t) in commodityLList"
                        :key="t"
                        :class="t == chooseTop ? 'chooseTop' : ''"
                        @click="chooseTopFun(t)"
                    >
                      <img
                          class="img"
                          :src="$store.state.imageHost+i.image"
                          alt=""
                      />
                      <div class="name">{{ i.name }}</div>
                    </div>
                  </div>
                </div>
              </div>


            </van-popup>
          </div>
        </div>
      </van-sticky>
      <div class="area">
        <div class="body" v-if="commodityLList.length>0">
          <div class="left" :class="fixedFlag?'fixed1':''" :style="'top:'+(this.stickyHieght)+'px'">
            <div
                class="li"
                v-for="(item, index) in leftLi"
                :key="index"
                :class="leftIndex == index ? 'chooseLeft' : ''"
                @click="chooseLeft(index)"
            >
              {{ item.cate_name }}
            </div>
            <!-- <van-sidebar v-model="leftIndex">
            <van-sidebar-item  v-for="(item, index) in leftLi" :key="index"  :title="item" />
          </van-sidebar> -->
          </div>
          <div class="left" v-if="fixedFlag"></div>

          <div class="right" ref="container">
            <div class="swiperOut">
              <div class="swiper">
                <div class="swiper-wrapper">
                  <!-- <div class="swiper-slide"><img src="../../../../public/image/asdasd.png" alt="" class="img"></div> -->
                  <div
                      class="swiper-slide"
                      :style="'background-image:url(https://picture.bjbjkj.top/tp2024062702331433414434306.jpeg)'"
                  ></div>
                  <!-- <div class="swiper-slide"><img src="../../../../public/image/asdasd1.png" alt="" class="img"></div> -->
                </div>
                <!-- 如果需要分页器 -->
                <div class="pagination">
                  <div class="swiper-pagination"></div>
                </div>
              </div>
            </div>
            <div class="top">
              <div class="leftSort" :class="sortType==1?'defaultS':''" @click="defaultS">默认排序</div>
              <div class="rightSort">
                <div class="sale" :class="sortType==2?'saleS':''" @click="sale">销量</div>
                <div class="priceSort" @click="price" :class="sortType==3||sortType==4?'priceS'+sortType:''">
                  价格
                  <div class="iconfontBox">
                    <span class="iconfont icon-Vector"></span>
                    <span class="iconfont icon-a-Group2009"></span>
                  </div>
                  <!--                  <span class="iconfont icon-a-Group20093"></span>-->
                  <!--                  <svg class="icon" aria-hidden="true">-->
                  <!--&lt;!&ndash;                    <use xlink:href="#icon-a-Group20093"></use>&ndash;&gt;-->
                  <!--                    <use xlink:href="#icon-a-Group2009-11"></use>-->
                  <!--                  </svg>-->
                </div>
              </div>
            </div>
            <div class="tagLi">
              <div class="item" :class="tagIdex==index?'sortEvery ':''" v-for="(item,index) in tagLi" :key="index"
                   @click="chooseTag(index)">
                {{ item.cate_name }}
              </div>
            </div>
            <div class="ul">
              <div class="li" v-for="(item, index) in goodsLi" :key="index" @click.stop="godetails(item)">
                <div class="leftImgBox">
                  <img :src="item.image" alt="" class="img"/>
                </div>
                <div class="info">
                  <div class="brand" v-if="item.brand_name">
                    <div class="brandBox">品牌</div>
                    {{ item.brand_name }}
                  </div>
                  <div class="name">{{ item.store_name }}</div>
                  <div class="detail">
                    <div class="spacl">{{ item.specifications }}</div>
                    <div class="line"></div>
                    <div class="sale">{{ item.sell_num }}</div>
                  </div>
                  <div>
                    <div class="price_add">
                      <div class="leftpriced">
                        <div class="leftPrice">
                          <div class="sellPrice">
                            <div>￥</div>
                            <div class="yuan">{{ item.yuan }}</div>
                            <div>.{{ item.mao }}</div>
                          </div>
                          <div class="lastPrice" :class="$store.state.is_vip.is_vip?'oldPrice':''" v-if="!$store.state.is_vip.is_vip">￥{{ item.ot_price }}</div>
                          <img v-if="$store.state.is_vip.is_vip" :src="$store.state.imageHost+'/img/vip_price_tag.png'"
                               alt="" class="vip_price_tag">
                        </div>
                        <div class="vipBox" v-if="item.is_vip">
                          <div class="vipPrice">￥{{ $store.state.is_vip.is_vip ? item.price : item.vip_price }}</div>
                          <img v-if="!$store.state.is_vip.is_vip" :src="$store.state.imageHost+'/img/vip_price_tag.png'"
                               alt="" class="vip_price_tag">
                          <div class="lastPrice" :class="$store.state.is_vip.is_vip?'oldPrice':''" v-if="$store.state.is_vip.is_vip">￥{{ item.ot_price }}</div>
                        </div>
                      </div>
                      <div class="rightAdd">
                        <div class="unadd" v-if="item.cart_num>0&&item.spec_type === 0" @click.stop="unadd(item)"></div>
                        <div class="input" v-if="item.cart_num>0&&item.spec_type === 0">{{ item.cart_num }}</div>
                        <div class="add" @click.stop="add(item)"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="noGoods" v-if="goodsLi.length<=0&&loding==false">
                暂无商品
              </div>
            </div>
          </div>
        </div>
        <van-popup v-model="carshow" round position="bottom">
          <div class="popup">
            <div class="tioline"></div>
            <div class="title">
              <div class="left">购物车</div>
              <div class="right" @click="clearCar">清空</div>
            </div>

            <div class="carBox">
              <div class="item" v-for="(item, index) in carList" :key="index">

                <div class="leftImgBox">
                  <!--                  <img :src="$store.state.imageHost+item.products.images" alt="" class="img">-->
                  <img :src="item.productInfo.image" alt="" class="img">
                </div>
                <div class="rightInfo">
                  <div class="name">{{ item.productInfo.store_name }}</div>
                  <div class="detail">
                    <div class="spacl">
                    </div>
                    <div class="sale">规格：{{ item.productInfo.attrInfo.suk }}</div>
                  </div>
                  <div class="price_add">
                    <div class="leftpriced">
                      <div class="leftPrice">
                        <div class="sellPrice">
                          <div>￥</div>
                          <div class="yuan">{{ item.productInfo.yuan }}</div>
                          <div>.{{ item.productInfo.mao }}</div>
                        </div>
                        <div class="lastPrice" :class="$store.state.is_vip.is_vip?'oldPrice':''" v-if="!$store.state.is_vip.is_vip">￥{{ item.sum_price }}</div>
                        <img v-if="$store.state.is_vip.is_vip" :src="$store.state.imageHost+'/img/vip_price_tag.png'"
                             alt="" class="vip_price_tag">
                      </div>
                      <div class="vipBox" v-if="item.productInfo.is_vip">
<!--                        <div class="vipPrice">-->
<!--                          ￥{{ $store.state.is_vip.is_vip ? item.productInfo.price : item.productInfo.vip_price }}-->
<!--                        </div>-->
                        <img v-if="!$store.state.is_vip.is_vip" :src="$store.state.imageHost+'/img/vip_price_tag.png'"
                             alt="" class="vip_price_tag">
                        <div class="lastPrice" :class="$store.state.is_vip.is_vip?'oldPrice':''" v-if="$store.state.is_vip.is_vip">￥{{ item.sum_price }}</div>
                      </div>
                    </div>
                    <div class="rightAdd">
                      <div class="unadd" v-if="item.cart_num>0" @click.stop="unadd1(item)"></div>
                      <div class="input" v-if="item.cart_num>0">{{ item.cart_num }}</div>
                      <div class="add" @click.stop="add1(item)"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="bottom">
              <div class="bottomBigbox">
                <div class="left">
                  <div class="car">
                    <img src="https://picture.bjbjkj.top/img/supermarkerCar.png" alt="" class="image busPos">
                    <div class="nums" v-if="cartNums>0">{{ cartNums > 99 ? ' 99+' : cartNums }}</div>
                  </div>
                  <div class="price">
                    ￥<span>
                  <span class="yuan">{{ allPirce.yuan }}</span
                  ><span class="mao">.{{ allPirce.mao }}</span></span
                  >
                  </div>
                </div>
                <div class="right">
                  <!-- <div class="songshui btnBox">一键送水</div> -->
                  <div class="zhifu btnBox" @click="submit">提交订单</div>
                </div>
              </div>
            </div>

          </div>
        </van-popup>
        <suk-pop v-if="chooseGoods.id" @buyClicked="buyClicked" :initial_sku="initialSku" ref="suk" :skuParent="sku"
                 :goods="chooseGoods"></suk-pop>
        <div class="bottom" v-if="carList.length>=1">
          <div class="bottomBigbox" v-if="carList.length>=1">
            <div class="left" @click="opencar">
              <div class="car">
                <!--                <img-->
                <!--                    class="image busPos"-->
                <!--                    src="https://picture.bjbjkj.top/img/Frame 2524@2x.png"-->
                <!--                    alt=""-->
                <!--                />-->
                <img src="https://picture.bjbjkj.top/img/supermarkerCar.png" alt="" class="image busPos">
                <div class="nums" v-if="cartNums>0">{{ cartNums > 99 ? ' 99+' : cartNums }}</div>
              </div>
              <div class="price">
                ￥<span>
                  <span class="yuan">{{ allPirce.yuan }}</span
                  ><span class="mao">.{{ allPirce.mao }}</span></span
              >
              </div>
            </div>
            <div class="right">
              <!-- <div class="songshui btnBox">一键送水</div> -->
              <div class="zhifu btnBox" @click="submit">提交订单</div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <loding v-if="loding"></loding>
  </div>
</template>

<script>
import navigationBar from "../../navigation-bar/navigation-bar.vue";
import Swiper from "swiper/bundle";
import "swiper/swiper-bundle.css";
import {
  delCartList,
  get_attr, getCartList,
  getCartNums,
  getWaterBrand,
  getWatercategory,
  getWaterproductsList,
  set_cart_num
} from "@/utils/api";
import loding from "@/components/common/loding/loding.vue";
import {Dialog, Toast} from "vant";
import {_getChatRoom, _throttle, getParentIdNew} from "@/utils/tool";
import SukPop from "@/components/common/sukPop.vue";

export default {
  components: {
    SukPop,
    loding,
    navigationBar,
  },
  props: {},
  data() {
    return {
      chooseGoods: {},
      amount:0,
      cartNums:0,
      container: '',
      chooseTop: 0,
      loding: false,
      commodityLList: [],
      goodsLi: [],
      tagIdex: 0,
      leftIndex: 0,
      swiper: {},
      allPirce: {
        yuan: 0,
        mao: 0,
      },
      carshow: false,
      topNavShow: false,
      fixedFlag: false,
      stickyHieght: 0,
      sortType: 1,
      // tagLi: [],
      carList: [],


      page: 1,
      limit: 10,



      sku: {
        // 所有sku规格类目与其值的从属关系，比如商品有颜色和尺码两大类规格，颜色下面又有红色和蓝色两个规格值。
        // 可以理解为一个商品可以有多个规格类目，一个规格类目下可以有多个规格值。
        tree: [
          {
            k: "颜色",
            k_id: "1",
            v: [
              {
                id: "30349",
                name: "天蓝色",
                imgUrl:
                    "https://img.yzcdn.cn/upload_files/2017/02/21/FjKTOxjVgnUuPmHJRdunvYky9OHP.jpg!100x100.jpg",
              },
              {
                id: "1215",
                name: "白色",
              },
            ],
            k_s: "s1",
            count: 2,
          },
          {
            k: "尺寸",
            k_id: "2",
            v: [
              {
                id: "1193",
                name: "1",
              },
              {
                id: "1194",
                name: "2",
              },
            ],
            k_s: "s2",
            count: 2,
          },
        ],
        // 所有 sku 的组合列表，如下是：白色1、白色2、天蓝色1、天蓝色2
        list: [
          {
            id: 2259,
            price: 12000, //价格（单位分）
            s1: "1215",
            s2: "1193",
            stock_num: 20, //库存
            goods_id: 946755,
            a: 0,
          },
          {
            id: 2260,
            price: 11000,
            s1: "1215",
            s2: "1194",
            stock_num: 2, //库存
            goods_id: 946755,
          },
          {
            id: 2257,
            price: 13000,
            s1: "30349",
            s2: "1193",
            stock_num: 40, //库存
            goods_id: 946755,
          },
          {
            id: 2258,
            price: 10000,
            s1: "30349",
            s2: "1194",
            stock_num: 50, //库存
            goods_id: 946755,
          },
        ],
        price: "59.00-129.00", // 默认价格（单位元）
        stock_num: 227, // 商品总库存
        none_sku: false, // 是否无规格商品 false正常显示那些可供选择的标准，此处是颜色和尺寸
        hide_stock: false, // 是否隐藏剩余库存 false正常显示剩余多少件的那个库存,
      },
      initialSku: {},
    };
  },
  watch: {
    amount(n) {
      this.allPirce.yuan = n.split(".")[0]
      this.allPirce.mao = n.split(".")[1]
    },
    chooseTop(n) {
      this.leftIndex = 0
      this.tagIdex = 0
      if (!this.commodityLList[n].children[this.leftIndex].children[this.tagIdex].child) {
        this.qingqiu()
      } else {
        this.goodsLi = this.commodityLList[n].children[this.leftIndex].children[this.tagIdex].child
      }
    },
    leftIndex(n) {
      this.tagIdex = 0
      if (!this.commodityLList[this.chooseTop].children[n].children[this.tagIdex].child) {
        this.qingqiu()
      } else {
        this.goodsLi = this.commodityLList[this.chooseTop].children[n].children[this.tagIdex].child
      }
    },
    tagIdex(n) {
      if (!this.commodityLList[this.chooseTop].children[this.leftIndex].children[n].child) {
        this.qingqiu()
      } else {
        this.goodsLi = this.commodityLList[this.chooseTop].children[this.leftIndex].children[n].child
      }
    },
    tagLi(n) {
      if (n.length >= 1) {
        // this.goodsLi = n[this.tagIdex].data
      } else {
        // this.goodsLi = []
      }
    },
    goodsLi(n) {
      n.forEach(item => {
        if (!item.yuan) {
          if (item.is_vip == 0) {
            item.yuan = item.price.split('.')[0]
            item.mao = item.price.split('.')[1]
          } else if (item.is_vip == 1) {
            item.yuan = (this.$store.state.is_vip.is_vip ? item.vip_price : item.price).split('.')[0]
            item.mao = (this.$store.state.is_vip.is_vip ? item.vip_price : item.price).split('.')[1]
          }
        }
      })

    },
    carList: {
      handler(n) {
        // this.watchCar(n)
        let mapc = Object.fromEntries(n.map(({product_id, nums}) => [product_id, nums]));
        n.forEach((item) => {
          console.log(item)
          // item.yuan = item.products.price.split(".")[0]
          // item.mao = item.products.price.split(".")[1]
        })
        console.log(mapc)
        this.goodsLi.forEach(it => {
          it.cart_nums = mapc[it.id] ?? 0
        })
      },


      deep: true,
    }
  },
  computed: {
    leftLi() {
      return this.commodityLList[this.chooseTop].children
    },
    cid() {
      return this.commodityLList[this.chooseTop].id
    },
    sid() {
      return this.commodityLList[this.chooseTop].children[this.leftIndex].id
    },
    tid() {
      return this.commodityLList[this.chooseTop].children[this.leftIndex].children[this.tagIdex].id
    },
    tagLi() {
      if(this.commodityLList[this.chooseTop]){
        return this.commodityLList[this.chooseTop].children[this.leftIndex].children
      }else{
        return  []
      }
    },
    // goodsLi(){
    //
    // },
  },
  methods: {
    buyClicked(e) {
      if(e.selectedSkuComb.suk){
        //多规格
        set_cart_num(e.goodsId, e.selectedNum, e.selectedSkuComb.unique, 1,2).then(res => {
          if (res.data.status == 200) {
            Toast('加入成功')
            this.getCartNum()
            this.getCart()
            this.getGoodsLi()
            this.$refs.suk.closePop()
            this.initialSku.selectedNum = 1
          }else{
            Toast(res.data.msg)
          }
        })
      }
    },

    watchCar: _throttle(function (n) {
      let mapc = Object.fromEntries(n.map(({product_id, nums}) => [product_id, nums]));
      console.log(mapc)
      // n.forEach((item) => {
      //   item.yuan = item.products.price.split(".")[0]
      //   item.mao = item.products.price.split(".")[1]
      // })
      // console.log(mapc)
      // this.goodsLi.forEach(it=>{
      //   it.cart_nums = mapc[it.id] ?? 0
      // })
      // console.log(n.length,'n.length',this.cartNums.setter)

    }, 1000),
    goOrderLi() {
      this.$router.push({
        name: "order_list",
        params: {id: '0',},
      })
    },
    kefu() {
      if (!this.$store.state.token) {
        Toast('您还未登录，请登录')
        return
      }
      //type 2 辅材 2 水站 3 超市 4 快修 5 房产
      _getChatRoom(3, 1, 2).then(res => {
        if (res) {
          this.$router.push({
            name: 'chat_details',
          })
        }
      })
    },
    godetails(item) {
      this.$router.push({
        path: '/water_details',
        query: {goods: item.id}
      })
    },
    chooseTag(e) {
      this.tagIdex = e
    },
    openTopNav() {
      this.topNavShow = true
    },
    chooseLeft(e) {
      this.leftIndex = e;
    },
    chooseTopFun(e) {
      this.chooseTop = e;
      // console.log(this.$refs)
      // console.log(this.$refs)
      // console.log(this.$refs.nvali[e].scrollIntoView())
      // this.$refs.innerSroll.scrollLeft = this.$refs.nvali[0].offsetWidth*e
      if (e >= 2) {
        this.$refs.nvali[e - 2].scrollIntoView({block: 'end', behavior: 'smooth', inline: "start"})
      }
    },
    submit() {
      if (this.carshow) {
        this.$router.push({
          name: 'createOrderSecond',
          params: {list: this.carList, allPrice: this.allPirce,cat:2,},
        })
        this.$store.commit('updateWaterOrder', this.carList)
      } else {
        this.carshow = true
      }
    },
    opencar() {
      this.carshow = true
    },
    stickchange(isFixed) {
      this.fixedFlag = isFixed
    },
    stickscroll(e) {
      e
      // console.log(e)
    },
    getCartNum() {
      getCartNums(0,2).then(res => {
        if (res.data.status == 200) {
          this.cartNums = res.data.data.count
          this.amount = res.data.data.sum_price
        } else {
          Toast(res.data.msg)
        }
      })
    },
    clearCar() {
      Dialog.confirm({
        title: '确认清空购物车？',
      })
          .then(() => {
            // on confirm
            if (!this.$store.state.token) return;
            let goods_id = []
            this.carList.forEach(item => {
              goods_id.push(item.id)
            })
            goods_id = String(goods_id)
            delCartList(goods_id).then(res => {
              console.log(res.data.status)
              if (res.data.status == 200) {
                this.goodsLi.forEach(it => {
                  it.cart_num = 0
                })
                this.carList = []
                this.carshow = false
              } else {
                Toast(res.data.msg)
              }
            })

          })
          .catch(() => {
            // on cancel
          });
    },
    add(item) {
      let nums = 1
      if (nums < item.sell_num) {
        nums = item.sell_num
      } else {
        nums = 1
      }
      if (item.spec_type == 1) {
        this.chooseGoods = item
        this.chooseGoods.storeInfo = this.chooseGoods

        this.$nextTick(async () => {
          this.multi_specification()
        })


      }else{
        set_cart_num(item.product_id, nums, '', 1,2).then(res => {
          if (res.data.status == 200) {
            item.cart_num += 1
            get_attr(item.id, 0)
            this.getCartNum()
            this.getCart()
          }else {
            Toast(res.data.msg)
          }
        })

      }


    },
    unadd(item) {

      let num = 0
      let type = 0
      console.log(item)
      if (item.cart_num <= 0) return;
      if (item.cart_num <= item.sell_num) {
        num = 0
        type = -1
      } else {
        num = 1
        type = 0
      }
      set_cart_num(item.product_id, num, '', type,2).then(res => {
        if (res.data.status == 200) {
          item.cart_num -= num
          this.getCartNum()
          this.getCart()
        } else {
          Toast(res.data.msg)
        }
      })

      // item.cart_nums -= 1
    },
    add1(item) {
      set_cart_num(item.product_id, 1, item.product_attr_unique?item.product_attr_unique:'', 1,2).then(res => {
        if (res.data.status == 200) {
          item.cart_num += 1
          this.getCartNum()
          this.getCart()
          let mapc = Object.fromEntries(this.carList.map(({product_id, cart_num}) => [product_id, cart_num]));
          this.goodsLi.forEach(it => {
            it.cart_num = mapc[it.id] ?? 0
          })
        } else {
          Toast(res.data.msg)
        }
      })
    },
    unadd1(item) {
      console.log(item)
      set_cart_num(item.product_id, 1, item.product_attr_unique?item.product_attr_unique:'', 0,2).then(res => {
        if (res.data.status == 200) {
          item.cart_num -= 1
          this.getCartNum()
          this.getCart()
          let mapc = Object.fromEntries(this.carList.map(({product_id, cart_num}) => [product_id, cart_num]));
          this.goodsLi.forEach(it => {
            it.cart_num = mapc[it.id] ?? 0
          })
        } else {
          Toast(res.data.msg)
        }
      })
    },
    getCart() {
      getCartList(2).then(res => {
        if (res.data.status == 200) {
          this.carList = res.data.data
          this.carList.forEach(item => {
            if (!item.productInfo.yuan) {
              if (item.productInfo.is_vip == 0) {
                // sum_price 原价
                item.productInfo.yuan = item.truePrice.toFixed(2).split('.')[0]
                item.productInfo.mao = item.truePrice.toFixed(2).split('.')[1]
              } else {
                // item.productInfo.yuan = (this.$store.state.is_vip.is_vip ? item.productInfo.vip_price : item.productInfo.price).split('.')[0]
                // item.productInfo.mao = (this.$store.state.is_vip.is_vip ? item.productInfo.vip_price : item.productInfo.price).split('.')[1]

                item.productInfo.yuan = item.truePrice.toFixed(2).split('.')[0]
                item.productInfo.mao = item.truePrice.toFixed(2).split('.')[1]
              }
            }
          })
        }
      })
    },
    defaultS() {
      this.sortType = 1
      this.goodsLi = this.goodsLi.sort(this.compareAlign('id', 'inverted'))
    },
    sale() {
      this.sortType = 2
      this.goodsLi = this.goodsLi.sort(this.compareAlign('buy_count', 'inverted'))
    },
    price() {
      let price = 'price'
      if (this.$store.state.is_vip.is_vip) {
        price = 'vip_price'
      }
      let list = JSON.parse(JSON.stringify(this.goodsLi))
      list.forEach(item => {
        item.price = Number(item.price)
        item.vip_price = Number(item.vip_price)
      })
      if (this.sortType == 3) {
        list = list.sort(this.compareAlign(price, 'positive'))
        list.forEach(item => {
          item.price = Number(item.price).toFixed(2)
          item.vip_price = Number(item.vip_price).toFixed(2)
        })
        this.sortType = 4
        this.goodsLi = list
      } else {
        list = list.sort(this.compareAlign(price, 'inverted'))
        list.forEach(item => {
          item.price = Number(item.price).toFixed(2)
          item.vip_price = Number(item.vip_price).toFixed(2)
        })
        this.sortType = 3
        this.goodsLi = list.sort(this.compareAlign(price, 'inverted'))
      }
    },
    compareAlign(prop, align) {
      return function (a, b) {
        var value1 = a[prop];
        var value2 = b[prop];
        if (align == "positive") {//正序
          return new Date(value1) - new Date(value2);
        } else if (align == "inverted") {//倒序
          return new Date(value2) - new Date(value1);
        }
      }
    },
    getList() {
      getWatercategory().then(async res => {
        if (res.data.status == 200) {
          let list = res.data.data.filter(item => {
            return item.cate_name != "百街水站"
          })
          list.forEach(item => {
            if (item.children) {
              item.children.forEach(item2 => {
                if (item2.children) {
                  item2.children.unshift({
                    big_pic: '',
                    cate_name: '全部商品',
                    id: '',
                    pic: '',
                    pid: '',
                  })
                } else {
                  item2.children = [{
                    big_pic: '',
                    cate_name: '全部商品',
                    id: '',
                    pic: '',
                    pid: '',
                  }]
                }
              })
            }

          })
          this.commodityLList = list
          this.$nextTick(() => {
            this.stickyHieght = this.$refs.sticky.offsetHeight
            this.container = this.$refs.container
          })
          // this.tagLi = this.commodityLList[this.chooseTop].children[this.leftIndex].children
          if (this.commodityLList[this.chooseTop].children[this.leftIndex]) {
            this.qingqiu()
          }
        } else {
          Toast(res.data.msg)
        }
      })
      // get_cat_goods_list_type_6().then(res => {
      //   if (res.data.status) {
      //     let list = res.data.data
      //     list.sort(function (a, b) {return a.sort - b.sort;})
      //
      //     // list.forEach(item => {
      //     // console.log(item)
      //     // })
      //     this.commodityLList = list

      //     if(list[this.chooseTop].goods_cat_idss[this.leftIndex]){
      //       get_list_6('', list[this.chooseTop].goods_cat_idss[this.leftIndex].id).then(res1 => {
      //         if (res1.data.status == true) {
      //           this.tagLi = res1.data.data
      //           this.commodityLList[this.chooseTop].goods_cat_idss[this.leftIndex].child = res1.data.data
      //         }
      //       })
      //     }
      //
      //   } else {
      //     Toast(res.data.msg)
      //   }
      //   this.loding = false
      // })
    },

    getGoodsLi() {
      this.loding = true
      getWaterproductsList(this.page, this.limit, 1, this.cid, this.sid, this.tid).then(async res => {
        let list = res.data.data
        this.commodityLList[this.chooseTop].children[this.leftIndex].children[this.tagIdex].child = list
        this.goodsLi = list
        const {data} = await getWaterBrand()
        let brandlist = data.data

        console.log(this.carList,brandlist)
        this.loding = false
      })
    },
    qingqiu: _throttle(function () {
      this.limit += 10
      this.getGoodsLi()
    }, 300),


    async multi_specification() {
      const {data} = await get_attr(this.chooseGoods.id, 0)
      this.chooseGoods.storeInfo.picture = this.chooseGoods.storeInfo.image
      this.sku.price = this.chooseGoods.storeInfo.is_vip?(this.$store.state.is_vip.is_vip?this.chooseGoods.storeInfo.vip_price:this.chooseGoods.storeInfo.price):this.chooseGoods.storeInfo.price
      this.sku.collection_id = this.chooseGoods.storeInfo.id
      this.sku.stock_num = this.chooseGoods.storeInfo.stock
      this.sku.quota = this.chooseGoods.storeInfo.limit_num
      let tree = JSON.parse(JSON.stringify(data.data.productAttr))
      let list2 = Object.values(data.data.productValue)
      let initialSku = {}
      tree.forEach((item) => {
        item.v=[];
        item.attr_value.forEach((item2,index2)=>{
          item.v.push({
            id:  item.id+''+index2,
            name:  item2.attr
          })
        })
        item.k = item.attr_name
        item.k_s = String(item.id)
        initialSku[item.k_s] = item.v[0].id
        item.imgUrl=''
        item.previewImgUrl=''
        // item.v.id = item.attr_value.id
        delete item.attr_value
        delete item.attr_values
        delete item.attr_name
        delete item.product_id
        delete item.type
      })
      list2.forEach(item=>{
        item.stock_num = item.stock
        item.goods_id = item.id
        item.price =  item.price*100
        tree.forEach(item2=>{
          if(!item[item2.k_s]){
            item[item2.k_s]  = ''
          }
        })
        item.suk.split(',').forEach(item2=>{
          let id = getParentIdNew(tree,'name',item2,'v').id
          item[id.slice(0,id.length-1)]= id
        })

      })
      console.log(tree,'tree')
      console.log(list2,'list2')
      this.sku.tree = tree
      this.sku.list = list2
      this.initialSku = initialSku


      this.$refs.suk.openPop()
    },
  },
  created() {
    this.$nextTick(() => {
      this.swiper = new Swiper(".swiper", {
        pagination: {
          el: ".swiper-pagination",
        },
        loop: false,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
      });
    })
  },
  mounted() {
    this.getList()
    this.getCart()
    this.getCartNum()
  },
};
</script>
<style lang="scss" scoped>
.price_add {
  display: flex;
  justify-content: space-between;
  margin-right: 16px;
  align-items: flex-end;
  font-weight: 500;

  .leftPrice {
    display: flex;
    //align-items: baseline;
    align-items: flex-end;
    align-items: center;

    .sellPrice {
      display: flex;
      font-size: 14px;
      color: #fd2a39;
      line-height: 18px;
      align-items: baseline;

      .yuan {

        font-size: 20px !important;
      }
    }

    .lastPrice {
      text-decoration-line: line-through;
      line-height: 18px;
      color: #7f7f7f;
      font-size: 12px;
    }
  }

  .vip_price_tag {
    width: 34px;
    height: 14px;
    margin-left: 5px;
  }

  .rightAdd {
    display: flex;

    .unadd,
    .add {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: #398ae9;
      position: relative;
    }

    .add::after,
    .unadd:after {
      content: "";
      display: block;
      position: absolute;
      transform: translate(-50%, -50%);
      width: 50%;
      height: 10%;
      background-color: white;
      top: 50%;
      left: 50%;
      border-radius: 100px;
    }

    .add::before {
      content: "";
      display: block;
      position: absolute;
      transform: translate(-50%, -50%);
      width: 10%;
      height: 50%;
      background-color: white;
      top: 50%;
      left: 50%;
      border-radius: 100px;
    }

    .input {
      height: 22px;
      width: 22px;
      line-height: 22px;
      margin: 0 5px;
      text-align: center;
      font-size: 20px;
      font-weight: 600;
      color: #1b1b1b;
      white-space: nowrap;
      overflow: scroll;
    }

    .input::-webkit-scrollbar {
      display: none;
    }

    .addBox {
      background: #0085FF;
      border-radius: 5px 5px 5px 5px;
      font-weight: 500;
      font-size: 12px;
      color: #FFFFFF;
      line-height: 24px;
      text-align: center;
      padding: 0 10px;
    }
  }
}
.leftpriced{
  overflow: hidden;
}
.vipBox {
  display: flex;
  font-weight: 600;
  font-size: 14px;
  color: #392723;
  line-height: 18px;
  align-items: center;

  .vipPrice {
    margin-right: 5px;
  }
}

.container {
  height: 100vh;
}

.popup {
  position: relative;
  padding: 20px 16px 0 16px;

  .carBox {
    max-height: 50vh;
    overflow: scroll;
    margin-top: 10px;
    margin-bottom: 70px;

    .item {
      display: flex;
    }

    .leftImgBox {
      width: 75px;
      height: 75px;
      border-radius: 10px;
      overflow: hidden;

      .img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    .rightInfo {
      flex: 1;
      padding-left: 10px;
      padding-bottom: 10px;

      .brand {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        display: flex;
        text-overflow: ellipsis;
        align-items: center;
        font-size: 13px;
        color: #1B1B1B;
        line-height: 18px;

        .brandBox {
          font-weight: 500;
          font-size: 12px;
          color: #1b1b1b;
          line-height: 18px;
          padding: 0 4px;
          background: #e8e8e8;
          border-radius: 4px 4px 4px 4px;
          margin-right: 5px;
        }
      }

      .name {
        font-weight: 500;
        font-size: 14px;
        color: #1b1b1b;
        line-height: 24px;
        white-space: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        align-items: center;
        text-align: left;
      }

      .detail {
        display: flex;
        font-weight: 400;
        font-size: 12px;
        color: #7f7f7f;
        line-height: 18px;
        overflow: hidden;
        text-overflow: ellipsis;
        align-items: center;

        .line {
          margin: 0 6px;
          width: 1px;
          height: 9px;
          background: #dddddd;
        }
      }

      .price_add {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .leftPrice {
          display: flex;
          align-items: baseline;
          align-items: center;

          .sellPrice {
            display: flex;
            font-size: 14px;
            color: #fd2a39;
            line-height: 24px;
            align-items: baseline;

            .yuan {
              font-size: 20px !important;
            }
          }

          .lastPrice {
            text-decoration-line: line-through;
            line-height: 18px;
            color: #7f7f7f;
            font-size: 12px;
          }
        }

        .rightAdd {
          display: flex;
          align-items: center;

          .unadd,
          .add {
            width: 22px;
            height: 22px;
            border-radius: 50%;
            background-color: #FC223B;
            position: relative;
          }

          .add::after,
          .unadd:after {
            content: "";
            display: block;
            position: absolute;
            transform: translate(-50%, -50%);
            width: 50%;
            height: 10%;
            background-color: white;
            top: 50%;
            left: 50%;
            border-radius: 100px;
          }

          .add::before {
            content: "";
            display: block;
            position: absolute;
            transform: translate(-50%, -50%);
            width: 10%;
            height: 50%;
            background-color: white;
            top: 50%;
            left: 50%;
            border-radius: 100px;
          }

          .input {
            height: 22px;
            width: 22px;
            line-height: 22px;
            //margin: 0 5px;
            text-align: center;
            font-size: 20px;
            font-weight: 600;
            color: #1b1b1b;
            overflow: scroll;
          }

          .input::-webkit-scrollbar {
            display: none;
          }
        }
      }

      .vipBox {
        display: flex;
        font-weight: 600;
        font-size: 14px;
        color: #392723;
        line-height: 18px;

        .vipPrice {
          margin-right: 5px;
        }
      }
    }
  }

  .carBox::-webkit-scrollbar {
    display: none;
  }

  .tioline {
    position: absolute;
    top: 10px;
    left: 50%;
    width: 10%;
    height: 5px;
    background: #FC223B;
    transform: translate(-50%, -0%);
    border-radius: 25px;
  }

  .title {
    display: flex;
    justify-content: space-between;
  }
}

.fixed1 {
  position: fixed;
}

.bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #ffffff;
  width: 100%;
  box-shadow: 0px -1px 2px 0px rgba(153, 153, 153, 0.15);

  z-index: 10;
}

.bottomBigbox {
  padding: 10px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left {
    display: flex;
    align-items: baseline;

    .car {
      width: 72rpx;
      position: relative;
      display: flex;
      align-items: center;
    }

    .price {
      margin-left: 10rpx;
      font-size: 14px;
    }

    .yuan {
      font-size: 24px;
    }

    .image {
      width: 36px;
      max-height: 36px;
    }

    .nums {
      position: absolute;
      left: 70%;
      top: 0;
      background-color: red;
      color: white;
      min-width: 14px;
      height: 14px;
      border-radius: 50px;
      text-align: center;
      line-height: 14px;
      padding: 2px;
    }
  }

  .right {
    display: flex;
    align-items: center;

    .btnBox {
      padding: 10px 24px;
      border-radius: 32px;
      color: white;
      font-size: 14px;
      background: #FC223B;
    }
  }
}

.price {
  color: #fd2a39;
}

.topBox {
  background: #fff9f1;
  border-radius: 0 0 20px 20px;
  overflow: hidden;
  padding-bottom: 6px;
}

.searchBox {
  display: flex;
  align-items: center;
  width: 88%;
  justify-content: space-between;

  .leftSearch {
    width: 80%;
    padding: 5px 13px;
    display: flex;
    background: #ffffff;
    border-radius: 30px 30px 30px 30px;
    border: 1px solid #ffb493;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .line {
      background: #66c23a;
      border-radius: 10px;
      border: 1px solid #ffb493;
      height: 9px;
      margin: 0 10px;
    }

    .placeHolder {
      font-weight: 400;
      font-size: 14px;
      color: #c7c7c7;
      line-height: 24px;
    }
  }

  .rightBtn {
    width: 20%;
    min-width: 70px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    .iconfont {
      color: #FC223B;
      font-size: 24px;
    }
  }
}

.nivatop {
  width: 100%;
}

.overScroll {
  overflow: hidden;
  width: 100%;
  //   background: red;
  position: relative;

  .innerSroll {
    white-space: nowrap;
    padding-top: 1px;
    overflow: scroll;
    //transition: background-color 0.3s;
    //transition-duration:0.3s;
    padding-right: 50px;

    .li {
      display: inline-block;
      width: 23%;
      text-align: center;

      .img {
        width: 48px;
        height: 48px;
        border-radius: 25%;
        border: 1px solid transparent;
      }

      .name {
        font-weight: 400;
        font-size: 11px;
        color: #1b1b1b;
        line-height: 15px;
        width: 70%;
        margin: 0 auto;
        max-width: 90%;
        white-space: nowrap;
        overflow: hidden;
        padding: 2px 6px;
        // text-overflow: ellipsis;
      }
    }

    .chooseTop {
      .name {
        color: white;
        background-color: #FC223B;
        border-radius: 15px 15px 15px 15px;
        padding: 2px 6px;
      }

      .img {
        border: 1px solid #FC223B;
      }
    }
  }

  .innerSroll::-webkit-scrollbar {
    display: none;
  }

  .moreBtn {
    writing-mode: vertical-rl;
    position: absolute;
    right: 0;
    top: -1px;
    height: 100%;
    width: 10%;
    font-weight: 500;
    font-size: 12px;
    color: #1b1b1b;
    line-height: 15px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0rpx -1rpx 4rpx 0rpx rgb(153, 153, 153);
    background: #fff9f1;
  }
}

.overWrap {
  text-align: left;

  .innerSroll {
    padding-top: 1px;
    white-space: pre-wrap;
    padding-right: 0;

    .li {
      width: 20%;
    }
  }

}

.body {
  position: relative;
  z-index: 2;
  display: flex;
  //   margin-top: 10px;
  padding-bottom: 100px;

  .left {
    width: 25%;

    .li {
      font-weight: 400;
      font-size: 13px;
      color: #1b1b1b;
      line-height: 24px;
      padding: 14px 0;
      background: #fafafa;
    }

    .chooseLeft {
      background: #fff;
      font-size: 14px;
      color: #FC223B;
    }
  }

  .right {
    width: 71%;
    padding-left: 10px;

    .top {
      margin-right: 16px;
      display: flex;
      justify-content: space-between;
      padding: 14px 0;
      font-weight: 400;
      font-size: 13px;
      color: #7f7f7f;
      line-height: 24px;

      .leftSort {
      }

      .defaultS {
        color: #FC223B;
      }

      .rightSort {
        display: flex;
        justify-content: space-between;

        .saleS {
          color: #FC223B;
        }

        .priceS3 {
          color: #FC223B !important;

          .icon-Vector {
            color: #FC223B !important;
          }
        }

        .priceS4 {
          color: #FC223B !important;

          .icon-a-Group2009 {
            color: #FC223B !important;
          }
        }

        .iconfont {
          font-size: 25px;
        }

        .sale {
          margin-right: 20px;
        }

        .priceSort {
          display: flex;
          align-items: center;

          .iconfontBox {
            display: flex;
            align-items: center;
            justify-content: center;

            .iconfont {
              font-size: 10px;
              margin-right: -5px;
              display: flex;
              color: #7f7f7f;
            }
          }
        }
      }
    }

    .ul {
      .li {
        display: flex;
        padding-top: 10px;

        .leftImgBox {
          display: flex;

          .img {
            width: 75px;
            height: 75px;
            object-fit: cover;
          }
        }

        .info {
          width: 70%;
          border-bottom: 1px solid #e8e8e8;
          padding-left: 10px;
          padding-bottom: 10px;

          .brand {
            display: flex;
            width: 100%;
            white-space: normal;
            overflow: hidden;
            text-overflow: ellipsis;
            align-items: center;
            font-weight: 500;
            font-size: 13px;
            color: #1b1b1b;
            line-height: 18px;

            .brandBox {
              font-weight: 500;
              font-size: 12px;
              color: #1b1b1b;
              line-height: 18px;
              padding: 0 4px;
              background: #e8e8e8;
              border-radius: 4px 4px 4px 4px;
              margin-right: 5px;
            }
          }

          .name {
            font-weight: 500;
            font-size: 14px;
            color: #1b1b1b;
            line-height: 24px;
            white-space: normal;
            overflow: hidden;
            text-overflow: ellipsis;
            align-items: center;
            text-align: left;

            display: -webkit-box;
            /* 必须结合的属性 ，设置或检索伸缩盒对象的子元素的排列方式 。*/
            -webkit-box-orient: vertical;
            /*要显示的行数*/
            -webkit-line-clamp: 2;
            /* 溢出部分隐藏 */
            overflow: hidden;
          }

          .detail {
            display: flex;
            font-weight: 400;
            font-size: 12px;
            color: #7f7f7f;
            line-height: 18px;
            overflow: hidden;
            text-overflow: ellipsis;
            align-items: center;

            .line {
              margin: 0 6px;
              width: 1px;
              height: 9px;
              background: #dddddd;
            }
          }

          .price_add {
            display: flex;
            justify-content: space-between;
            margin-right: 16px;
            align-items: center;

            .leftPrice {
              display: flex;
              align-items: baseline;

              .sellPrice {
                display: flex;
                font-size: 14px;
                color: #fd2a39;
                line-height: 24px;
                align-items: baseline;

                .yuan {
                  font-size: 20px !important;
                }
              }

              .lastPrice {
                text-decoration-line: line-through;
                line-height: 18px;
                color: #7f7f7f;
                font-size: 12px;
              }
            }

            .rightAdd {
              display: flex;
              align-items: center;

              .unadd,
              .add {
                width: 24px;
                height: 24px;
                border-radius: 50%;
                background-color: #FC223B;
                position: relative;
              }

              .add::after,
              .unadd:after {
                content: "";
                display: block;
                position: absolute;
                transform: translate(-50%, -50%);
                width: 50%;
                height: 10%;
                background-color: white;
                top: 50%;
                left: 50%;
                border-radius: 100px;
              }

              .add::before {
                content: "";
                display: block;
                position: absolute;
                transform: translate(-50%, -50%);
                width: 10%;
                height: 50%;
                background-color: white;
                top: 50%;
                left: 50%;
                border-radius: 100px;
              }

              .input {
                height: 22px;
                width: 22px;
                line-height: 22px;
                //margin: 0 5px;
                text-align: center;
                font-size: 18px;
                font-weight: 600;
                color: #1b1b1b;
                overflow: scroll;
              }

              .input::-webkit-scrollbar {
                display: none;
              }
            }
          }

          .vipBox {
            display: flex;
            font-weight: 600;
            font-size: 14px;
            color: #392723;
            line-height: 18px;

            .vipPrice {
              margin-right: 5px;
            }
          }
        }
      }
    }

    .tagLi {
      text-align: left;
      white-space: nowrap;
      overflow: scroll;

      .item {
        display: inline-block;
        margin-right: 12px;
        background-color: #F6F6F8;
        color: #404040;
        border-radius: 20px;
        padding: 2px 8px;
        font-size: 12px;
      }

      .sortEvery {
        background-color: #FC223B20 !important;
        color: #FC223B !important;
      }
    }

    .tagLi::-webkit-scrollbar {
      display: none;
    }
  }
}

.swiper-slide {
  // background: red;
  height: 96px;
  background-size: cover;
  background-position: center center;
}

.swiper {
  margin: 0 10px 0 0;
  overflow: hidden;
  position: relative;
  border-radius: 10px;

  .swiper-slide {
    max-height: 96px;
  }

  .img {
    width: 100%;
  }

  // 分页器
  /* 两种都可以 */
  .swiper-pagination-total {
    font-size: 12px;
  }

  .swiper-pagination {
    background: rgba(51, 51, 51, 0.75);
    width: auto;
    // right: 10px;
    border-radius: 64px;
    padding: 0 5px;
    color: white;
    line-height: 24px;
    font-size: 13px;
  }

  // 分页器
  .pagination {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 50px;
    height: 10px;
    z-index: 3;
    margin: 0;
  }
}
.oldPrice{
  font-weight: 400;
  font-size: 12px;
  color: #7F7F7F;
  line-height: 18px;
  text-align: left;
  text-decoration-line: line-through
}
</style>