<template>
  <div>
    <div id="app">

      <div class="area">
        <div class="backOne back"></div>
        <div class="backTow back"></div>
        <div class="backThe back"></div>
        <navigationBar :title="'注册'" class="navigation"></navigationBar>

        <div class="topLogoBox">
          <img class="img logo" src="../../../public/image/logo.png" alt="">
          <img class="img logoText" src="https://picture.bjbjkj.top/small_program_image/logo_text.png" alt="">
        </div>
        <div class="loginForom">
          <van-form @submit="login" ref="subRef">
            <van-field name="账号" v-model="mobile" label="账号" placeholder="请输入你的手机号" label-width='4em'
                       :rules="[{required: true, validator:mobileRules, message: ruleForm.mobileError, trigger:'onBlur', }]">
              <template #label>
                <span class="leftLable">账号</span>
              </template>
              <!--              <template #input>-->
              <!--                <input type="text" placeholder="请输入你的手机号" v-model="mobile" autoComplete class="input">-->
              <!--              </template>-->
            </van-field>
            <van-field type="text" v-model="SMS" name="验证码" label="验证码" placeholder="请输入验证码"
                       label-width='4em'
                       :rules="[{required: true, validator:smsRules, message: ruleForm.SMSError, trigger:'onBlur', }]">
              <template #label>
                <span class="leftLable">验证码</span>
              </template>
              <!--              <template #input>-->
              <!--                <input type="password" placeholder="请输入验证码" v-model="SMS" autoComplete class="input">-->
              <!--              </template>-->
              <template #button>
                <span class="smstext" @click="getSms" v-if="!timerFlag">获取验证码</span>
                <span class="smstext" v-if="timerFlag">{{ timerS }}s</span>
              </template>
            </van-field>

            <van-field type="password" autocomplete="" v-model="password" name="密码" label="密码" placeholder="设置密码"
                       label-width='4em'
                       :rules="[{required: true, name:'password', validator:passwordRule, message: ruleForm.passwordError, trigger:'onBlur', }]">
              <template #label>
                <span class="leftLable">设置密码</span>
              </template>
              <!--              <template #input>-->
              <!--                <input type="password" placeholder="请输入密码" v-model="password" autoComplete class="input">-->
              <!--              </template>-->
            </van-field>
            <van-field type="password" autocomplete="" v-model="confirmPassword" name="确认密码" label="确认密码"
                       placeholder="再次输入密码" label-width='4em'
                       :rules="[{required: true,name:'confirmPassword', validator:passwordRule, message: ruleForm.confirmPasswordError, trigger:'onBlur', }]">
              <template #label>
                <span class="leftLable">确认密码</span>
              </template>
              <!--              <template #input>-->
              <!--                <input type="password" placeholder="请输入密码" v-model="confirmPassword" autoComplete class="input">-->
              <!--              </template>-->
            </van-field>

            <van-checkbox name="协议" v-model="checked" checked-color="#EC3530" class="checkOutBox">
              <span class="checkBox">阅读并同意<span class="aHerf">《用户协议》</span>及<span
                  class="aHerf">《隐私政策》</span></span>
            </van-checkbox>


            <div class="submitLog">
              <van-button round block type="info" native-type="submit" class="btn">登录</van-button>
            </div>
            <div class="bottomFun">

            </div>
          </van-form>
        </div>


      </div>

    </div>

  </div>
</template>

<script>
import navigationBar from '../navigation-bar/navigation-bar.vue';
import {Checkbox, CheckboxGroup, Form, Field, Dialog, Toast} from 'vant';
import Vue from 'vue';
import {sendSms, yzm, app_mobile_sign_login} from "@/utils/api";


Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Form);
Vue.use(Field);
Vue.use(Dialog);
Vue.use(Toast);
export default {
  name: 'home_login',
  components: {
    navigationBar,
    [Dialog.Component.name]: Dialog.Component,
  },
  props: {},
  data() {
    return {
      regIdCard: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
      mobile: '',
      password: '',
      confirmPassword: '',
      checked: false,
      timerS: 60,
      timer: null,
      timerFlag: false,
      SMS: '',
      ruleForm: {
        mobileError: '请输入手机号',
        SMSError: '请输入验证码',
        passwordError: '请输入密码',
        confirmPasswordError: '再次输入密码',
      },
    };
  },
  watch: {
    timerS(n){
      if(n<=0){
        this.timerFlag = false
        clearInterval(this.timer)
        this.timerS = 60
      }
    },
  },
  computed: {},
  methods: {
    mobileRules(val) {
      if(!this.regIdCard.test(val)){
        this.ruleForm.mobileError= '请输入正确的手机号'
      }
      return this.regIdCard.test(val)
    },
    smsRules() {

      // console.log(val)
    },
    passwordRule(val,rule) {
      if(rule.name == 'password'){
        if(!val)return  false
      }
      if(rule.name == 'confirmPassword'){
        if(this.password!==this.confirmPassword){
          return false
        }else{
          return  true
        }
      }

    },

    loginFun(){

    },

    login(values) {
      // console.log('submit', values);
      if (!this.checked) {
        Dialog.alert({
          message: '请阅读协议',
        }).then(() => {
          // on close
        });
        return
      }



      yzm(values['账号'],values['验证码']).then(res=>{
        const {data} = res;
        if(data.status==true){
          app_mobile_sign_login(values['账号'],values['密码'],values['确认密码'],'').then(res1=>{
            if(res1.data.status){
              Toast('登录成功')
              sessionStorage.setItem('token',res1.data.data.data.token);
              let userInfo = res1.data.data.data.user
              userInfo = JSON.parse(JSON.stringify(userInfo))
              sessionStorage.setItem('userInfo',JSON.stringify(userInfo));
              this.$store.commit("updataToken", res1.data.data.data.token);
              this.$store.commit("updataUser", res1.data.data.data.user);
              this.$router.push({name:'首页'})
            }else{
              Toast(res1.data.msg)
            }
          })
        }else{
          Toast(data.msg);
        }
      }).catch(err=>{
        console.log(err)
        // Toast(err.data.msg)
      })
    },
    getSms() {
      if (!this.regIdCard.test(this.mobile)) {
        Toast('输入正确的手机号');
        return
      }
      sendSms(this.mobile).then(res => {
        const {data} = res;
        if (data.status == true) {
          this.timerFlag = true
          this.timer = setInterval(() => {
            this.timerS -= 1
          }, 1000);
        } else {
          Toast(res.data.msg)
        }
      }).catch(err=>{
        console.log(err)
        // Toast(err.data.msg)
      })

    },
  },
  created() {
  },
  mounted() {
  },
  beforeUnmount() {
    clearInterval(this.timer)
  },
};
</script>
<style lang="scss" scoped>

.navigation {
  position: relative;
  z-index: 4;
}

#app {
  height: 100vh;
  // background: red;
  position: relative;
  background: #F9FBFD;

  .back {
    position: absolute;
    height: 492px;
    border-radius: 190px;
    opacity: .8;
    filter: blur(90px);
  }

  .backOne {
    width: 388px;
    transform: translate(40%, -50%) rotate(45deg);
    background: #F2F9FB;
    right: 0;
    top: 0;
    border-radius: 114px;
  }

  .backTow {
    width: 286px;
    transform: translate(-50%, -50%) rotate(50deg);
    background: #ECECFB;
    left: 0;
    top: 55%;
  }

  .backThe {
    width: 422px;
    background: #EEF5FF;
    transform: translate(30%, 40%) rotate(45deg);
    right: 0;
    bottom: 0;
  }

}

.area {
  position: relative;
  z-index: 4;
  background: #F9FBFD;
  overflow: hidden;

  .topLogoBox {
    display: flex;
    flex-direction: column;
    margin-top: 10vh;
    justify-content: center;
    align-items: center;

    .img {
      width: 84px;
      z-index: 3;
    }

    .logoText {
      margin-top: 20px;
    }
  }
}

.loginForom {
  margin-top: 5vh;
  position: relative;
  z-index: 3;
  .van-cell {
    background: transparent !important;
    padding-left: 30px;
    padding-right: 30px;
    font-size: 16px;
    padding-bottom: 20px;

    .leftLable {
      font-size: 16px;
      font-weight: 500;
    }

    .input {
      border: none;
      background: transparent;
      font-size: 14px;
      color: #666666;
    }
  }

  .checkOutBox {
    margin-left: 30px;
    margin-top: 3vh;

    .aHerf {
      color: #FC223B;
    }
  }

  .submitLog {
    margin: 5vh 30px 0 30px;

    .btn {
      background: #FC223B;
      border: 1px solid #FC223B;
      border-radius: 10px;
      padding: 26px 0;
      font-size: 16px;
    }

    .btn::after,
    .btn::before {
      display: none;
    }
  }

  .smstext {
    color: #FC223B;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
  }

  .bottomFun {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 30px;

    .Fun {
      color: #333333;
      font-size: 14px;
    }
  }
}
</style>

<style>

</style>