<script>
import navigationBar from "@/components/navigation-bar/navigation-bar.vue";
import {
  apiorder_kt_list,
  computedOrder, createOrder,
  getCartList,
  getWatercategory,
  getWaterproductsList,
  orderConfirm,
  set_cart_num
} from "@/utils/api";
import {Toast} from "vant";
import {_throttle} from "@/utils/tool";

export default {
  name: "buyPail",
  components: {navigationBar,},
  data() {
    return {
      waterDrum: {},
      payType: 'weixin', //支付方式

      allrice: 0,
      listTitle: [
        '押金/桶',
        '已购数量',
        '付款日期',
        '押金总额',
      ],
      KtList: [],
      ktPrcie: 0,//空桶单价
      totalNum: 0,//空桶总数量
      totalPrice: 0,//空桶总价格
      btnFlag: true,
      maxlength: 4,
      maxIndex: 4,
      image: '',

    }
  },
  methods: {
    async getKongt() {
      const {data} = await getWatercategory()
      console.log(data)
      let list = []
      if (data.status == 200) {
        list = data.data
      }
      this.cid = list.find(item => {
        return item.cate_name == '百街水站'
      }).id //一级分类
      this.sid = list.find(item => {
        return item.cate_name == '百街水站'
      }).children.find(item => {
        return item.cate_name == '空桶押金'
      }).id //二级分类
      // let obj = list.find(item=>{return item.cate_name == '百街水站'}).children.find(item=>{return item.cate_name == '空桶押金'})
      const goodsData = await getWaterproductsList(this.page, this.limit, 1, this.cid, this.sid, '')
      if (goodsData.data.status == 200) {
        this.waterDrum = goodsData.data.data[0]
        this.ktPrcie = this.waterDrum.price
        this.cart_ids = this.waterDrum.id
        this.image = this.waterDrum.image
        // ktPrcie:0,//空桶单价
        //     totalNum:0,//空桶总数量
        // totalPrice:0,//空桶总价格
      }
      // getKongtong(this.$store.state.token).then(res=>{
      //   if(res.data.status==true){
      //     this.ktPrcie = res.data.data.price
      //     this.cart_ids = res.data.data.id
      //     this.image = res.data.data.images
      //   }
      // })
    },
    getist() {
      apiorder_kt_list().then(res => {
        if (res.data.status !=200) return
        let list = res.data.data
        list.forEach(item => {
          console.log(item)
          item.payment_time = item._pay_time.split(' ')[0]
        })
        this.KtList = list
      })
    },

    unadd() {
      if (this.totalNum > 0) {
        this.totalNum = this.totalNum - 1
      }
    },
    add() {
      this.totalNum += 1
    },
    creatOrder() {
      if (this.totalNum <= 0) {
        Toast('请选择购买数量')
        return
      }

      this.qingqiu()

    },
    qingqiu: _throttle(async function () {
      // let ship = {
      //   yx:'',
      //   ds:'',
      //   address:'',
      //   name:'',
      //   mobile:'',
      //   time:'',
      //   remark:'',
      //   id:'',
      // }

      await set_cart_num(this.waterDrum.id, this.totalNum, '', -1, 1)
      let car_list = await getCartList(1)
      car_list = car_list.data.data.filter(item => {
        return item.product_id == this.waterDrum.id
      })
      let confirmObj = {
        cartId: String(car_list.map(item => item.id))
      }
      let confirmPost = await orderConfirm(confirmObj)
      console.log(confirmPost.data.data)
      let data = {
        addressId: '',
        couponId: 0,
        payType: "weixin",
        useIntegral: 0,
      }
      let payobj = {
        addressId:'',
        couponId:0,
        payType:this.payType,
        mark:'',
      }
      let result = await computedOrder(confirmPost.data.data.orderKey, data)
      console.log()
      createOrder(confirmPost.data.data.orderKey, payobj).then(res => {
        delete result.data.data.result.cartInfo
        result.data.data.result.result = res.data.data.result
        this.$router.push({
          path:"/pay",
          query:{data:JSON.stringify(result.data.data.result),backStep:2,type:""}
        })
      })
      // addOrder(this.$store.state.token,this.totalNum,ship,10).then(res => {
      //   if(res.data.data){
      //     console.log(res.data.data)
      //     this.totalNum = 0
      //     this.$router.push({
      //       path:"/pay",
      //       query:{data:JSON.stringify(res.data.data),backStep:2,type:""}
      //     })
      //   }
      // })
    }, 500),
    shouqi() {
      this.maxIndex = 4
    },
    more() {
      this.maxIndex = -1
    },
  },
  mounted() {
    this.getKongt()
    this.getist()
  },
  watch: {
    totalNum(n) {
      this.totalPrice = n * this.ktPrcie
    },
  },
}
</script>

<template>
  <div class="container">
    <div class="area">
      <navigationBar :title="'购买空桶'"></navigationBar>

      <div class="buyPromit">
        <van-icon name="volume-o" class="icon"/>
        <span class="text">送桶装水数量应与收回空桶数量相符，如若不符您需购买差额空桶</span>
      </div>
      <div class="buyKyBox">
        <div class="buyTtile">
          空桶押金
        </div>

        <div class="kyCommdoty">
          <div class="img">
            <img :src="image" alt="" class="image">
            <!--            <image lazy-load class="image" src="{{globalData.imgHost+image}}" mode="aspectFill" />-->
          </div>
          <div class="centerInfo">
            <div>
              <div class="ktTitle ktInfo">
                空桶押金
              </div>
              <div class="ktUnit ktInfo">
                <span>￥</span>{{ ktPrcie }}/空桶
              </div>
            </div>
            <div class="totalPrice  ktInfo">

            </div>
          </div>
          <div class="rightAdd">
            <!--             <div class="all">合计{{totalNum}}桶</div>-->
            <div class="totalPrice  ktInfo">
              <!--               <text class="unit">￥</text>{{totalPrice}}-->
            </div>
            <div class="addBox">
              <div class="unadd" @click="unadd"></div>
              <div class="input">{{ totalNum }}</div>
              <div class="add" @click="add"></div>
            </div>
          </div>
        </div>

      </div>


      <div class="buyTtile" style="margin: 0 16px;">空桶押金购买记录</div>

      <div class="top">
        <div class="listTitle">
          <div class="listTitleEvery" v-for="(item,index) in listTitle" :key="index">
            {{ item }}
          </div>
        </div>
        <div class="KtList">
          <!--          <div class="KtListEvery" wx:for="{{KtList}}" wx:key="KtList" wx:if="{{index<maxIndex||maxIndex==-1}}">-->
          <div class="li"  v-for="(item,index) in KtList" :key="index">
            <div class="KtListEvery" v-if="index<maxIndex||maxIndex==-1">
              <div class="payed attri">
                {{ ktPrcie }}元/桶
              </div>
              <div class="nums attri">
                {{ item.total_num }}
              </div>
              <div class="payment_time attri">
                {{ item.payment_time }}
              </div>
              <div class="allPrice attri">
                {{ item.pay_price }}
              </div>
            </div>
          </div>
          <div class="more" v-if="KtList.length>maxlength&&maxIndex!=-1" @click='more'>
            查看更多
          </div>
          <div class="more" v-if="maxIndex==-1" @click='shouqi'>
            收起
          </div>
        </div>
      </div>
      <div class="bottomPromit">
        <div style="color: #666666;font-size: 14px;">注：</div>
        <div>
          <div class="promitEvery">
            1.若您没有空桶，需购买空桶;应根据您所需送水桶数，购买相应数量的空桶
          </div>
          <div class="promitEvery">
            2.若您自有空桶，无需购买空桶，自有空桶押金自理。可根据您拥有的空桶数量，送相应数量的桶装水，并回收相应数量的空桶。若您送水数量超出您所拥有的空桶数量，您需购买差额空桶
          </div>
        </div>
      </div>

      <div class="bottomRight" @click="creatOrder">
        <div class="btn">立即支付</div>
      </div>

    </div>
  </div>
</template>

<style scoped lang="scss">
.bottomRight {
  position: fixed;
  left: 0;
  bottom: 0;
  padding-bottom: 20px;
}

.nav-bar {
  background: #ffffff;
  position: fixed;
  width: 100%;
  top: 0;
  color: #1b1b1b;
  z-index: 999;
}

.nav-bar .text {
  width: 100%;
  color: #333;
  font-size: 14px;
  position: absolute;
  text-align: left;
  color: #1b1b1b;
  font-size: 15px;
  margin-left: 5px;
  font-size: 16px;
}

.bottom {
  position: fixed;
  bottom: 0%;
  width: 100%;
  transform: translate(-50%, -0%);
  left: 50%;
  display: flex;
  overflow: hidden;
  background-color: white;
  align-items: center;
  box-shadow: 0px -2px 3px 0px rgba(153, 153, 153, .15);
}

.bottomLeft .price {
  font-size: 20px;
}

.bottomLeft {
  width: 60%;
  padding: 18px 0;
  text-indent: 1.3rem;
  font-weight: 700;
  color: #F74440;
}

.bottomRight {
  /* font-weight: 800; */
  font-size: 16px;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: flex-end;

  position: fixed;
}

.bottomRight .btn {
  background-color: #286CFB;
  color: white;
  border-radius: 10px;
  padding: 13px 14px;
  font-size: 16px;
  width: 100%;
  margin: 0 16px;
}

.container {
  background-color: #ffffff;
}

.top {
  background-color: white;
  margin: 0 16px 0px 16px;
  box-shadow: 0px 0px 2px 0px rgba(102, 102, 102, 0.25);
  border-radius: 10px;
  overflow: hidden;
}

.topTitle {
  padding: 10p;
  border-bottom: 1.5px solid #f6f6f6;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.topTitle .text {
  font-size: 15px;
}

.topTitle .icon {
  font-size: 15px;
}

.KtList {
  max-height: 250px;
  overflow: auto;
}

.listTitle, .KtListEvery {
  display: flex;
  position: relative;

}

.KtListEvery::after {
  content: '';
  display: block;
  width: 98%;
  position: absolute;
  background-color: rgba(102, 102, 102, 0.15);
  height: 1px;
  bottom: 0;
  transform: translate(-50%, -50%);
  left: 50%;
}

.KtListEvery:nth-last-child(1)::after {
  content: '';
  display: block;
  width: 98%;
  position: absolute;
  background-color: white;
  height: 1px;
  bottom: 0;
  transform: translate(-50%, -50%);
  left: 50%;
}

.listTitle {
  background-color: #C8F1FE;
  background-color: #286CFB;
}

.listTitleEvery {
  width: 25%;
  text-align: center;
  padding: 10px 0;
  font-size: 14px;
  color: #1b1b1b;
  color: #ffffff;
}

.buyKyBox {
  margin-top: 10px;
  margin: 0 16px 10px 16px;
}

.buyTtile {
  padding: 5px 0 10px 0;
  font-weight: 600;
  font-size: 16px;
  color: #1b1b1b;
  text-align: left;
}

.buyPromit {
  margin: 0 16px 20px 16px;
  font-size: 14px;
  background-color: #F1F6FD;
  border: 1px solid #286CFB;
  padding: 5px 10px;
  color: #286CFB;
  display: flex;
  border-radius: 10px;
  text-align: left;
  align-items: center;
}

.buyPromit .icon {
  margin-right: 10px;
  font-size: 18px;
}

.buyPromit .text {
  font-size: 12px;
  line-height: 24px;
}

.kyCommdoty {
  display: flex;
  padding: 10px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 2px 0px rgba(102, 102, 102, 0.25);
}

.kyCommdoty .img .image {
  width: 85px;
  height: 85px;
}

.kyCommdoty .img {
  /* border:1px solid #309afe; */
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: #f9f9f7;
}

.ktUnit {
  font-size: 14px !important;
}

.ktInfo {
  font-size: 20px;
  margin-top: 5px;
}

.ktTitle {
  font-size: 16px;
  margin-top: 0px;
  color: #1b1b1b;
}

.centerInfo {
  margin-left: 10px;
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
}

.centerInfo .unit {
  font-size: 12px;
  color: #F74440;
}

.rightAdd {
  width: 28%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}

.rightAdd .all {
  color: #F74440;
  font-size: 14px;
}

.addBox {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.rightAdd .input {
  height: 25px;
  width: 25px;
  line-height: 25px;
  text-align: center;
  font-size: 18px;
  border-radius: 5px;
  color: #1b1b1b;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 8px;
  font-weight: 600;
}

.rightAdd .unadd, .rightAdd .add {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #286CFB;
  position: relative;
}

.rightAdd .add::after, .rightAdd .unadd::after {
  content: '';
  display: block;
  position: absolute;
  transform: translate(-50%, -50%);
  width: 50%;
  height: 10%;
  background-color: white;
  top: 50%;
  left: 50%;
}

.rightAdd .add::before {
  content: '';
  display: block;
  position: absolute;
  transform: translate(-50%, -50%);
  width: 10%;
  height: 50%;
  background-color: white;
  top: 50%;
  left: 50%;
}

.totalPrice {
  font-weight: 700;
  color: #F74440;
}

.bottomPromit {
  margin: 10px 16px;
  border-radius: 5px;
  padding: 5px;
  line-height: 22px;
  display: flex;
  text-align: left;
}

.promitEvery {
  font-size: 14px;
  color: #666666;
  position: relative;
}

.KtListEvery .attri {
  width: 25%;
  text-align: center;
  padding: 10px 0;
  font-size: 14px;
}

.KtListEvery .allPrice {
  /* color: #398ae9; */
}

.more {
  text-align: center;
  font-size: 14px;
  padding: 10px;
}

.MyKtNums {
  padding: 10px;
  border-bottom: 1px solid #f6f6f6;
}

.MyKtNums .text {
  font-size: 15px;
  text-indent: .3em;
  /* color: white; */
}

.MyKtNums .nums {
  font-size: 20px;
  margin-top: 10px;
  text-indent: 2em;
  /* color: white; */
}

.bottomLeft .unit, .bottomLeft .price {
  color: #F74440;
}

.van-icon-volume-o:before, .buyPromit .text {
  color: #398ae9;
}
</style>