<script>
import navigationBar from "@/components/navigation-bar/navigation-bar.vue";
import {addressDetails, addressEdit, city_list, user_ship_address} from "@/utils/api";
import {Toast} from "vant";

let fristIndex = -1
let secondIndex = -1
let third = -1
let fourIdex = -1
let fiveIdex = -1
export default {
  name: "addAddress",
  components: {navigationBar},
  data() {
    return {
      ruleForm: {
        address: '',
        province_name: '',
        province_id: '',
        city_name: '',
        city_id: '',
        district_name: '',
        district_id: '',
        details_name: '',
        detailsAddress: '',
        name: '',
        mobile: '',
        roomNumber: '',
      },
      rules: {
        address: [
          {required: true, message: '请选择地址', trigger: 'blur'},
          // {min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur'}
        ], detailsAddress: [
          {required: true, message: '请选择详细地址', trigger: 'blur'},
          // {min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur'}
        ],roomNumber :[
          {required: true, message: '请输入门牌号', trigger: 'blur'},
          // {min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur'}
        ],
        name: [
          {required: true, message: '请输入联系人姓名', trigger: 'blur'},
          // {min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur'}
        ], mobile: [
          {required: true, message: '请输入联系人电话', trigger: 'blur'},
          // {min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur'}
        ],
      },
      addPop: false,
      addPop2: false,
      options: [
        {
          text: '浙江省',
          value: '330000',
          children: [{text: '杭州市', value: '330100'}],
        },
        {
          text: '江苏省',
          value: '320000',
          children: [{text: '南京市', value: '320100'}],
        },
      ],
      v_1_1_0AddressPost: {},
      v_1_1_0AddressDetailsPost: {},
      Floor_houseNumber: [],//楼层房号
      cascaderValue: '',
      cascaderValue2: '',
      is_default: false,

      ieldnames: {
        text: 'name',
        value: 'id',
      },

    }
  },
  watch: {},
  computed: {
    id_def() {
      return this.is_default ? 1 : 0
    },
  },
  async mounted() {
    const {data} = await city_list(0)
    let Floor_houseNumber = await user_ship_address()
    console.log(Floor_houseNumber)
    let list = []
    if (data.status == 200) {
      list = data.data
    }
    if (Floor_houseNumber.data.status == 200) {
      this.Floor_houseNumber = Floor_houseNumber.data.data
      this.Floor_houseNumber =  this.Floor_houseNumber.map(item => {
        return {name: item.name, id: item.id, value: item.id, children: item.data, level: item.level}
      })
    }
    this.options = list.map(item => {
      return {name: item.label, id: item.id, value: item.id, children: item.children, level: item.level}
    })

    if (this.$route.query.item) {
      if (JSON.parse(this.$route.query.item).id) {
        let obj = JSON.parse(this.$route.query.item)
        const {data} = await addressDetails(JSON.parse(this.$route.query.item).id)
        if (data.status) {
          obj = data.data
        }
        console.log(obj)
        let v_1_1_0AddressPost = {
          province: obj.province,//省
          city: obj.city,//市
          district: obj.district,//区
          city_id: obj.city_id,//区id
          street: obj.street,//街道
        }
        this.v_1_1_0AddressDetailsPost = {
          address_location: obj.address_location,
          unit_id: obj.unit_id,
          layer_id: obj.layer_id,
          address_location_name: obj.address_location_name,
          unit_name:obj.unit_name,
          layer_name: obj.layer_name,
        }
        this.v_1_1_0AddressPost = v_1_1_0AddressPost
        this.ruleForm = {
          address: obj.province + ' ' + obj.city + ' ' + obj.district + ' ' + obj.street,
          province_name: obj.province_name,
          province_id: obj.province_id,
          city_name: obj.city_name,
          city_id: obj.city_id,
          district_name: obj.district_name,
          district_id: obj.district_id,
          details_name: obj.street ? obj.street : '',
          detailsAddress: obj.address_location_name+' '+obj.unit_name+' '+obj.layer_name,
          name: obj.real_name,
          mobile: obj.phone,
          id: obj.id,
          roomNumber:obj.detail,
        }
        this.is_default = obj.is_default == 1 ? true : false
        // is_default
      }
    }
  },
  methods: {
    submitFormc(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let obj = {
            address: this.v_1_1_0AddressPost,
            detail: this.ruleForm.roomNumber,
            is_default: this.id_def,
            real_name: this.ruleForm.name,
            phone: this.ruleForm.mobile,
            latitude: '',
            longitude: '',
            id: this.ruleForm.id ? this.ruleForm.id : 0,
            address_location:this.v_1_1_0AddressDetailsPost.address_location,
            unit_id:this.v_1_1_0AddressDetailsPost.unit_id,
            layer_id:this.v_1_1_0AddressDetailsPost.layer_id,
            address_location_name:this.v_1_1_0AddressDetailsPost.address_location_name,
            unit_name:this.v_1_1_0AddressDetailsPost.unit_name,
            layer_name:this.v_1_1_0AddressDetailsPost.layer_name,
          }
          addressEdit(obj).then(res => {
            if (res.data.status == 200) {
              this.$router.go(-1)
            } else {
              Toast(res.data.msg)
            }
          })
        }
      })
    },
    openAddressPop() {
      this.addPop = true
    },
    openAddressPop2() {
      this.addPop2 = true
      console.log(this.Floor_houseNumber)
    },
    onFinish(e) {
      this.addPop = false;
      let addtess = {
        province: e.selectedOptions[0].name,//省
        city: e.selectedOptions[1].name,//市
        district: e.selectedOptions[2].name,//区
        city_id: e.selectedOptions[2].id,//区id
        street: e.selectedOptions[3].name,//街道
      }
      this.v_1_1_0AddressPost = addtess
      this.cascaderValue = e.selectedOptions.map((item) => item.name).join(' ')
      this.ruleForm.address = this.cascaderValue
      this.ruleForm.province_name = e.selectedOptions[0].name
      this.ruleForm.province_id = e.selectedOptions[0].id
      this.ruleForm.city_name = e.selectedOptions[1].name
      this.ruleForm.city_id = e.selectedOptions[1].id
      this.ruleForm.district_name = e.selectedOptions[2].name
      this.ruleForm.district_id = e.selectedOptions[2].id
      this.ruleForm.details_name = e.selectedOptions[3].name
    },
    onFinish2(e){
      this.addPop2 = false;
      this.cascaderValue2 = e.selectedOptions.map((item) => item.name).join(' ')
      this.ruleForm.detailsAddress  = e.selectedOptions.map((item) => item.name).join(' ')
      console.log(e)
      let v_1_1_0AddressDetailsPost = {
        address_location: e.selectedOptions[0].id,
        unit_id: e.selectedOptions[2].id,
        layer_id: e.selectedOptions[3].id,
        address_location_name: e.selectedOptions[0].name,
        unit_name: e.selectedOptions[2].name,
        layer_name: e.selectedOptions[3].name,
      }
      this.v_1_1_0AddressDetailsPost = v_1_1_0AddressDetailsPost
    },
    async onChange(e) {
      console.log(e)
      if (e.tabIndex == 0) {
        const {data} = await city_list(e.value)
        let list = []
        let list2 = []
        fristIndex = this.options.findIndex(item => {
          return item.id == e.value
        })
        console.log(fristIndex)
        if (data.status == 200) {
          list = data.data
          list2 = list.map(item => {
            return {name: item.label, id: item.id, value: item.id, children: item.children, level: item.level}
          })
          setTimeout(() => {
            this.options[this.options.findIndex(item => {
              return item.id == e.value
            })].children = list2
          }, 200);
        }
      }
      if (e.tabIndex == 1) {
        secondIndex = this.options[fristIndex].children.findIndex(item => {
          return item.id == e.value
        })
        const {data} = await city_list(e.value)
        let list = []
        let list2 = []
        if (data.status == 200) {
          list = data.data
          list2 = list.map(item => {
            return {name: item.label, id: item.id, value: item.id, children: item.children, level: item.level}
          })
          setTimeout(() => {
            this.options[fristIndex].children[secondIndex].children = list2
          }, 200);
        }
      }
      if (e.tabIndex == 2) {
        third = this.options[fristIndex].children[secondIndex].children.findIndex(item => {
          return item.id == e.selectedOptions[e.tabIndex].id
        })
        const {data} = await city_list(e.value)
        let list = []
        let list2 = []
        if (data.status == 200) {
          list = data.data
          list2 = list.map(item => {
            return {name: item.label, id: item.id, value: item.id, level: item.level}
          })
          this.options[fristIndex].children[secondIndex].children[third].children = list2
        }
      }
      if (e.tabIndex == 3) {
        fourIdex = this.options[fristIndex].children[secondIndex].children[third].children.findIndex(item => {
          return item.id == e.selectedOptions[e.tabIndex].id
        })
      }
      if(e.tabIndex == 4){
        fiveIdex = this.options[fristIndex].children[secondIndex].children[third].children[fourIdex].children.findIndex(item => {
          return item.id == e.value
        })
        let list = this.options[fristIndex].children[secondIndex].children[third].children[fourIdex].children[fiveIdex].children.map(item => {
          return {name: item.name, id: item.id, value: item.id, children: item.data?item.data:[], level: e.tabIndex}
        })
        this.options[fristIndex].children[secondIndex].children[third].children[fourIdex].children[fiveIdex].children = list
      }
    },
    onChange2(e){
      if (e.tabIndex == 0) {
        let fitstIdex = this.Floor_houseNumber.findIndex(item=>{return item.id == e.selectedOptions[0].id})
        this.Floor_houseNumber[fitstIdex].children = this.Floor_houseNumber[fitstIdex].children.map(item => {
          return {name: item.name, id: item.id, value: item.id, children: item.data?item.data:item.children, level: item.type}
        })
      }
      if (e.tabIndex == 1) {
        let fitstIdex = this.Floor_houseNumber.findIndex(item=>{return item.id == e.selectedOptions[0].id})
        let secondIdex = this.Floor_houseNumber[fitstIdex].children.findIndex(item=>{return item.id == e.selectedOptions[1].id})
        this.Floor_houseNumber[fitstIdex].children[secondIdex].children = this.Floor_houseNumber[fitstIdex].children[secondIdex].children.map(item => {
          return {name: item.name, id: item.id, value: item.id, level: item.type,children: item.data?item.data:item.children,}
        })
      }
      if(e.tabIndex == 2) {
        let fitstIdex = this.Floor_houseNumber.findIndex(item=>{return item.id == e.selectedOptions[0].id})
        let secondIdex = this.Floor_houseNumber[fitstIdex].children.findIndex(item=>{return item.id == e.selectedOptions[1].id})
        let threeIdex = this.Floor_houseNumber[fitstIdex].children[secondIdex].children.findIndex(item=>{return item.id == e.selectedOptions[2].id})
        this.Floor_houseNumber[fitstIdex].children[secondIdex].children[threeIdex].children = this.Floor_houseNumber[fitstIdex].children[secondIdex].children[threeIdex].children.map(item => {
          return {name: item.name, id: item.id, value: item.id, level: item.type,}
        })
      }



    },
    changeSwitch() {
      this.is_default = !this.is_default
    },
  },
}
</script>

<template>
  <div class="ccontainer addressPage">
    <div class="area">
      <navigationBar :title="'添加地址'"></navigationBar>
      <div class="card addAddress">
        <div class="titleBox">
          <div class="leftTitle">地址信息</div>
          <div class="rightSwitch" @click="changeSwitch">
            默认收货地址
            <van-switch class="switch" v-model="is_default" size="12px" active-color="#FC223B"
                        inactive-color="#e9e9ea"/>
          </div>
        </div>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px" class="demo-ruleForm">
          <el-form-item label="所在地区" prop="address" @click="openAddressPop">
            <div class="line">
              <!--            <input type="text" placeholder="" class="title input" v-model="ruleForm.address"-->
              <!--                   maxlength="22" disabled  @click="openAddressPop">-->
              <div class="texte" :class="ruleForm.address?'':'placeholder'" @click="openAddressPop">
                {{ ruleForm.address ? ruleForm.address : '省、市、区、街道' }}
              </div>
              <span class="iconfont icon-Frame-4" @click="openAddressPop"></span>
            </div>
          </el-form-item>
          <el-form-item label="收货地址" prop="detailsAddress" @click="openAddressPop2">
            <div class="line">
              <div class="texte" :class="ruleForm.detailsAddress?'':'placeholder'" @click="openAddressPop2">
                {{ ruleForm.detailsAddress ? ruleForm.detailsAddress : '请选择详细收货地址' }}
              </div>
              <span class="iconfont icon-Frame-4" @click="openAddressPop2"></span>
<!--              <input type="text" placeholder="请填写详细收货地址" class="title input" v-model="ruleForm.detailsAddress"-->
<!--                     maxlength="50">-->
            </div>
          </el-form-item>
          <el-form-item label="门牌号" prop="roomNumber">
            <div class="line">
              <input type="text" placeholder="门牌号" class="title input" v-model="ruleForm.roomNumber"
                     maxlength="25">
            </div>
          </el-form-item>

          <el-form-item label="联系人" prop="name">
            <div class="line">
              <input type="text" placeholder="名字" class="title input" v-model="ruleForm.name"
                     maxlength="25">
            </div>
          </el-form-item>
          <el-form-item label="联系号码" prop="mobile">
            <div class="line">
              <input type="text" placeholder="手机号" class="title input" v-model="ruleForm.mobile"
                     maxlength="15">
              <span class="text">+86</span>
            </div>
          </el-form-item>

          <div class="bottomBox">
            <span class="sendBtn" @click="submitFormc('ruleForm')">确认</span>
          </div>
        </el-form>
      </div>
      <van-popup v-model="addPop" round position="bottom">
        <van-cascader
            v-model="cascaderValue"
            title="请选择所在地区"
            :options="options"
            :field-names="ieldnames"
            @close="addPop = false"
            @finish="onFinish"
            @change="onChange"
        />
      </van-popup>
      <van-popup v-model="addPop2" round position="bottom">
        <van-cascader
            v-model="cascaderValue2"
            title="请选择所在地区"
            :options="Floor_houseNumber"
            :field-names="ieldnames"
            @close="addPop = false"
            @finish="onFinish2"
            @change="onChange2"
        />
      </van-popup>
      <div class="tag">
        <span class="red">*</span>目前配送范围限<span class="red">三里屯SOHO、通盈中心</span>内，我们正在努力建设更多配送点，敬请期待
      </div>

    </div>
  </div>
</template>

<style scoped lang="scss">

.input {
  border: none;
  width: 100%;
}

.card {
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0px 0px 2px #66666640;
  padding: 10px;
  margin: 10px 16px;

  .titleBox {
    display: flex;
    justify-content: space-between;
    margin-bottom: 14px;

    .leftTitle {
      color: #1b1b1b;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }

    .rightSwitch {
      color: #666666;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      display: flex;
      align-items: center;

      .switch {
        margin-left: 6px;
      }
    }
  }

  .line {
    border-radius: 10px;
    background: #f9f9f9;
    display: flex;
    padding: 0 0px 0 10px;
    justify-content: space-between;

    .input {
      background-color: transparent;
    }

    .iconfont {
      color: #666666;
      font-size: 24px;
    }

    .input::placeholder {
      color: var(--999999, #999999);
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
    }

    .texte {
      text-align: justify;
      color: #1b1b1b;
      font-weight: 400;
      font-size: 14px;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .text {
      margin-right: 10px;
      color: #cccccc;
      font-weight: 400;
      font-size: 16px;
    }
  }

}

.tag {
  color: #9e9c9d;
  font-family: "PingFang SC";
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  margin: 10px 16px;
  text-align: left;

  .red {
    color: #fc223b;
  }
}

.bottomBox {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #fff;
  padding: 20px 0;
  width: 100%;

  .sendBtn {
    border-radius: 10px;
    background: #fc223b;
    border: none;
    color: #fff;
    padding: 14px;
    width: 85%;
    display: inline-block;

  }
}

.placeholder {
  color: var(--999999, #999999) !important;
  font-weight: 400;
  font-size: 14px;
}
</style>
<style>
.addressPage .van-cascader__options {
  height: 100%;
}

.addressPage .van-popup {
  background-color: #fff !important;
}

.addressPage .van-popup .van-tabs__wrap {
  margin: 0px 16px;
  border-bottom: 1px solid #e6e6e6;
}

.addressPage .van-popup .van-tabs__line {
  width: 28px;
  height: 2px;
  background: #fc223b;
}

.addressPage .van-popup .van-cascader__option {
  color: #1b1b1b;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}
</style>